import React, { createContext, useState, useEffect } from "react";
import axios from "axios";
import News4 from "./../../../images/advertisement/sliding-banner4.png";
import fourtis from "../../../images/Circle.png";

export const NewsContext = createContext();

export const NewsProvider = ({ children }) => {
  const [loading, setLoading] = useState(true);

  function generateRandomString(length) {
    return Math.random().toString(36).substr(2, length);
  }

  const announcement = {
    category: "All",
    title: "Platform Launch: Welcome to Fourtis!",
    text: "We are excited to announce the launch of Fourtis! Our platform brings together the latest in cryptocurrency rankings, news, and analysis. Whether you're interested in DeFi, NFTs, or the latest meme coins, Fourtis is your go-to source for real-time updates.",
    description:
      "Fourtis has officially launched, introducing a comprehensive platform...",
    content:
      "Fourtis has officially launched, introducing a comprehensive platform for cryptocurrency enthusiasts, traders, and investors. Explore a wealth of features and stay updated on all the major developments in the crypto world.",
    publishedAt: "09-17-2024",
    urlToImage: fourtis,
    site: "CoinIndex",
    url: "https://www.coinindex.com/news/platform-launch",
    id: generateRandomString(8),
  };

  const [news, setNewsIds] = useState({
    1: announcement,
  });

  return (
    <NewsContext.Provider
      value={{
        setNewsIds,
        news
      }}
    >
      {children}
    </NewsContext.Provider>
  );
};
