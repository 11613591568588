import React, { useState, useEffect } from "react";
import { Swiper, SwiperSlide } from "swiper/react";
import { Autoplay, Pagination, EffectCoverflow } from "swiper/modules";
import axios from "axios";
import "swiper/css";
import "./../../styles/micro/carousel.css";

import Sliding_banner2 from "./../../images/advertisement/sliding-banner2.jpeg";
import Sliding_banner1 from "./../../images/advertisement/sliding-banner1.gif";
import Sliding_banner3 from "./../../images/advertisement/sliding-banner3.gif";
import Sliding_banner4 from "./../../images/advertisement/sliding-banner4.png";
import Sliding_banner5 from "./../../images/advertisement/sliding-banner5.png";
import Sliding_banner6 from "./../../images/advertisement/sliding-banner6.png";

const Carousel = () => {
  const [slide, setSlide] = useState([
    { image: Sliding_banner2 },
    { image: Sliding_banner3 },
    { image: Sliding_banner4 },
    { image: Sliding_banner5 },
    { image: Sliding_banner6 },
    { image: Sliding_banner1 },
  ]);

  useEffect(() => {
    async function fetchData() {
      const response = await axios.get(
        "https://fourtis.xyz/api/promotions/type/rotatedbanner/1"
      );
      setSlide(response.data);
    }
    fetchData();
  }, []);

  return (
    <Swiper
      className="adv-swiper"
      modules={[Pagination, EffectCoverflow, Autoplay]}
      effect={"coverflow"}
      grabCursor={true}
      centeredSlides={true}
      coverflowEffect={{
        rotate: 18,
        stretch: 0,
        depth: 100,
        modifier: 3,
        slideShadows: true,
      }}
      loop={true}
      pagination={{ clickable: true }}
      autoplay={{
        delay: 3500,
        disableOnInteraction: false,
      }}
      breakpoints={{
        300: {
          slidesPerView: 1,
        },
        640: {
          slidesPerView: 2,
        },
      }}
    >
      {slide.length > 0 &&
        slide.map((slide) => (
          <SwiperSlide className="adv-swiper-slide">
            <a href={slide.link ? slide.link : "#"}>
              <img
                src={"https://fourtis.xyz/images/" + slide.image}
                alt=""
                className="adv-swiper-img"
              />
            </a>
          </SwiperSlide>
        ))}
    </Swiper>
  );
};

export default Carousel;
