import React, { useEffect, useState, useRef } from "react";
import "./../../styles/micro/coinSheet.css";
import CoinSheet from "./../../images/icon/moon-sheet.webp";
import axios from "axios";
import { formatCoinValue } from "../../helpers/formatPrice";
import { CircularProgress } from "@mui/material";

const CoinSheetCompo = () => {
  const [measuredCoins, setMeasuredCoins] = useState([]);
  const [sourceCoins, setSourceCoins] = useState([]);
  const optionsBRef = useRef(null);
  const optionsARef = useRef(null);
  useEffect(() => {
    const getAllCoins = async () => {
      let totalPages;
      const result = await axios.get("https://fourtis.xyz/api/coins-price");
      totalPages = result.data.totalPages;

      let allCoins = [];
      for (let i = 0; i < totalPages; i++) {
        const data = await axios.get(
          `https://fourtis.xyz/api/coins-price?page=${i}`
        );
        allCoins = allCoins.concat(data.data.data);
      }
      console.log(allCoins);
      console.log(allCoins.length);
      return allCoins;
    };

    getAllCoins().then((r) => {
      setSourceCoins(r);
      setMeasuredCoins(r);
    });
  }, []);

  const [displayOptionsA, setDisplayOptionsA] = useState(false);
  const [displayOptionsB, setDisplayOptionsB] = useState(false);
  const [selectA, setSelectA] = useState("");
  const [selectB, setSelectB] = useState("");
  const [showCalculation, setShowCalculation] = useState(false);
  const [calcData, setCalcData] = useState({});

  const handleCapA = (token) => {
    setSelectA(token);
    setDisplayOptionsA(false);
  };
  const handleCapB = (token) => {
    setSelectB(token);
    setDisplayOptionsB(false);
  };

  const calculatePriceAndMarketCap = (tokenA, tokenB) => {
    const times = tokenB.mcap / tokenA.mcap;
    setCalcData({
      price: tokenB.price * times,
      times,
    });
  };

  useEffect(() => {
    if (selectA && selectB) {
      calculatePriceAndMarketCap(selectA, selectB);
      setShowCalculation(true);
    } else {
      setShowCalculation(false);
    }
  }, [selectA, selectB]);

  const handleClickOutsideA = (event) => {
    if (optionsARef.current && !optionsARef.current.contains(event.target)) {
      setDisplayOptionsA(false);
    }
  };
  useEffect(() => {
    document.addEventListener("mousedown", handleClickOutsideA);
    return () => {
      document.removeEventListener("mousedown", handleClickOutsideA);
    };
  }, []);

  const handleClickOutsideB = (event) => {
    if (optionsBRef.current && !optionsBRef.current.contains(event.target)) {
      setDisplayOptionsB(false);
    }
  };
  useEffect(() => {
    document.addEventListener("mousedown", handleClickOutsideB);
    return () => {
      document.removeEventListener("mousedown", handleClickOutsideB);
    };
  }, []);

  return (
    <div className="coin-sheet-cntr">
      <div className="sheet-title-cntr">
        <h1>Coin Calculate</h1>
        <img src={CoinSheet} alt="" className="sheet-title-img" />
      </div>
      <div className="expalin">
        {" "}
        Calculate the price of A with the market cap of B{" "}
      </div>
      <div className="cap-cntr">
        <div
          className="capA-cntr cap"
          onClick={() => setDisplayOptionsA(displayOptionsA ? false : true)}
          style={{
            opacity: sourceCoins.length == 0 ? "0.5" : "1",
            pointerEvents: sourceCoins.length == 0 ? "none" : "all",
            cursor: "pointer",
          }}
        >
          <div className="capA-select">
            {" "}
            {sourceCoins.length > 0 ? (
              selectA === "" ? (
                "select..."
              ) : (
                <div className="cap-selected">
                  <img src={selectA.logo_url} alt="" className="option-img" />
                  <span style={{ fontWeight: "bold" }}>{selectA.symbol}</span>
                  <span>{Number(selectA.price).toFixed(6)}</span>
                </div>
              )
            ) : (
              <span
                style={{
                  display: "flex",
                  alignItems: "center",
                }}
              >
                Loading{" "}
                <CircularProgress
                  size={25}
                  sx={{ marginLeft: "10px" }}
                ></CircularProgress>
              </span>
            )}
          </div>
          <div className="capA-cntrlr cntrlr">
            <svg
              height="20"
              width="20"
              fill="rgb(180, 180, 180)"
              viewBox="0 0 20 20"
              aria-hidden="true"
              focusable="false"
              class="css-8mmkcg"
              onClick={() => setSelectA("")}
            >
              <path d="M14.348 14.849c-0.469 0.469-1.229 0.469-1.697 0l-2.651-3.030-2.651 3.029c-0.469 0.469-1.229 0.469-1.697 0-0.469-0.469-0.469-1.229 0-1.697l2.758-3.15-2.759-3.152c-0.469-0.469-0.469-1.228 0-1.697s1.228-0.469 1.697 0l2.652 3.031 2.651-3.031c0.469-0.469 1.228-0.469 1.697 0s0.469 1.229 0 1.697l-2.758 3.152 2.758 3.15c0.469 0.469 0.469 1.229 0 1.698z"></path>
            </svg>
            |
            <svg
              height="20"
              width="20"
              fill="rgb(180, 180, 180)"
              viewBox="0 0 20 20"
              aria-hidden="true"
              focusable="false"
              class="css-8mmkcg"
            >
              <path d="M4.516 7.548c0.436-0.446 1.043-0.481 1.576 0l3.908 3.747 3.908-3.747c0.533-0.481 1.141-0.446 1.574 0 0.436 0.445 0.408 1.197 0 1.615-0.406 0.418-4.695 4.502-4.695 4.502-0.217 0.223-0.502 0.335-0.787 0.335s-0.57-0.112-0.789-0.335c0 0-4.287-4.084-4.695-4.502s-0.436-1.17 0-1.615z"></path>
            </svg>
          </div>
          {displayOptionsA === true && (
            <div className="options" ref={optionsARef}>
              {true &&
                measuredCoins.map((tok, index) => (
                  <div
                    className="option"
                    key={index}
                    onClick={() => handleCapA(tok)}
                  >
                    <div
                      style={{
                        display: "flex",
                        flexDirection: "row",
                        justifyContent: "flex-start",
                        alignItems: "center",
                        gap: "10px",
                      }}
                    >
                      <img src={tok.logo_url} alt="" className="option-img" />
                      <span className="option-name"> {tok.name} </span>
                    </div>
                    <div
                      style={{
                        backgroundColor: "rgb(25, 25, 25)",
                        padding: "5px",
                        borderRadius: "10px",
                        fontWeight: "bold",
                        textShadow: " 0 0 10px #00bfff",
                      }}
                    >
                      {tok.symbol}
                    </div>
                  </div>
                ))}
            </div>
          )}
        </div>

        <svg
          xmlns="http://www.w3.org/2000/svg"
          class="icon icon-tabler icon-tabler-arrow-down-circle"
          width="25"
          height="25"
          viewBox="0 0 24 24"
          stroke="currentColor"
          stroke-width="2"
          fill="none"
          stroke-linecap="round"
          stroke-linejoin="round"
        >
          <path stroke="none" d="M0 0h24v24H0z" fill="none"></path>
          <circle cx="12" cy="12" r="9"></circle>
          <line x1="8" y1="12" x2="12" y2="16"></line>
          <line x1="12" y1="8" x2="12" y2="16"></line>
          <line x1="16" y1="12" x2="12" y2="16"></line>
        </svg>

        <div
          className="capB-cntr cap"
          onClick={() => setDisplayOptionsB(displayOptionsB ? false : true)}
          style={{
            opacity: measuredCoins.length == 0 ? "0.5" : "1",
            pointerEvents: measuredCoins.length == 0 ? "none" : "all",
            cursor: "pointer",
          }}
        >
          <div className="capB-select">
            {measuredCoins.length > 0 ? (
              selectB === "" ? (
                "select..."
              ) : (
                <div className="cap-selected">
                  <img src={selectB.logo_url} alt="" className="option-img" />
                  <span style={{ fontWeight: "bold" }}>{selectB.symbol}</span>
                  <span>{Number(selectB.price).toFixed(6)}</span>
                </div>
              )
            ) : (
              <span
                style={{
                  display: "flex",
                  alignItems: "center",
                }}
              >
                Loading{" "}
                <CircularProgress
                  size={25}
                  sx={{ marginLeft: "10px" }}
                ></CircularProgress>
              </span>
            )}
          </div>
          <div className="capB-cntrlr cntrlr">
            <svg
              height="20"
              width="20"
              fill="rgb(180, 180, 180)"
              viewBox="0 0 20 20"
              aria-hidden="true"
              focusable="false"
              class="css-8mmkcg"
              onClick={() => setSelectB("")}
            >
              <path d="M14.348 14.849c-0.469 0.469-1.229 0.469-1.697 0l-2.651-3.030-2.651 3.029c-0.469 0.469-1.229 0.469-1.697 0-0.469-0.469-0.469-1.229 0-1.697l2.758-3.15-2.759-3.152c-0.469-0.469-0.469-1.228 0-1.697s1.228-0.469 1.697 0l2.652 3.031 2.651-3.031c0.469-0.469 1.228-0.469 1.697 0s0.469 1.229 0 1.697l-2.758 3.152 2.758 3.15c0.469 0.469 0.469 1.229 0 1.698z"></path>
            </svg>
            |
            <svg
              height="20"
              width="20"
              fill="rgb(180, 180, 180)"
              viewBox="0 0 20 20"
              aria-hidden="true"
              focusable="false"
              class="css-8mmkcg"
            >
              <path d="M4.516 7.548c0.436-0.446 1.043-0.481 1.576 0l3.908 3.747 3.908-3.747c0.533-0.481 1.141-0.446 1.574 0 0.436 0.445 0.408 1.197 0 1.615-0.406 0.418-4.695 4.502-4.695 4.502-0.217 0.223-0.502 0.335-0.787 0.335s-0.57-0.112-0.789-0.335c0 0-4.287-4.084-4.695-4.502s-0.436-1.17 0-1.615z"></path>
            </svg>
          </div>
          {displayOptionsB === true && (
            <div className="options" ref={optionsBRef}>
              {true &&
                sourceCoins.map((tok, index) => (
                  <div
                    className="option"
                    key={index}
                    onClick={() => handleCapB(tok)}
                  >
                    <div
                      style={{
                        display: "flex",
                        flexDirection: "row",
                        justifyContent: "flex-start",
                        alignItems: "center",
                        gap: "10px",
                      }}
                    >
                      <img src={tok.logo_url} alt="" className="option-img" />
                      <span className="option-name"> {tok.name} </span>
                    </div>
                    <div
                      style={{
                        backgroundColor: "rgb(25, 25, 25)",
                        padding: "5px",
                        borderRadius: "10px",
                        fontWeight: "bold",
                        textShadow: " 0 0 10px #00bfff",
                      }}
                    >
                      {tok.symbol}
                    </div>
                  </div>
                ))}
            </div>
          )}
        </div>
      </div>
      {showCalculation && (
        <div className="calculate-cntr">
          <div className="calculate">
            <img src={CoinSheet} alt="" className="token-img" />
            <h1 className="result-price">
              ${calcData.price ? formatCoinValue(calcData.price) : "0.0"}
            </h1>
            <h1 className="in-de-time">({Math.floor(calcData.times)}x)</h1>
          </div>
          <div className="calculate-sides">
            <span>
              <span>{selectA.symbol}</span> with the marketcap of{" "}
              <span>{selectB.symbol}</span>
            </span>
          </div>
        </div>
      )}
    </div>
  );
};

export default CoinSheetCompo;
