import React from "react";
import { useNavigate } from "react-router-dom";
import "./../../styles/micro/notFound.css";

const NotFound = () => {
  let navigate = useNavigate();
  return (
    <div className="not-found-cntr">
      <div className="not-found-box">
        <h1 style={{textDecoration: 'underline',margin: "0"}}>404 !</h1>
        <h1 style={{textDecoration: 'underline', marginTop:"10px"}}>this page is not found !</h1>
        <button
          onClick={() => {
            navigate("/");
          }}
          className="to-home-btn"
        >
          {" "}
          Home{" "}
        </button>
      </div>
    </div>
  );
};
export default NotFound;
