import React from 'react'
import CryptoCollageBanner from '../component/micro/cryptoCollageBanner'
import Carousel from '../component/micro/carousel'
import SlidingProgressBar from '../component/micro/slidingProgressBar'
import CoinSheetCompo from '../component/micro/coinSheetCompo'
import CryptoDashboard from '../component/micro/cryptoDashboard'

const CoinSheet = () => {
  return (
    <React.Fragment>
      <SlidingProgressBar />
      <CryptoCollageBanner />
      <Carousel />
      <CoinSheetCompo />
      <CryptoDashboard />
    </React.Fragment>
  )
}

export default CoinSheet