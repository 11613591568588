import React, { useContext, useEffect, useRef, useState } from "react";
import { CryptoDataContext } from "./../../App";
import "./../../styles/micro/tokenDetails.css";
import Telegram from "./../../images/icon/telegram-icon.svg";
import Website from "./../../images/icon/website.svg";
import Twitter from "./../../images/icon/X-icon.svg";
import { useParams } from "react-router-dom";
import chains from "../../data/chains.json";
import axios from "axios";
import { handleError } from "../../utils/errorHandler";
import { useNotifications } from "@toolpad/core";
import ContentCopyIcon from "@mui/icons-material/ContentCopy";
import OpenInNewIcon from "@mui/icons-material/OpenInNew";
import TelegramIcon from "@mui/icons-material/Telegram";
import XIcon from "@mui/icons-material/X";
import LanguageIcon from "@mui/icons-material/Language";
import PresaleTimer from "./presaleTimer";
import ScanResultsAccordion from "./scanResultAccordion";
import { DiscussionEmbed } from "disqus-react";
import DexChartIframe from "../custom/chartFrame";
import OpenInFullIcon from "@mui/icons-material/OpenInFull";
import { formatCoinValue } from "../../helpers/formatPrice";

const TokenDetailsCompo = () => {
  const notif = useNotifications();
  const [votes, setVotes] = useState({});
  const [scanResults, setScanResults] = useState(null);

  const forLink = {
    "English Telegram": Telegram,
    "Chineese Telegram": Telegram,
    website: Website,
    telegram: Telegram,
    Website: Website,
    twitter: Twitter,
  };

  const GetSocialIcon = (title) => {
    if (
      title == "English Telegram" ||
      title == "Chinesee Telegram" ||
      title.toLowerCase() == "telegram"
    ) {
      return <TelegramIcon color="primary"></TelegramIcon>;
    } else if (title.toLowerCase() == "website") {
      return <LanguageIcon color="info"></LanguageIcon>;
    } else {
      return <XIcon color="black"></XIcon>;
    }
  };

  const { coinForDetail } = useContext(CryptoDataContext);
  const [coin, setCoin] = useState([]);

  const details = useRef(null);
  const { cat, chain, address } = useParams();
  const [isVisible, setIsVisible] = useState(false);
  const [socials, setSocials] = useState([]);
  const [pairCreationDate, setPairCreationdate] = useState(0);
  const [presaleStart, setPresaleStart] = useState(0);
  const [presaleEnd, setPresaleEnd] = useState(0);
  const [presaleData, setPresaleData] = useState({});
  const [price, setPrice] = useState(0);
  const [priceChange, setPriceChange] = useState({
    h1: 0,
    h24: 0,
    h6: 0,
  });

  const handleIsVisible = () => setIsVisible(!isVisible);

  const getChainName = (chain) => {
    if (chain == "ether") {
      return "ethereum";
    }
    return chain.toLowerCase();
  };

  function formatNumber(num) {
    return num.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
  }

  const updateVotes = async () => {
    if (localStorage.getItem("token")) {
      let itemIds = [coin._id];
      try {
        const result = await axios.post(
          "https://fourtis.xyz/api/hasVotedBatch",
          {
            itemType: cat,
            itemIds,
          },
          {
            headers: {
              Authorization: "Bearer " + localStorage.getItem("token"),
            },
          }
        );
        setVotes(result.data.voteMap);
      } catch (e) {
        notif.show(handleError(e), {
          severity: "error",
          autoHideDuration: 3000,
        });
      }
    }
  };

  const handleBoostClick = (coin) => {
    const tweetText = `Let's take #${coin.name} ($${coin.symbol}) to the moon! 🚀 #memecoins`;
    const tweetUrl = `https://twitter.com/intent/tweet?text=${encodeURIComponent(
      tweetText
    )}&via=CoinIndexOfficial`;
    window.open(tweetUrl, "_blank");
  };

  const handleClickVoteButton = async (item, type) => {
    const token = localStorage.getItem("token");
    const upvote = votes[item._id];
    if (token) {
      try {
        const result = await axios.post(
          "https://fourtis.xyz/api/" + (upvote ? "unvote" : "vote"),
          {
            itemId: item._id,
            itemType: type,
          },
          {
            headers: {
              Authorization: "Bearer " + localStorage.getItem("token"),
            },
          }
        );
        let newCoin = { ...coin };
        if (upvote) {
          newCoin.vote -= 1;
        } else {
          newCoin.votes += 1;
        }
        setCoin(newCoin);
        updateVotes();
      } catch (e) {
        notif.show(handleError(e), {
          autoHideDuration: 3000,
          severity: "error",
        });
      }
    } else {
      notif.show("You need to be logged in!", {
        severity: "warning",
        autoHideDuration: 3000,
      });
    }
  };

  const getCoinPrice = () => {
    axios
      .get(
        `https://fourtis.xyz/api/${
          cat == "coin" ? "coins" : "presales"
        }-price/${chain}/${address}`
      )
      .then((r) => {
        if (r.data) {
          setPrice(r.data.price || 0);
          setPriceChange({
            h1: r.data.variation1h || 0,
            h24: r.data.variation24h || 0,
            h6: r.data.variation6h || 0,
          });
          setSocials(r.data.links);
          setPairCreationdate(new Date(r.data.approved_at));
          if (cat == "presale") {
            setPresaleStart(new Date(r.data.presale_details.start_date));
            setPresaleEnd(new Date(r.data.presale_details.end_date));
          }
          details.current.innerHTML = r.data.overview;
          setCoin(r.data);
        }
      })
      .catch((e) => {});
  };

  const getCoinInfo = (id) => {
    axios
      .get(`https://fourtis.xyz/api/${cat}s/${id}`)
      .then((r) => {
        if (r.data) {
          setPrice(0);
          setPriceChange({
            h1: 0,
            h24: 0,
            h6: 0,
          });
          setSocials(r.data.links);
          setPairCreationdate(new Date(r.data.approved_at));
          if (cat == "presale") {
            setPresaleStart(new Date(r.data.presale_details.start_date));
            setPresaleEnd(new Date(r.data.presale_details.end_date));
          }
          details.current.innerHTML = r.data.overview;
          setCoin(r.data);
        }
      })
      .catch((e) => {});
  };

  const scan = async (network, ca) => {
    try {
      const result = await axios.get(
        `https://fourtis.xyz/api/scan?chain=${network}&address=${ca}`
      );
      setScanResults(result.data.data);
    } catch (e) {}
  };

  const getShortAddress = (addr) => {
    if (addr == "TBA") return "TBA";
    return (
      addr.substring(0, 5) +
      "..." +
      addr.substring(addr.length - 5, addr.length)
    ).toLowerCase();
  };

  const getExplorerURI = (addr, chain) => {
    const explorer = chains.data.find((c) => c.slug == chain);
    return explorer.explorer_url + "/" + addr;
  };

  useEffect(() => {
    if (address && chain) {
      if (address.substring(0, 2) == "id") {
        const id = address.split("=")[1];
        getCoinInfo(id);
      } else {
        getCoinPrice();
        scan(chain, address);
      }
    }
  }, [address]);

  useEffect(() => {
    if (coin._id) {
      updateVotes();
    }
  }, [coin]);

  const [responsive_765px, setResponsive_76px] = useState(false);
  useEffect(() => {
    // Function to update the isMenuOpen state based on screen width
    const updateResponsive = () => {
      setResponsive_76px(window.innerWidth <= 780);
    };
    window.addEventListener("resize", updateResponsive);
    return () => {
      window.removeEventListener("resize", updateResponsive);
    };
  }, []);

  return (
    <div>
      <div className="coin-detail-container">
        <header className="coin-header">
          <div className="coin-info">
            <img
              src={coin.logo_url}
              alt={`${coin.name} Logo`}
              className="coin-logo-detail"
            />
            <div>
              <h1 className="coin-name-details">{coin.name}</h1>
              <h2 className="coin-symbol-details">{coin.symbol}</h2>
              <p className="coin-price">
                ${price ? formatCoinValue(price) : 0}
              </p>
            </div>
            <div
              className="details-price"
              style={{
                display: "flex",
                flexDirection: "column",
                gap: "8px",
                marginLeft: "40px",
              }}
            >
              <div>
                <span style={{ marginRight: "10px", color: "white" }}>1H</span>
                <span
                  style={{
                    color:
                      parseFloat(priceChange.h1) >= 0
                        ? "rgb(46, 246, 46)"
                        : "rgb(220, 16, 16)",
                  }}
                >
                  {priceChange.h1 ? formatCoinValue(priceChange.h1) : 0}%
                </span>
              </div>
              <div>
                <span style={{ marginRight: "10px", color: "white" }}>24H</span>
                <span
                  style={{
                    color:
                      parseFloat(priceChange.h24) >= 0
                        ? "rgb(46, 246, 46)"
                        : "rgb(220, 16, 16)",
                  }}
                >
                  {formatCoinValue(priceChange.h24)}%
                </span>
              </div>
              <div>
                <span style={{ marginRight: "10px", color: "white" }}>
                  Market Cap
                </span>
                <span style={{ color: "#2861fd" }}>
                  {coin.mcap ? formatNumber(Math.floor(coin.mcap)) : "0.0"}$
                </span>
              </div>
              {pairCreationDate != 0 && cat == "coin" && (
                <div
                  style={{
                    display: "flex",
                    gap: "5px",
                    alignItems: "center",
                  }}
                >
                  <span
                    style={{
                      color: "white",
                      marginRight: "3px",
                    }}
                  >
                    Launch At
                  </span>
                  <span
                    style={{
                      color: "gray",
                    }}
                  >
                    {pairCreationDate.getFullYear()}/
                    {parseInt(pairCreationDate.getMonth(), 10)}/
                    {pairCreationDate.getDate()}
                  </span>
                </div>
              )}
              {cat == "presale" && (
                <div
                  onClick={() => {
                    window.open(coin.presale_details.link, "__blank");
                  }}
                >
                  <span
                    style={{
                      marginRight: "10px",
                      color: "white",
                      display: "flex",
                      alignItems: "center",
                      gap: "5px",
                    }}
                  >
                    <OpenInNewIcon color="secondary"></OpenInNewIcon>
                    <b style={{ color: "#9c27b0" }}>Presale</b>
                  </span>
                </div>
              )}
            </div>
          </div>
          <div className="coin-actions">
            <div
              style={{
                display: "flex",
                gap: "10px",
              }}
            >
              <button
                className="vote-button"
                onClick={() => handleClickVoteButton(coin, cat)}
              >
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  class="icon icon-tabler icon-tabler-rocket"
                  width="16"
                  height="16"
                  viewBox="0 0 24 24"
                  stroke="#fff"
                  stroke-width="2"
                  fill="none"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                >
                  <path stroke="none" d="M0 0h24v24H0z" fill="none"></path>
                  <path d="M4 13a8 8 0 0 1 7 7a6 6 0 0 0 3 -5a9 9 0 0 0 6 -8a3 3 0 0 0 -3 -3a9 9 0 0 0 -8 6a6 6 0 0 0 -5 3"></path>
                  <path d="M7 14a6 6 0 0 0 -3 6a6 6 0 0 0 6 -3"></path>
                  <circle cx="15" cy="9" r="1"></circle>
                </svg>
                {votes && votes[coin._id] ? "Unvote" : "Vote"}
              </button>
              <button
                className="boost-button"
                onClick={() => handleBoostClick(coin)}
              >
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="14"
                  height="14"
                  viewBox="0 0 24 24"
                  fill="none"
                  stroke="#fff"
                  stroke-width="2"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                  class="tabler-icon tabler-icon-brand-x"
                >
                  <path d="M4 4l11.733 16h4.267l-11.733 -16z"></path>
                  <path d="M4 20l6.768 -6.768m2.46 -2.46l6.772 -6.772"></path>
                </svg>
                Boost
              </button>
            </div>
            <div
              style={{
                marginBottom: "10px",
              }}
            >
              <div
                className="social-links"
                style={{
                  gap: "5px",
                  display: "flex",
                  flexDirection: "row",
                }}
              >
                {coin &&
                  coin.links &&
                  coin.links.map((link, idx) => (
                    <a
                      key={idx}
                      href={link.url}
                      target="_blank"
                      rel="noopener noreferrer"
                      style={{
                        backgroundColor: "black",
                        borderRadius: "10px",
                      }}
                    >
                      {GetSocialIcon(link.title)}
                    </a>
                  ))}
              </div>
            </div>
            <div>
              <div
                style={{
                  display: "flex",
                  justifyContent: "space-evenly",
                  alignItems: "center",
                  gap: "10px",
                  background: "black",
                  color: "gray",
                  padding: "0 5px",
                  height: "36px",
                  borderRadius: "6px",
                }}
              >
                {coin &&
                  coin.contract_details &&
                  coin.contract_details[0].contract_address != "TBA" && (
                    <OpenInNewIcon
                      color="primary"
                      sx={{
                        ":hover": {
                          cursor: "pointer",
                          backgroundColor: "#1976d2",
                          color: "white",
                        },
                      }}
                      onClick={() => {
                        window.open(
                          getExplorerURI(
                            coin.contract_details[0].contract_address,
                            coin.contract_details[0].chain
                          ),
                          "__blank"
                        );
                      }}
                    ></OpenInNewIcon>
                  )}
                {coin && coin.contract_details && (
                  <img
                    src={
                      "https://fourtis.xyz/images/" +
                      chains.logos[coin.contract_details[0].chain]
                    }
                    alt=""
                    style={{ width: "30px", borderRadius: "50%" }}
                  />
                )}
                {coin && coin.contract_details && (
                  <span>
                    {getShortAddress(coin.contract_details[0].contract_address)}
                  </span>
                )}
                <ContentCopyIcon
                  sx={{
                    ":hover": {
                      cursor: "pointer",
                    },
                  }}
                  onClick={() => {
                    navigator.clipboard.writeText(
                      coin.contract_details[0].contract_address
                    );
                    notif.show("Address Copied To Clipboard!", {
                      autoHideDuration: 3000,
                      severity: "info",
                    });
                  }}
                ></ContentCopyIcon>
              </div>
            </div>
          </div>
        </header>

        <nav className="coin-navigation"></nav>

        <section className="coin-details">
          <div className="transaction-details"></div>

          {scanResults && (
            <ScanResultsAccordion
              scanResults={scanResults}
            ></ScanResultsAccordion>
          )}

          {pairCreationDate != 0 && cat == "presale" && (
            <PresaleTimer presale={coin}></PresaleTimer>
          )}
          <div
            id="details"
            ref={details}
            style={{
              borderRadius: "10px",
              marginTop: "10px",
              width: "fit-content",
              color: "#c9c9c9",
              padding: "15px",
            }}
          ></div>
        </section>

        {cat == "coin" &&
          coin.contract_details &&
          address.substring(0, 2) != "id" && (
            <div className="parent-container">
              <iframe
                title={`${coin.symbol} Chart`}
                src={`https://dexscreener.com/${getChainName(
                  coin.contract_details[0].chain
                )}/${coin.contract_details[0].contract_address}?embed=1&theme=dark&info=0`}
                allowFullScreen
                className="dex-chart-iframe"
                style={{
                  width: "100%",
                  height: `100%`,
                }}
              ></iframe>
            </div>
          )}
        {coin.contract_details && (
          <div
            style={{
              marginTop: "10px",
            }}
          >
            <DiscussionEmbed
              shortname="fourtis"
              config={{
                url: "https://fourtis.disqus.com/" + coin._id,
                identifier: coin._id,
                title:
                  coin._id + "-" + coin.contract_details[0].contract_address,
                language: "English", //e.g. for Traditional Chinese (Taiwan)
              }}
            />
          </div>
        )}
      </div>
    </div>
  );
};

export default TokenDetailsCompo;
