// components/ResetPassword.js
import React, { useState } from "react";
import { useSearchParams, useNavigate } from "react-router-dom";
import axios from "axios";
import { useNotifications } from "@toolpad/core";
import "./../styles/micro/loginRegister.css";

const ResetPassword = () => {
  const [searchParams] = useSearchParams();
  const navigate = useNavigate();
  const notif = useNotifications();
  const [newPassword, setNewPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const [error, setError] = useState("");
  const [success, setSuccess] = useState(false);

  const handleSubmit = async (event) => {
    event.preventDefault();
    const token = searchParams.get("token");
    const userId = searchParams.get("id");

    if (!token || !userId) {
      setError("Invalid or missing token.");
      return;
    }

    if (newPassword !== confirmPassword) {
      setError("Passwords do not match.");
      return;
    }

    try {
      const response = await axios.post(
        "https://fourtis.xyz/api/auth/reset-password",
        {
          newPassword,
        },
        {
          params: {
            token,
            userId,
          },
        }
      );

      if (response.data.message) {
        setSuccess(true);
        notif.show("Password has been reset successfully!", {
          severity: "success",
          autoHideDuration: 3000,
        });
      } else {
        setError("Failed to reset password. Please try again.");
      }
    } catch (err) {
      setError("An error occurred. Please try again later.");
    }
  };

  return (
    <div className="reset-password-container">
      <h2>Reset Password</h2>
      {success ? (
        <p>
          Your password has been reset successfully. Redirecting to login...
        </p>
      ) : (
        <form onSubmit={handleSubmit}>
          <div className="input-container">
            <label htmlFor="newPassword">
              New Password <span>*</span>
            </label>
            <input
              type="password"
              id="newPassword"
              value={newPassword}
              onChange={(e) => setNewPassword(e.target.value)}
              required
            />
          </div>
          <div className="input-container">
            <label htmlFor="confirmPassword">
              Confirm New Password <span>*</span>
            </label>
            <input
              type="password"
              id="confirmPassword"
              value={confirmPassword}
              onChange={(e) => setConfirmPassword(e.target.value)}
              required
            />
          </div>
          {error && <div className="error-message">{error}</div>}
          <div className="submit-container">
            <button type="submit">Reset Password</button>
          </div>
        </form>
      )}
    </div>
  );
};

export default ResetPassword;
