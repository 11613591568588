import React from "react";
import {
  Accordion,
  AccordionSummary,
  AccordionDetails,
  Typography,
  Grid,
  Box,
} from "@mui/material";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";

// Sample scanResults data (Replace this with your actual data)
const scanResults = {
  isOpenSource: "Yes",
  isHoneypot: "No",
  isMintable: "Yes",
  isProxy: "No",
  slippageModifiable: "Yes",
  isBlacklisted: "No",
  sellTax: { min: 2, max: 5, status: "Normal" },
  buyTax: { min: 1, max: 4, status: "Normal" },
  isContractRenounced: "No",
  isPotentiallyScam: "No",
  updatedAt: "2024-09-19T12:00:00.000Z",
};

const ScanResultsAccordion = ({ scanResults }) => {
  // Helper function to format date
  const formatDate = (dateString) => {
    const options = {
      year: "numeric",
      month: "short",
      day: "numeric",
      hour: "2-digit",
      minute: "2-digit",
    };
    return new Date(dateString).toLocaleString(undefined, options);
  };

  return (
    <Box sx={{ width: "100%", maxWidth: 600 }}>
      <Accordion
        sx={{
          backgroundColor: "black",
          color: "white",
        }}
      >
        <AccordionSummary
          expandIcon={<ExpandMoreIcon color="success" />}
          aria-controls="scan-results-content"
          id="scan-results-header"
        >
          <Typography variant="h6" color="white">
            Scan Results
          </Typography>
        </AccordionSummary>
        <AccordionDetails>
          <Grid container spacing={1}>
            {/* Row 1 */}
            <Grid item xs={6}>
              <Typography variant="body2" fontWeight="bold" color="primary">
                Open Source:
              </Typography>
              <Typography variant="body2">
                {scanResults.isOpenSource}
              </Typography>
            </Grid>
            <Grid item xs={6}>
              <Typography variant="body2" fontWeight="bold" color="orange">
                Honeypot:
              </Typography>
              <Typography variant="body2">{scanResults.isHoneypot}</Typography>
            </Grid>

            {/* Row 2 */}
            <Grid item xs={6}>
              <Typography variant="body2" fontWeight="bold" color="orange">
                Mintable:
              </Typography>
              <Typography variant="body2">{scanResults.isMintable}</Typography>
            </Grid>
            <Grid item xs={6}>
              <Typography variant="body2" fontWeight="bold" color="orange">
                Proxy:
              </Typography>
              <Typography variant="body2">{scanResults.isProxy}</Typography>
            </Grid>

            {/* Row 3 */}
            <Grid item xs={6}>
              <Typography variant="body2" fontWeight="bold" color="primary">
                Slippage Modifiable:
              </Typography>
              <Typography variant="body2">
                {scanResults.slippageModifiable}
              </Typography>
            </Grid>
            <Grid item xs={6}>
              <Typography variant="body2" fontWeight="bold" color="orange">
                Blacklisted:
              </Typography>
              <Typography variant="body2">
                {scanResults.isBlacklisted}
              </Typography>
            </Grid>

            {/* Row 4 */}
            <Grid item xs={6}>
              <Typography variant="body2" fontWeight="bold" color="orange">
                Sell Tax:
              </Typography>
              <Typography variant="body2">
                {scanResults.sellTax.max * 100}% (
                {scanResults.sellTax.status})
              </Typography>
            </Grid>
            <Grid item xs={6}>
              <Typography variant="body2" fontWeight="bold" color="orange">
                Buy Tax:
              </Typography>
              <Typography variant="body2">
                {scanResults.buyTax.max * 100}% (
                {scanResults.buyTax.status})
              </Typography>
            </Grid>

            {/* Row 5 */}
            <Grid item xs={6}>
              <Typography variant="body2" fontWeight="bold" color="primary">
                Contract Renounced:
              </Typography>
              <Typography variant="body2">
                {scanResults.isContractRenounced}
              </Typography>
            </Grid>
            <Grid item xs={6}>
              <Typography variant="body2" fontWeight="bold" color="orange">
                Potential Scam:
              </Typography>
              <Typography variant="body2">
                {scanResults.isPotentiallyScam}
              </Typography>
            </Grid>

            {/* Row 6 */}
            <Grid item xs={12}>
              <Typography variant="body2" fontWeight="bold" color="primary">
                Updated At:
              </Typography>
              <Typography variant="body2">
                {formatDate(scanResults.updatedAt)}
              </Typography>
            </Grid>
          </Grid>
        </AccordionDetails>
      </Accordion>
    </Box>
  );
};

export default ScanResultsAccordion;
