// src/components/LoginRegister.js

import React, { useState, useEffect, useContext } from "react";
import "./../../styles/micro/loginRegister.css";
import axios from "axios";
import HCaptcha from "@hcaptcha/react-hcaptcha";
import { CryptoDataContext } from "./../../App";
import { useNotifications } from "@toolpad/core";

const LoginRegister = ({ onClose, isOpen }) => {
  const notif = useNotifications();
  const { setIsLoggedInState } = useContext(CryptoDataContext);

  // State to manage which form is rendered: 'login', 'register', 'forget'
  const [renderedForm, setRenderedForm] = useState("login");

  // Login form state
  const [loginEmail, setLoginEmail] = useState("");
  const [loginPassword, setLoginPassword] = useState("");

  // Register form state
  const [registerName, setRegisterName] = useState("");
  const [registerEmail, setRegisterEmail] = useState("");
  const [registerPassword1, setRegisterPassword1] = useState("");
  const [registerPassword2, setRegisterPassword2] = useState("");

  // Forgot password form state
  const [forgetEmail, setForgetEmail] = useState("");
  const [forgetMessage, setForgetMessage] = useState("");

  // Common state
  const [captchaToken, setCaptchaToken] = useState(null);
  const [error, setError] = useState("");
  const [success, setSuccess] = useState(false);

  // Resend verification email state
  const [resendSuccess, setResendSuccess] = useState(false);
  const [resendError, setResendError] = useState("");

  // Handle CAPTCHA verification
  const handleCaptchaVerification = (token) => {
    setCaptchaToken(token);
  };

  // Handle form overlay click to close modal
  const handleOverlayClick = (event) => {
    if (event.target === event.currentTarget) {
      onClose();
    }
  };

  // Handle Escape key to close modal
  useEffect(() => {
    const handleKeyDown = (event) => {
      if (event.key === "Escape") {
        onClose();
      }
    };
    if (isOpen) {
      document.addEventListener("keydown", handleKeyDown);
    }
    return () => {
      document.removeEventListener("keydown", handleKeyDown);
    };
  }, [isOpen, onClose]);

  // Handle Login Form Submission
  const handleSubmit = async (event) => {
    event.preventDefault();
    setError("");
    try {
      const res = await axios.post(`https://fourtis.xyz/api/auth/login`, {
        email: loginEmail,
        password: loginPassword,
      });
      if (res.data.token) {
        localStorage.setItem("token", res.data.token);
        localStorage.setItem("exp", res.data.exp);
        localStorage.setItem("user", JSON.stringify(res.data.user));
        setIsLoggedInState(true);
        notif.show("Logged in successfully!", {
          severity: "success",
          autoHideDuration: 3000,
        });
        onClose();
      }
    } catch (err) {
      if (
        err.response &&
        err.response.data &&
        err.response.data.message ===
          "Please verify your email before logging in."
      ) {
        setError(err.response.data.message);
      } else if (
        err.response &&
        err.response.data &&
        err.response.data.message
      ) {
        setError(err.response.data.message);
      } else {
        setError("Login failed. Please check your credentials.");
      }
    }
  };

  // Handle Register Form Submission
  const handleSubmitRegister = async (event) => {
    event.preventDefault();
    setError("");
    setSuccess(false);
    if (!captchaToken) {
      notif.show("Please complete CAPTCHA and try again!", {
        severity: "error",
        autoHideDuration: 3000,
      });
      return;
    }
    if (registerPassword1 !== registerPassword2) {
      notif.show("Passwords do not match!", {
        severity: "error",
        autoHideDuration: 3000,
      });
      return;
    }
    if (!registerEmail || !registerName || !registerPassword1) {
      notif.show("All fields are required!", {
        severity: "error",
        autoHideDuration: 3000,
      });
      return;
    }
    try {
      const response = await axios.post(
        `https://fourtis.xyz/api/auth/register`,
        {
          name: registerName,
          email: registerEmail,
          password: registerPassword1,
          captchaToken,
        }
      );
      if (response.status === 201) {
        setSuccess(true);
        notif.show(
          "Registered successfully! Please check your email to verify your account.",
          {
            severity: "success",
            autoHideDuration: 5000,
          }
        );
        // Optionally, switch to login form after registration
        // setRenderedForm("login");
      } else {
        notif.show("Registration failed. Please try again.", {
          severity: "error",
          autoHideDuration: 3000,
        });
      }
    } catch (err) {
      if (err.response && err.response.data && err.response.data.message) {
        setError(err.response.data.message);
      } else {
        setError("An error occurred during registration.");
      }
      notif.show("An error occurred during registration.", {
        severity: "error",
        autoHideDuration: 3000,
      });
    }
  };

  // Handle Forgot Password Form Submission
  const handleSubmitForget = async (event) => {
    event.preventDefault();
    setError("");
    setForgetMessage("");
    try {
      const response = await axios.post(
        `https://fourtis.xyz/api/auth/forgot-password`,
        {
          email: forgetEmail,
        }
      );
      if (response.data.message) {
        setForgetMessage("Check your email for password reset instructions.");
        notif.show("Password reset email sent. Please check your email.", {
          severity: "success",
          autoHideDuration: 5000,
        });
      } else {
        setError("Failed to send reset email. Please try again.");
        notif.show("Failed to send reset email. Please try again.", {
          severity: "error",
          autoHideDuration: 3000,
        });
      }
    } catch (err) {
      if (err.response && err.response.data && err.response.data.message) {
        setError(err.response.data.message);
      } else {
        setError("An error occurred. Please try again later.");
      }
      notif.show("An error occurred. Please try again later.", {
        severity: "error",
        autoHideDuration: 3000,
      });
    }
  };

  // Handle Resend Verification Email
  const handleResendVerification = async () => {
    setResendError("");
    setResendSuccess(false);
    try {
      const response = await axios.post(
        `https://fourtis.xyz/api/auth/resend-verification`,
        {
          email: loginEmail,
        }
      );
      if (response.data.message) {
        setResendSuccess(true);
        notif.show(response.data.message, {
          severity: "success",
          autoHideDuration: 5000,
        });
      }
    } catch (err) {
      if (err.response && err.response.data && err.response.data.message) {
        setResendError(err.response.data.message);
      } else {
        setResendError("An error occurred. Please try again later.");
      }
      notif.show("An error occurred. Please try again later.", {
        severity: "error",
        autoHideDuration: 3000,
      });
    }
  };

  // Render the appropriate form based on renderedForm state
  const renderForm = () => {
    switch (renderedForm) {
      case "login":
        return (
          <div className="modal-content">
            <div className="log-reg-title">
              <span
                className="close-button"
                onClick={onClose}
                style={{ backgroundColor: "transparent", boxShadow: "none" }}
              >
                &times;
              </span>
              <h2>Login</h2>
            </div>
            <div className="form">
              <form onSubmit={handleSubmit}>
                <div className="input-container">
                  <label htmlFor="loginEmail">
                    <b>Username or Email Address</b> <span>*</span>
                  </label>
                  <input
                    style={{
                      backgroundColor: "#0b0e0e",
                      color: "white",
                      borderStyle: "none",
                    }}
                    type="text"
                    id="loginEmail"
                    name="loginEmail"
                    placeholder="Enter Email ..."
                    value={loginEmail}
                    onChange={(e) => setLoginEmail(e.target.value)}
                    autoFocus
                    required
                  />
                </div>
                <div className="input-container">
                  <label htmlFor="loginPassword">
                    <b>Password</b> <span>*</span>
                  </label>
                  <input
                    style={{
                      backgroundColor: "#0b0e0e",
                      color: "white",
                      borderStyle: "none",
                    }}
                    type="password"
                    id="loginPassword"
                    name="loginPassword"
                    placeholder="Enter Password ..."
                    value={loginPassword}
                    onChange={(e) => setLoginPassword(e.target.value)}
                    required
                  />
                </div>
                {/* Display error messages */}
                {error && (
                  <div className="error-message">
                    {error}
                    {error ===
                      "Please verify your email before logging in." && (
                      <div>
                        <button
                          type="button"
                          onClick={handleResendVerification}
                          className="resend-button"
                        >
                          Resend Verification Email
                        </button>
                        {resendSuccess && (
                          <span className="success-text">
                            {" "}
                            Verification email sent.
                          </span>
                        )}
                        {resendError && (
                          <span className="error-text"> {resendError}</span>
                        )}
                      </div>
                    )}
                  </div>
                )}
                <div className="log-btn-cntr">
                  <button type="submit">Login</button>
                </div>
                <div
                  className="frgt-rgstr-cntr"
                  style={{
                    color: "white",
                  }}
                >
                  <span onClick={() => setRenderedForm("register")}>
                    Sign Up
                  </span>
                  <span onClick={() => setRenderedForm("forget")}>
                    Forgot Password
                  </span>
                </div>
              </form>
            </div>
          </div>
        );
      case "register":
        return (
          <div>
            <div className="modal-header">
              <h2>Register</h2>
              <span className="close-button-register" onClick={onClose}>
                &times;
              </span>
            </div>
            {success ? (
              <div className="success-message">
                You have registered successfully! Please check your email to
                verify your account.
              </div>
            ) : (
              <form onSubmit={handleSubmitRegister}>
                <div className="input-container">
                  <label htmlFor="registerName">
                    Name <span>*</span>
                  </label>
                  <input
                    style={{
                      backgroundColor: "#0b0e0e",
                      color: "white",
                      borderStyle: "none",
                    }}
                    type="text"
                    id="registerName"
                    value={registerName}
                    onChange={(e) => setRegisterName(e.target.value)}
                    required
                  />
                </div>
                <div className="input-container">
                  <label htmlFor="registerEmail">
                    Email Address <span>*</span>
                  </label>
                  <input
                    style={{
                      backgroundColor: "#0b0e0e",
                      color: "white",
                      borderStyle: "none",
                    }}
                    type="email"
                    id="registerEmail"
                    value={registerEmail}
                    onChange={(e) => setRegisterEmail(e.target.value)}
                    required
                  />
                </div>
                <div className="input-container">
                  <label htmlFor="registerPassword1">
                    Password <span>*</span>
                  </label>
                  <input
                    style={{
                      backgroundColor: "#0b0e0e",
                      color: "white",
                      borderStyle: "none",
                    }}
                    type="password"
                    id="registerPassword1"
                    value={registerPassword1}
                    onChange={(e) => setRegisterPassword1(e.target.value)}
                    required
                  />
                </div>
                <div className="input-container">
                  <label htmlFor="registerPassword2">
                    Confirm Password <span>*</span>
                  </label>
                  <input
                    style={{
                      backgroundColor: "#0b0e0e",
                      color: "white",
                      borderStyle: "none",
                    }}
                    type="password"
                    id="registerPassword2"
                    value={registerPassword2}
                    onChange={(e) => setRegisterPassword2(e.target.value)}
                    required
                  />
                </div>
                <div className="captcha-container">
                  <HCaptcha
                    sitekey="243e27ba-6dc4-4724-a652-0f848ebc6040" // Replace with your HCaptcha site key
                    onVerify={handleCaptchaVerification}
                  />
                </div>
                {/* Display error messages */}
                {error && <div className="error-message">{error}</div>}
                <div className="submit-container">
                  <button type="submit">Register</button>
                </div>
                <div
                  className="already-registered"
                  style={{
                    color: "white",
                    cursor: "pointer",
                  }}
                  onClick={() => setRenderedForm("login")}
                >
                  Log In
                </div>
              </form>
            )}
          </div>
        );
      case "forget":
        return (
          <div>
            <div className="modal-header">
              <span
                className="back-to-login"
                onClick={() => setRenderedForm("login")}
              >
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  className="icon icon-tabler icon-tabler-arrow-left"
                  width="16"
                  height="16"
                  viewBox="0 0 24 24"
                  stroke="currentColor"
                  strokeWidth="2"
                  fill="none"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                >
                  <path stroke="none" d="M0 0h24v24H0z" fill="none"></path>
                  <line x1="5" y1="12" x2="19" y2="12"></line>
                  <line x1="5" y1="12" x2="11" y2="18"></line>
                  <line x1="5" y1="12" x2="11" y2="6"></line>
                </svg>
              </span>
              <h2>Forgot Password</h2>
            </div>
            {forgetMessage ? (
              <div className="success-message">{forgetMessage}</div>
            ) : (
              <form onSubmit={handleSubmitForget}>
                <div className="input-container">
                  <label htmlFor="forgetEmail">
                    Username or Email Address <span>*</span>
                  </label>
                  <input
                    type="text"
                    id="forgetEmail"
                    value={forgetEmail}
                    onChange={(e) => setForgetEmail(e.target.value)}
                    required
                  />
                </div>
                {/* Display error messages */}
                {error && <div className="error-message">{error}</div>}
                <div className="submit-container">
                  <button type="submit">Submit</button>
                </div>
              </form>
            )}
          </div>
        );
      default:
        return null;
    }
  };

  // If the modal is not open, don't render anything
  if (!isOpen) {
    return null;
  }

  return (
    <div className="modal-overlay" onClick={handleOverlayClick}>
      <div
        className="modal"
        style={{
          backgroundColor: "black",
          boxShadow: "0px 0px 10px black",
        }}
      >
        {renderForm()}
      </div>
    </div>
  );
};

export default LoginRegister;
