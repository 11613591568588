import React, { useEffect, useState } from "react";
import "./../../styles/micro/changePasswordModal.css";
import axios from "axios";
import { useNotifications } from "@toolpad/core";

const ChangePasswordModal = ({ isModalOpen, onClose, isSuccess }) => {
  const notif = useNotifications();
  const [oldPassword, setOldPassword] = useState("");
  const [newPassword, setNewPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const [user, setUser] = useState(localStorage.getItem("user"));
  const [error, setError] = useState("");
  const [success, setSuccess] = useState(isSuccess);

  const logout = () => {
    localStorage.removeItem("token");
    localStorage.removeItem("exp");
    localStorage.removeItem("user");
  };

  useEffect(() => {
    setSuccess(isSuccess);
  }, [isSuccess]);

  const handleSubmit = (event) => {
    event.preventDefault();
    if (newPassword !== confirmPassword) {
      setError("New Password and Confirm New Password do not match.");
    } else {
      setError("");
      axios
        .put(
          "https://fourtis.xyz/api/auth/change-password",
          {
            oldPassword,
            newPassword,
          },
          {
            headers: {
              Authorization:
                "Bearer " +
                localStorage
                  .getItem("token")
                  .substring(1, localStorage.getItem("token").length - 1),
            },
          }
        )
        .then((res) => {
          if (res.data.message == "Password changed successfully") {
            setSuccess(true);
            logout();
          }
        })
        .catch((e) => {
          setSuccess(false);
          notif.show("Changing password faild!", {
            severity: "error",
            autoHideDuration: 3000,
          });
        });
    }
  };

  useEffect(() => {
    setSuccess(false);
    return () => {
      setSuccess(false);
    };
  }, []);

  if (!isModalOpen) {
    return null;
  }
  return (
    <div className="modal-overlay" onClick={onClose}>
      <div className="modal" onClick={(e) => e.stopPropagation()}>
        <div className="modal-header">
          <h2>Change Password</h2>
          <span className="modal-close-button" onClick={onClose}>
            &times;
          </span>
        </div>
        {success ? (
          <div className="success-message">Password changed successfully!</div>
        ) : (
          <form onSubmit={handleSubmit}>
            <div className="input-container">
              <label htmlFor="oldPassword">
                Old Password <span>*</span>
              </label>
              <input
                type="password"
                id="oldPassword"
                value={oldPassword}
                onChange={(e) => setOldPassword(e.target.value)}
                required
              />
            </div>
            <div className="input-container">
              <label htmlFor="newPassword">
                New Password <span>*</span>
              </label>
              <input
                type="password"
                id="newPassword"
                value={newPassword}
                onChange={(e) => setNewPassword(e.target.value)}
                required
              />
            </div>
            <div className="input-container">
              <label htmlFor="confirmPassword">
                Confirm New Password <span>*</span>
              </label>
              <input
                type="password"
                id="confirmPassword"
                value={confirmPassword}
                onChange={(e) => setConfirmPassword(e.target.value)}
                required
              />
            </div>
            {error && <div className="error-message">{error}</div>}
            <div className="submit-container">
              <button type="submit">Submit</button>
            </div>
          </form>
        )}
      </div>
    </div>
  );
};

export default ChangePasswordModal;
