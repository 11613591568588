import React from 'react'
import SlidingProgressBar from '../component/micro/slidingProgressBar'
import CryptoCollageBanner from '../component/micro/cryptoCollageBanner'
import Carousel from '../component/micro/carousel'
import CryptoTile from '../component/micro/cryptoTile'
import DraggableSlider from '../component/micro/draggableSlider'
import MarketTrendTabs from '../component/micro/marketTrendTabs'
import CryptoDashboard from '../component/micro/cryptoDashboard'

const CoinRanking = () => {
  return (
    <React.Fragment>
      <SlidingProgressBar />
      <CryptoCollageBanner />
      <Carousel />
      <CryptoTile />
      <MarketTrendTabs />
      <CryptoDashboard />
    </React.Fragment>
  )
}

export default CoinRanking