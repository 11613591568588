import React, { useEffect, useState, useContext } from "react";
import { Link, useNavigate } from "react-router-dom";
import "./../../styles/micro/coinPulse.css";
import fourtis from "../../images/Circle.png";
import axios from "axios";
import { NewsContext } from "./newsContext/newsContext";

const CoinPulseCompo = () => {
  const { setNewsIds } = useContext(NewsContext);
  const navigate = useNavigate();

  const announcement = {
    category: "All",
    title: "Platform Launch: Welcome to Fourtis!",
    text: "We are excited to announce the launch of Fourtis! Our platform brings together the latest in cryptocurrency rankings, news, and analysis. Whether you're interested in DeFi, NFTs, or the latest meme coins, Fourtis is your go-to source for real-time updates.",
    description:
      "Fourtis has officially launched, introducing a comprehensive platform...",
    content:
      "Fourtis has officially launched, introducing a comprehensive platform for cryptocurrency enthusiasts, traders, and investors. Explore a wealth of features and stay updated on all the major developments in the crypto world.",
    publishedAt: "09-17-2024",
    urlToImage: fourtis,
    site: "CoinIndex",
    url: "https://fourtis.io",
    id: "/crypto",
  };

  const [news, setNews] = useState([announcement]);
  const [categorizedNews, setCategorizedNews] = useState([]);
  const [categorizedFactor, setCategorizedFactor] = useState("crypto");
  const [category, setCategory] = useState("crypto");
  const [page, setPage] = useState(0);

  function generateRandomString(length) {
    return Math.random().toString(36).substr(2, length);
  }

  const onCatClicked = async (cat) => {
    let pagen = page;
    if (cat != category) {
      setPage(0);
      pagen = 0;
    }
    await fetchNews(cat, pagen + 1, false);
  };

  const onLoadMoreClicked = async (cat) => {
    console.log("clicked on load more");
    console.log(cat);
    await fetchNews(cat, page + 1, true);
  };

  useEffect(() => {
    fetchNews(category, 1, false);
  }, []);

  const fetchNews = async (category, pageNumber = 1, loadMore = false) => {
    try {
      if (loadMore) {
        setPage(pageNumber);
      }
      setCategory(category);
      const response = await axios.get(
        "https://fourtis.xyz/api/news/" + category + "/" + pageNumber
      );
      if (response.data.articles.length > 0) {
        let fetchedNews = response.data.articles;
        for (let i = 0; i < fetchedNews.length; i++) {
          fetchedNews[i].id = fetchedNews[i].id
            .replace("_", "/")
            .replace("_", "/");
        }
        fetchedNews = fetchedNews.filter((n) => n.title != "[Removed]");
        if (loadMore) {
          fetchedNews = [...news, ...fetchedNews];
        }

        fetchedNews[0] = announcement;
        fetchedNews[0].id = `${category}/${pageNumber}/100000`;
        setNews(fetchedNews);

        let newIds = {};
        fetchedNews.forEach((n) => {
          newIds[n.id] = n;
        });
        setNewsIds(newIds);
        setPage(pageNumber); // Update the page state
      } else {
        console.error("Failed to load news");
      }
    } catch (error) {
      console.error("Error fetching news:", error);
    }
  };

  return (
    <div className="pulse-cntr">
      <h2>Newsroom</h2>
      <div className="segmented-cntrl">
        <div
          className="all segment"
          style={{
            backgroundColor:
              categorizedFactor === "" ? "rgb(45, 45, 45)" : "transparent",
          }}
          onClick={() => onCatClicked("crypto")}
        >
          All
        </div>
        |
        <div
          className="btc segment"
          style={{
            backgroundColor:
              categorizedFactor === "btc" ? "rgb(45, 45, 45)" : "transparent",
          }}
          onClick={() => onCatClicked("bitcoin")}
        >
          BTC
        </div>
        |
        <div
          className="alt-coin segment"
          style={{
            backgroundColor:
              categorizedFactor === "altcoin"
                ? "rgb(45, 45, 45)"
                : "transparent",
          }}
          onClick={() => onCatClicked("altcoin")}
        >
          AltCoin
        </div>
        |
        <div
          className="defi segment"
          style={{
            backgroundColor:
              categorizedFactor === "defi" ? "rgb(45, 45, 45)" : "transparent",
          }}
          onClick={() => onCatClicked("defi")}
        >
          Defi
        </div>
        |
        <div
          className="nft segment"
          style={{
            backgroundColor:
              categorizedFactor === "nft" ? "rgb(45, 45, 45)" : "transparent",
          }}
          onClick={() => onCatClicked("NFT")}
        >
          NFT
        </div>
        |
        <div
          className="meme segment"
          style={{
            backgroundColor:
              categorizedFactor === "meme" ? "rgb(45, 45, 45)" : "transparent",
          }}
          onClick={() => onCatClicked("memecoin")}
        >
          Meme
        </div>
        |
        <div
          className="featured-news segment"
          style={{
            backgroundColor:
              categorizedFactor === "featured news"
                ? "rgb(45, 45, 45)"
                : "transparent",
          }}
          onClick={() => onCatClicked("cryptocurrency")}
        >
          Featured News
        </div>
      </div>
      <div className="news-cntr">
        {news.map((newsItem, index) => {
          return (
            <Link
              to={`/coin-news-details/${newsItem.id}`}
              className="link"
              key={index}
            >
              <div className="trend-cntr">
                <div className="img-news-cntr">
                  <img src={newsItem.urlToImage} alt="" className="img-news" />
                </div>
                <div className="info-cntr-pulse">
                  <div className="title-info-news">
                    <h3> {newsItem.title} </h3>
                    <span> {newsItem.url} </span>
                    <span> {newsItem.Date} </span>
                  </div>
                  <div className="abstract-news">{newsItem.description}</div>
                </div>
              </div>
            </Link>
          );
        })}
        <div className="load-more-cntr">
          <button
            className="load-more-btn"
            onClick={() => onLoadMoreClicked(category)}
          >
            {" "}
            Load More
          </button>
        </div>
      </div>
    </div>
  );
};

export default CoinPulseCompo;
