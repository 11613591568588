import React, { useState, useContext, useEffect } from "react";
import { Link, useNavigate } from "react-router-dom";
import axios from "axios";
import "./../../styles/micro/marketTrendTabs.css";
import ETH from "./../../images/icon/crypto/eth.png";
import Rikowako from "./../../images/icon/crypto/rikowako.jpg";
import Iro from "./../../images/icon/crypto/iro.jpg";
import Mellow from "./../../images/icon/crypto/mellow.jpg";
import Paga from "./../../images/icon/crypto/paga.jpg";
import Weiwei from "./../../images/icon/crypto/weiwei.jpg";
import Neiro from "./../../images/icon/crypto/neiro.jpg";
import Ginnan from "./../../images/icon/crypto/ginnan.jpg";
import SOL from "./../../images/icon/crypto/sol.png";
import NieroSol from "./../../images/icon/crypto/neiro-sol.jpg";
import Rizo from "./../../images/icon/crypto/rizo.jpg";
import Neet from "./../../images/icon/crypto/neet.jpg";
import Cfsm from "./../../images/icon/crypto/cfsm.jpg";
import Aitune from "./../../images/icon/crypto/aitune.jpg";
import Pickle from "./../../images/icon/crypto/pickle.jpg";
import Kieth from "./../../images/icon/crypto/kieth.jpg";
import Gainers from "./../../images/icon/gainers.svg";
import Presales from "./../../images/icon/presales.svg";
import Latest from "./../../images/icon/latest.svg";
import Token from "./../../images/icon/crypto/token.avif";
import chains from "../../data/chains.json";
import { CryptoDataContext } from "../../App";
import { formatCoinValue } from "../../helpers/formatPrice";

const MarketTrendTabs = () => {
  const [trends, setTrends] = useState([
    {
      name: "RIKOWAKA",
      status: "presale",
      ground: "ETH",
      imageGround: ETH,
      date: new Date("2010-04-23"),
      image: Rikowako,
      network: "Binance",
      oscillation: "+37%",
    },
    {
      name: "IRO",
      image: Iro,
      network: "Iro-Chan",
      oscillation: "+82%",
      status: "",
      ground: "ETH",
      imageGround: ETH,
      date: new Date("2014-07-22"),
    },
    {
      name: "MELLOW",
      status: "",
      ground: "ETH",
      imageGround: ETH,
      date: new Date("2019-07-03"),
      image: Mellow,
      network: "Mellow Man",
      oscillation: "-25%",
    },
    {
      name: "PAGA",
      status: "presale",
      ground: "SOL",
      imageGround: SOL,
      date: new Date("2022-07-17"),
      image: Paga,
      network: "PepeWifMaga",
      oscillation: "-42%",
    },
    {
      name: "WEIWEI",
      status: "",
      ground: "ETH",
      imageGround: ETH,
      date: new Date("2023-04-29"),
      image: Weiwei,
      network: "WeiWei",
      oscillation: "+7.5454%",
    },
    {
      name: "NEIRO",
      image: Neiro,
      network: "Neiro",
      oscillation: "-32%",
      status: "",
      ground: "SOL",
      imageGround: SOL,
      date: new Date("2017-09-12"),
    },
    {
      name: "GINAN",
      status: "presale",
      ground: "ETH",
      imageGround: ETH,
      date: new Date("2023-01-04"),
      image: Ginnan,
      network: "Ginan Doge's",
      oscillation: "-9.82%",
    },
    {
      name: "NEIRO",
      status: "presale",
      ground: "ETH",
      imageGround: ETH,
      date: new Date("2015-10-21"),
      image: NieroSol,
      network: "Neiro",
      oscillation: "-37%",
    },
    {
      name: "RIZO",
      status: "presale",
      ground: "SOL",
      imageGround: SOL,
      date: new Date("2023-12-02"),
      image: Rizo,
      network: "HahaYes",
      oscillation: "+79%",
    },
    {
      name: "NEET",
      status: "",
      ground: "SOL",
      imageGround: SOL,
      date: new Date("2022-09-11"),
      image: Neet,
      network: "NEET",
      oscillation: "-251.254%",
    },
    {
      name: "CFSM",
      status: "",
      ground: "SOL",
      imageGround: SOL,
      date: new Date("2024-05-28"),
      image: Cfsm,
      network: "Flying Spaghetti",
      oscillation: "+42%",
    },
    {
      name: "AITUNE",
      status: "presale",
      ground: "ETH",
      imageGround: ETH,
      date: new Date("2021-03-27"),
      image: Aitune,
      network: "AiTune",
      oscillation: "+7%",
    },
    {
      name: "PICKLE",
      status: "",
      ground: "SOL",
      imageGround: SOL,
      date: new Date("2024-06-18"),
      image: Pickle,
      network: "PICKLE",
      oscillation: "-32%",
    },
    {
      name: "Kieth",
      status: "",
      ground: "SOL",
      imageGround: SOL,
      date: new Date("2024-06-18"),
      image: Kieth,
      network: "Invisible Cat",
      oscillation: "+9%",
    },
  ]);
  const [latest, setLatest] = useState([]);
  const [losers, setLosers] = useState([]);
  const [gainers, setGainers] = useState([]);
  const navigate = useNavigate();
  const { isLoggedInState, setCoinForDetail } = useContext(CryptoDataContext);

  useEffect(() => {
    const getStatistics = async () => {
      let result = await axios.get("https://fourtis.xyz/api/latestList");
      setLatest(result.data.data);

      result = await axios.get("https://fourtis.xyz/api/losers");
      setLosers(result.data.data);

      result = await axios.get("https://fourtis.xyz/api/gainers");
      setGainers(result.data.data);
    };
    getStatistics();
  }, []);

  return (
    <div className="market-trend-tab-cntr">
      <div className="gainers-trend market-trend-tab">
        <div className="trend-tab-hdr-cntr">
          <div className="hdr-img-name-cntr">
            <img src={Gainers} alt="" className="hdr-img" />
            <div className="hdr-name">Gainers</div>
          </div>
          <div className="hdr-more-link-cntr">
            <Link to="/coin-listing/gainers" className="hdr-more-link">
              {" "}
              More &gt;{" "}
            </Link>
          </div>
        </div>
        <div className="trend-tab-main">
          {gainers.length > 0 &&
            gainers.map((gainer, index) => {
              return (
                <div>
                  <div
                    className="trend-tab"
                    key={index}
                    onClick={() => {
                      navigate(
                        "/token-details/coin/" +
                          gainer.contract_details[0].chain +
                          "/" +
                          (gainer.contract_details[0].contract_address == "TBA"
                            ? "id=" + gainer._id
                            : gainer.contract_details[0].contract_address)
                      );
                    }}
                  >
                    <div className="main-img-name-cntr">
                      <div className="main-img-cntr">
                        <img
                          src={gainer.logo_url}
                          alt=""
                          className="main-img"
                        />
                        <img
                          src={
                            "https://fourtis.xyz/images/" +
                            chains.logos[gainer.contract_details[0].chain]
                          }
                          alt=""
                          className="main-svg"
                        />
                      </div>
                      <div className="main-name-info-cntr">
                        <div className="main-name">{gainer.symbol}</div>
                        <div className="main-info">{gainer.name}</div>
                      </div>
                    </div>
                    <div
                      className="main-osciltn"
                      style={{
                        color:
                          parseFloat(gainer.variation6h) >= 0
                            ? "rgb(46, 246, 46)"
                            : "rgb(220, 16, 16)",
                      }}
                    >
                      {gainer.variation6h
                        ? formatCoinValue(gainer.variation6h)
                        : 0}{" "}
                      %
                    </div>
                  </div>
                </div>
              );
            })}
        </div>
      </div>
      <div className="presales-trend market-trend-tab">
        <div className="trend-tab-hdr-cntr">
          <div className="hdr-img-name-cntr">
            <img src={Presales} alt="" className="hdr-img" />
            <div className="hdr-name">Losers</div>
          </div>
          <div className="hdr-more-link-cntr">
            {/* <Link to="/coin-listing/presales" className="hdr-more-link">
              {" "}
              More &gt;{" "}
            </Link> */}
          </div>
        </div>
        <div className="trend-tab-main">
          {losers.length > 0 &&
            losers.map((loser, index) => {
              return (
                <a href="#" target="_self">
                  <div
                    className="trend-tab"
                    key={index}
                    onClick={() => {
                      // navigate("/token-details/" + pres.chains[0].contract_address);
                      navigate(
                        "/token-details/coin/" +
                          loser.contract_details[0].chain +
                          "/" +
                          (loser.contract_details[0].contract_address == "TBA"
                            ? "id=" + loser._id
                            : loser.contract_details[0].contract_address)
                      );
                    }}
                  >
                    <div className="main-img-name-cntr">
                      <div className="main-img-cntr">
                        {loser ? (
                          <img
                            src={loser.logo_url}
                            alt=""
                            className="main-img"
                          />
                        ) : (
                          <img src={Token} alt="" className="main-img" />
                        )}
                        <img
                          src={
                            "https://fourtis.xyz/images/" +
                            chains.logos[loser.contract_details[0].chain]
                          }
                          alt=""
                          className="main-svg"
                        />
                      </div>
                      <div className="main-name-info-cntr">
                        <div className="main-name">{loser.symbol}</div>
                        <div className="main-info">{loser.name}</div>
                      </div>
                    </div>
                    <div className="main-osciltn">
                      {/* <img
                        src={pres.presale_platform.image}
                        alt=""
                        className="main-osciltn-img"
                      /> */}
                      <span
                        style={{
                          color:
                            parseFloat(loser.variation6h) >= 0
                              ? "rgb(46, 246, 46)"
                              : "rgb(220, 16, 16)",
                        }}
                      >
                        {" "}
                        {loser.variation6h
                          ? formatCoinValue(loser.variation6h)
                          : 0}{" "}
                        %
                      </span>
                    </div>
                  </div>
                </a>
              );
            })}
        </div>
      </div>
      <div className="latest-trend market-trend-tab">
        <div className="trend-tab-hdr-cntr">
          <div className="hdr-img-name-cntr">
            <img src={Latest} alt="" className="hdr-img" />
            <div className="hdr-name">Latest</div>
          </div>
          <div className="hdr-more-link-cntr">
            <Link to="/coin-listing/latest" className="hdr-more-link">
              {" "}
              More &gt;{" "}
            </Link>
          </div>
        </div>
        <div className="trend-tab-main">
          {latest.length > 0 &&
            latest.map((latest, index) => {
              return (
                <a href="#" target="_blank">
                  <div
                    className="trend-tab"
                    key={index}
                    onClick={() => {
                      navigate(
                        "/token-details/coin/" +
                          latest.contract_details[0].chain +
                          "/" +
                          (latest.contract_details[0].contract_address == "TBA"
                            ? "id=" + latest._id
                            : latest.contract_details[0].contract_address)
                      );
                    }}
                  >
                    <div className="main-img-name-cntr">
                      <div className="main-img-cntr">
                        <img
                          src={latest.logo_url}
                          alt=""
                          className="main-img"
                        />
                        <img
                          src={
                            "https://fourtis.xyz/images/" +
                            chains.logos[latest.contract_details[0].chain]
                          }
                          alt=""
                          className="main-svg"
                        />
                      </div>
                      <div className="main-name-info-cntr">
                        <div className="main-name">{latest.symbol}</div>
                        <div className="main-info">{latest.name}</div>
                      </div>
                    </div>
                    <div
                      className="main-osciltn"
                      style={{
                        color:
                          parseFloat(latest.variation24h) >= 0
                            ? "rgb(46, 246, 46)"
                            : "rgb(220, 16, 16)",
                      }}
                    >
                      {latest.variation6h
                        ? formatCoinValue(latest.variation24h)
                        : 0}
                      %
                    </div>
                  </div>
                </a>
              );
            })}
        </div>
      </div>
    </div>
  );
};

export default MarketTrendTabs;
