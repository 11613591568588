import React, { useState, useEffect, useRef } from "react";
import axios from "axios";
import "./../../styles/micro/coinClip.css";
import beyzahzah from "./../../images/icon/clip/pexels-beyzahzah-89810429-17977100.jpg";
import CryptopPumpBitcoinPumpGif from "./../../images/icon/clip/pexels-jonathanborba-14856609.jpg";
import jonathanborba from "./../../images/icon/clip/pexels-jonathanborba-14902676.jpg";
import PumpGrowthGif from "./../../images/icon/clip/pump-growth.gif";

const MoonClip = () => {
  // State for video clips
  const [clips, setClips] = useState([]);

  const [playingClip, setPlayingClip] = useState(null);
  const [page, setPage] = useState(1);
  const [loading, setLoading] = useState(false);

  // State for GIFs
  const [gifs, setGifs] = useState([]);
  const [searchTerm, setSearchTerm] = useState("Dogecoin"); // Default search term
  const [gifLoading, setGifLoading] = useState(false);
  const [gifPos, setGifPos] = useState(""); // Position for pagination
  const [hasMoreGifs, setHasMoreGifs] = useState(true); // To track if more GIFs are available

  // API Keys (Use environment variables for security)
  const TENOR_API_KEY = "AIzaSyBBaMZ-aDrIlvBUtadx0r58y3SYqb5o2N4";
  const TENOR_CLIENT_KEY = "my_test_app"; // Replace with your client key

  // List of popular meme coins (30+)
  const memeCoinList = [
    "Dogecoin",
    "Shiba Coin",
    "SafeMoon",
    "Floki Inu",
    "Baby Doge Coin",
    "Akita Inu",
    "Kishu Inu",
    "Dogelon Mars",
    "Hoge Finance",
    "CumRocket",
    "MonaCoin",
    "Pitbull",
    "Samoyedcoin",
    "ElonGate",
    "Saitama Inu",
    "Husky",
    "Bugs Bunny Coin",
    "Doge Killer",
    "Mini Doge",
    "Little Lion Inu",
    "PugLife",
    "Tenk",
    "NyanCoin",
    "MiniShiba",
    "Kitty Inu",
    "Mochi Inu",
    "Cheems Inu",
    "Goku Inu",
    "Corgi Inu",
    "PandaCoin",
    "DogePad",
    "DogeFi",
    "Spheroid Universe",
    // Add more meme coins as needed
  ];

  useEffect(() => {
    fetchClips(page);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [page]);

  // Function to fetch video clips
  const fetchClips = async (pageNumber) => {
    try {
      setLoading(true);
      const response = await axios.get(
        `https://your-real-api.com/clips?page=${pageNumber}`
      ); // Replace with your actual API endpoint
      setClips((prevClips) => [...prevClips, ...response.data]);
      setLoading(false);
    } catch (error) {
      console.error("Error fetching clips:", error);
      setLoading(false);
    }
  };

  // Function to load more video clips
  const loadMoreClips = () => {
    setPage((prevPage) => prevPage + 1);
  };

  // Handle click on video clip
  const handleClick = (id) => {
    setPlayingClip(id === playingClip ? null : id); // Toggle play/pause
  };

  // Function to fetch GIFs from Tenor API
  const fetchGifs = async (term, pos = "") => {
    try {
      setGifLoading(true);
      const response = await axios.get(
        "https://tenor.googleapis.com/v2/search",
        {
          params: {
            q: term,
            key: TENOR_API_KEY,
            client_key: TENOR_CLIENT_KEY,
            limit: 10, // Fetch 10 GIFs per request
            media_filter: "minimal", // To reduce response size
            pos: pos, // Position for pagination
          },
        }
      );

      if (response.data.results && response.data.results.length > 0) {
        const fetchedGifs = response.data.results.map((gif) => ({
          id: gif.id,
          previewUrl: gif.media_formats?.gif?.url || "", // Use animated GIF URL
          shareUrl: gif.media_formats?.gif?.url || "",
          title: gif.content_description || "No Description",
        }));

        setGifs((prevGifs) => [...prevGifs, ...fetchedGifs]);
        setGifPos(response.data.next || ""); // Update position for next fetch
        setHasMoreGifs(!!response.data.next); // Check if more GIFs are available
      } else {
        setHasMoreGifs(false); // No more GIFs to fetch
      }

      setGifLoading(false);
    } catch (error) {
      console.error("Error fetching GIFs:", error);
      setGifLoading(false);
    }
  };

  // Handle search form submission
  const handleSearch = (e) => {
    e.preventDefault();
    if (memeCoinList.includes(searchTerm)) {
      setGifs([]); // Clear existing GIFs
      fetchGifs(searchTerm);
    } else {
      alert("Please select a valid meme coin from the list.");
    }
  };

  // Handle load more for GIFs
  const loadMoreGifs = () => {
    if (hasMoreGifs) {
      fetchGifs(searchTerm, gifPos);
    }
  };

  // Initial fetch for default search term
  useEffect(() => {
    fetchGifs(searchTerm);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <div className="moonclip">
      <h2>Fourtris Clip | Popular Meme Coin GIFs</h2>

      {/* Video Clips Section */}
      <div className="clips">
        {clips.map((clip) => (
          <div
            key={clip.id}
            className="clip"
            onClick={() => handleClick(clip.id)}
          >
            {playingClip === clip.id ? (
              <video
                src={clip.url}
                autoPlay
                muted
                loop
                controls
                style={{ width: "100%" }}
                loading="lazy"
              />
            ) : (
              <img
                src={clip.thumbnail}
                alt={clip.title}
                loading="lazy"
                style={{ width: "100%", cursor: "pointer" }}
              />
            )}
            <a href="#" target="_blank" rel="noopener noreferrer">
              <p>{clip.title}</p>
            </a>
          </div>
        ))}
      </div>
      {/* Divider */}
      <hr />

      {/* GIF Search Section */}
      <div className="gif-search">
        <form onSubmit={handleSearch}>
          <label htmlFor="category">Select Meme Tags:</label>
          <select
            id="category"
            value={searchTerm}
            onChange={(e) => setSearchTerm(e.target.value)}
          >
            {memeCoinList.map((coin, index) => (
              <option key={index} value={coin}>
                {coin}
              </option>
            ))}
          </select>
          <button type="submit">Search</button>
        </form>

        {/* Loading Indicator */}
        {gifLoading && <div className="spinner"></div>}

        {/* Display GIFs */}
        <div className="gifs">
          {gifs.map((gif) => (
            <div key={gif.id} className="gif-item">
              <img
                src={gif.previewUrl}
                alt={gif.title}
                loading="lazy"
                style={{
                  width: "100%",
                  cursor: "pointer",
                  borderRadius: "8px",
                }}
                onClick={() => window.open(gif.shareUrl, "_blank")}
              />
              <p>{gif.title}</p>
            </div>
          ))}
        </div>

        {/* Load More GIFs */}
        {hasMoreGifs && !gifLoading && (
          <div className="load-more">
            <button onClick={loadMoreGifs}>Load More GIFs</button>
          </div>
        )}

        {/* No GIF Found Message */}
        {!gifLoading && gifs.length === 0 && (
          <p style={{ color: "#bbb", textAlign: "center" }}>
            No GIF found for the selected meme coin.
          </p>
        )}
      </div>
    </div>
  );
};

export default MoonClip;
