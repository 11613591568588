import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";

// Helper function to combine date and time strings into a Date object
const combineDateAndTime = (dateString, timeString) => {
  // Extract date parts
  const date = new Date(dateString);

  // Extract time parts
  const [hours, minutes] = timeString.split(":").map(Number);

  // Set hours and minutes in UTC
  date.setUTCHours(hours, minutes, 0, 0);

  return date;
};

// Helper function to convert milliseconds to days, hours, minutes, seconds
const getTimeComponents = (milliseconds) => {
  const totalSeconds = Math.floor(milliseconds / 1000);

  const days = Math.floor(totalSeconds / (3600 * 24));
  const hours = Math.floor((totalSeconds % (3600 * 24)) / 3600);
  const minutes = Math.floor((totalSeconds % 3600) / 60);
  const seconds = totalSeconds % 60;

  return { days, hours, minutes, seconds };
};

const PresaleTimer = ({ presale }) => {
  const [timeRemaining, setTimeRemaining] = useState({});
  const [status, setStatus] = useState("");

  // Combine date and time strings into Date objects
  const startDateTime = combineDateAndTime(
    presale.presale_details.start_date,
    presale.presale_details.start_time
  );
  const endDateTime = combineDateAndTime(
    presale.presale_details.end_date,
    presale.presale_details.end_time
  );

  useEffect(() => {
    const updateTimer = () => {
      const now = new Date();

      if (now < startDateTime) {
        // Presale hasn't started yet
        const diff = startDateTime - now;
        setStatus("start");
        setTimeRemaining(getTimeComponents(diff));
      } else if (now >= startDateTime && now < endDateTime) {
        // Presale is ongoing
        const diff = endDateTime - now;
        setStatus("end");
        setTimeRemaining(getTimeComponents(diff));
      } else {
        // Presale has ended
        setStatus("ended");
        setTimeRemaining({});
      }
    };

    // Initial call
    updateTimer();

    // Update every second
    const intervalId = setInterval(updateTimer, 1000);

    // Clean up interval on unmount
    return () => clearInterval(intervalId);
  }, [startDateTime, endDateTime]);

  // Render the timer based on the current status
  const renderTimer = () => {
    if (status === "start") {
      const { days, hours, minutes, seconds } = timeRemaining;
      return (
        <div
          style={{
            display: "flex",
            alignItems: "center",
            gap: "10px",
            width: "fit-content",
            color: "#378e73",
            padding: "5px",
            borderRadius: "5px",
          }}
        >
          <h3>Presale starts in</h3>
          <div>
            {days}d : {hours}h : {minutes}m : {seconds}s
          </div>
        </div>
      );
    } else if (status === "end") {
      const { days, hours, minutes, seconds } = timeRemaining;
      return (
        <div
          style={{
            display: "flex",
            alignItems: "center",
            gap: "10px",
            width: "fit-content",
            color: "#378e73",
            padding: "5px",
            borderRadius: "5px",
          }}
        >
          <h3>Presale ends in</h3>
          <div>
            {days}d : {hours}h : {minutes}m : {seconds}s
          </div>
        </div>
      );
    } else if (status === "ended") {
      return (
        <div
          style={{
            display: "flex",
            alignItems: "center",
            gap: "10px",
            width: "fit-content",
            color: "#378e73",
            border: "1px solid #378e73",
            padding: "5px",
            borderRadius: "5px",
            boxShadow: "0px 0px 10px black",
          }}
        >
          <h3>Presale Ended</h3>
        </div>
      );
    } else {
      return null;
    }
  };

  return <div>{renderTimer()}</div>;
};

// PropTypes for type checking
PresaleTimer.propTypes = {
  presale: PropTypes.shape({
    presale_details: PropTypes.shape({
      start_date: PropTypes.string.isRequired, // ISO date string
      start_time: PropTypes.string.isRequired, // Format: "HH:mm"
      end_date: PropTypes.string.isRequired, // ISO date string
      end_time: PropTypes.string.isRequired, // Format: "HH:mm"
    }).isRequired,
  }).isRequired,
};

export default PresaleTimer;
