import React from 'react';
import SlidingProgressBar from '../component/micro/slidingProgressBar';
import CryptoCollageBanner from '../component/micro/cryptoCollageBanner';
import Carousel from '../component/micro/carousel';
import TokenDetailsCompo from '../component/micro/tokenDetailsCompo';
import CryptoTile from '../component/micro/cryptoTile';


const TokenDetails = () => {
  return (
    <>
      <SlidingProgressBar />
      <CryptoCollageBanner />
      <Carousel />
      <TokenDetailsCompo />
      <CryptoTile />
    </>
  )
}

export default TokenDetails