import React, { useState } from "react";
import "./../../styles/micro/addCoin.css";
import CoinForm from "./coinForm";
import PresaleForm from "./presaleForm";

const AddCoinCompo = () => {
  const [displayForm, setDisplayForm] = useState("coin");
  const handleRadioChange = (e) => {
    setDisplayForm(e.target.value);
  };

  return (
    <form className="add-coin-cntr">
      <h1>List Your {displayForm == "coin" ? "Coin" : "Presale"} For Free</h1>
      <div className="which-form">
        <label
          style={{
            border: displayForm == "coin" ? "1px solid #378e73" : "none",
          }}
        >
          <input
            type="radio"
            name="formType"
            checked={displayForm == "coin"}
            value="coin"
            onChange={handleRadioChange}
          />
          Coin
        </label>
        <label
          style={{
            border: displayForm == "presale" ? "1px solid #378e73" : "none",
          }}
        >
          <input
            type="radio"
            name="formType"
            checked={displayForm == "presale"}
            value="presale"
            onChange={handleRadioChange}
          />
          Presale
        </label>
      </div>

      <div className="info-cntr">
        <CoinForm type={displayForm} />
      </div>
    </form>
  );
};

export default AddCoinCompo;
