import React from 'react'
import SlidingProgressBar from '../component/micro/slidingProgressBar'
import CryptoCollageBanner from '../component/micro/cryptoCollageBanner'
import Carousel from '../component/micro/carousel'
import CryptoDashboard from '../component/micro/cryptoDashboard'
import ScanSmartContractCompo from '../component/micro/scanSmartContractCompo'

const ScanSmartContract = () => {
  return (
    <React.Fragment>
      <SlidingProgressBar />
      <CryptoCollageBanner />
      <Carousel />
      <ScanSmartContractCompo />
      <CryptoDashboard />
    </React.Fragment>
  )
}

export default ScanSmartContract