import React from "react";
import "./../../styles/micro/promotePackage.css";
import RotatedBanner from "./../../images/icon/promote/rotate.png";
import WideBanner from "./../../images/icon/promote/wide.png";
import Trending from "./../../images/icon/promote/trending.png";

const packages = [
  {
    title: "ROTATED",
    image: "widebanner.png",
    sizes: "1100x120",
    prices: [
      { duration: "1 Day", price: "$400 (-11%)" },
      { duration: "3 Days", price: "$1,080 (-20%)" },
      { duration: "7 Days", price: "$2,205 (-30%)" },
    ],
    orderLink: "https://t.me/fourtistechbot",
  },
  {
    title: "WIDEBANNER",
    image: "rotated.png",
    sizes: "600x240",
    prices: [
      { duration: "1 Day", price: "$225 (-10%)" },
      { duration: "3 Days", price: "$670 (-20%)" },
      { duration: "7 Days", price: "$1,220 (-30%)" },
    ],
    orderLink: "https://t.me/fourtistechbot",
  },
  {
    title: "TRENDING",
    image: "trending.png",
    description: "Token will also show on our telegram channel",
    // linkText: "Click here for Trending Price List",
    prices: [],
    orderLink: "https://t.me/fourtistechbot",
  },
  // {
  //   title: "POPUP",
  //   image: PopUp,
  //   prices: [
  //     { duration: "1 Day", price: "$1,150" },
  //     { duration: "3 Days", price: "$3,100 (-10%)" },
  //     { duration: "7 Days", price: "$5,600 (-30%)" },
  //   ],
  //   orderLink: "@Moontech",
  // },
];

const PromotePackages = () => {
  return (
    <div className="promote-packages">
      {packages.map((pkg, index) => (
        <div
          className="package"
          key={index}
          style={{
            backgroundColor: "black",
          }}
        >
          <h3>{pkg.title}</h3>
          <img src={pkg.image} alt={pkg.title} />
          {pkg.description && <p>{pkg.description}</p>}
          {pkg.linkText && <a href="#">{pkg.linkText}</a>}
          {pkg.sizes && <p className="sizes">{pkg.sizes}</p>}
          <div className="complex-a-ul">
            <ul className="prices">
              {pkg.prices.map((price, i) => (
                <li key={i}>
                  <span>{price.duration}</span> <span>{price.price}</span>
                </li>
              ))}
            </ul>
            <a href={`${pkg.orderLink}`} className="order-link">
              Order via @FourtisTech
            </a>
          </div>
        </div>
      ))}
    </div>
  );
};

export default PromotePackages;
