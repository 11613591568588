import { Route, Routes } from "react-router-dom";
import CoinRanking from "../pages/coinRanking";
import NotFound from "./micro/notFound";
import CoinSheet from "../pages/coinSheet";
import CoinPulse from "../pages/coinPulse";
import ScanSmartContract from "../pages/scanSmartContract";
import "./../styles/main.css";
import AddCoin from "../pages/addCoin";
import PromotePackages from "./micro/promotepackage";
import CoinHub from "../pages/coinHub";
import CoinClip from "../pages/coinClip";
import TokenDetails from "./../pages/tokenDetails";
import CoinNewsDetails from "../pages/coinNewsDetails";
import CoinListing from "./micro/coinListing/coinListing";
import ResetPassword from "./resetPass";
import VerifyEmail from "./verifyUser";

const Main = ({ isOpenNav }) => {
  return (
    <div className={`main-container ${isOpenNav ? "" : "close"}`}>
      <Routes>
        <Route path="/coin-listing/:type" element={<CoinListing />} />
        <Route
          path="/coin-news-details/:category/:page/:id"
          element={<CoinNewsDetails />}
        />
        <Route
          path="/token-details/:cat/:chain/:address"
          element={<TokenDetails />}
        />
        <Route path="/coin-clip" element={<CoinClip />} />
        <Route path="/our-partner" element={<CoinHub />} />
        <Route path="/promote-coin" element={<PromotePackages />} />
        <Route path="/add-coin" element={<AddCoin />} />
        <Route path="/scan-smart-contract" element={<ScanSmartContract />} />
        <Route path="/coin-news" element={<CoinPulse />} />
        <Route path="/coin-calculate" element={<CoinSheet />} />
        <Route path="/" element={<CoinRanking />} />
        <Route path="*" element={<NotFound />} />
        <Route path="/reset-password" element={<ResetPassword />} />
        <Route path="/verify-email" element={<VerifyEmail />} />
      </Routes>
    </div>
  );
};

export default Main;
