import axios from "axios";
import { useEffect, useState } from "react";
import "./../../styles/micro/slidingProgressBar.css";
import Logo from "./login-register-icon.svg";
import Up from "../../images/icon/up.svg";
import Down from "../../images/icon/down.svg";
import { useNavigate } from "react-router-dom";
import { formatCoinValue } from "../../helpers/formatPrice";

const SlidingProgressBar = () => {
  const navigate = useNavigate();

  const [cryptoCurrency, setCrypto] = useState([
    [
      {
        rank: 1,
        logo: "./../../images/icon/X-icon.svg",
        name: "bitcoin",
        info: "2%up",
      },
      {
        rank: 2,
        logo: "./../../images/icon/X-icon.svg",
        name: "ethereum",
        info: "4%up",
      },
      {
        rank: 1,
        logo: "./../../images/icon/X-icon.svg",
        name: "bitcoin",
        info: "2%up",
      },
      {
        rank: 2,
        logo: "./../../images/icon/X-icon.svg",
        name: "ethereum",
        info: "4%up",
      },
      {
        rank: 1,
        logo: "./../../images/icon/X-icon.svg",
        name: "bitcoin",
        info: "2%up",
      },
      {
        rank: 2,
        logo: "./../../images/icon/X-icon.svg",
        name: "ethereum",
        info: "4%up",
      },
    ],
    [
      {
        rank: 3,
        logo: "./../../images/icon/X-icon.svg",
        name: "nutCoin",
        info: "7%down",
      },
      {
        rank: 4,
        logo: "./../../images/icon/X-icon.svg",
        name: "hamster",
        info: "2%up",
      },
      {
        rank: 3,
        logo: "./../../images/icon/X-icon.svg",
        name: "nutCoin",
        info: "7%down",
      },
      {
        rank: 4,
        logo: "./../../images/icon/X-icon.svg",
        name: "hamster",
        info: "2%up",
      },
      {
        rank: 3,
        logo: "./../../images/icon/X-icon.svg",
        name: "nutCoin",
        info: "7%down",
      },
      {
        rank: 4,
        logo: "./../../images/icon/X-icon.svg",
        name: "hamster",
        info: "2%up",
      },
      {
        rank: 3,
        logo: "./../../images/icon/X-icon.svg",
        name: "nutCoin",
        info: "7%down",
      },
      {
        rank: 4,
        logo: "./../../images/icon/X-icon.svg",
        name: "hamster",
        info: "2%up",
      },
    ],
    [
      {
        rank: 5,
        logo: "./../../images/icon/X-icon.svg",
        name: "nutCoin",
        info: "7%down",
      },
      {
        rank: 6,
        logo: "./../../images/icon/X-icon.svg",
        name: "hamster",
        info: "2%up",
      },
    ],
    [
      {
        rank: 7,
        logo: "./../../images/icon/X-icon.svg",
        name: "nutCoin",
        info: "7%down",
      },
      {
        rank: 8,
        logo: "./../../images/icon/X-icon.svg",
        name: "hamster",
        info: "2%up",
      },
    ],
    [
      {
        rank: 9,
        logo: "./../../images/icon/X-icon.svg",
        name: "nutCoin",
        info: "7%down",
      },
      {
        rank: 10,
        logo: "./../../images/icon/X-icon.svg",
        name: "hamster",
        info: "2%up",
      },
    ],
  ]);
  const [isPaused, setIsPaused] = useState(false);
  const handleMouseEnter = () => {
    setIsPaused(true);
  };
  const handleMouseLeave = () => {
    setIsPaused(false);
  };
  const scrollingContainer = {
    overflow: "hidden",
    whiteSpace: "nowrap",
    boxSizing: "border-box",
    backgroundColor: "#050808",
    borderRadius: "10px",
    width: "100%",
    float: "right",
  };
  const scrollingContent = {
    animation: "scroll-left 35s linear infinite",
    animationPlayState: isPaused ? "paused" : "running",
  };

  const [tokens, setTokens] = useState([]);

  useEffect(() => {
    const chains = ["ether", "tron", "bsc", "base", "solana"];
    async function getTrending() {
      let data = [];
      for (let i = 0; i < chains.length; i++) {
        const result = await axios.get(
          "https://fourtis.xyz/api/trending?chain=" + chains[i]
        );
        data = data.concat(result.data.data);
      }
      return data;
    }
    getTrending().then((r) => {
      setTokens(r);
    });
  }, []);

  return (
    <div
      className="scrolling-container"
      style={scrollingContainer}
      onMouseEnter={handleMouseEnter}
      onMouseLeave={handleMouseLeave}
    >
      <div
        className="slide-prgrs-bar-cntr scrolling-content"
        style={scrollingContent}
      >
        {cryptoCurrency.map((cryptoSet) => {
          return (
            <div className="set-slid-prgrs-cntr">
              {tokens.map((crypto, index) => {
                return (
                  <div
                    className="crypto-link"
                    style={{
                      cursor: "pointer",
                    }}
                    onClick={() => {
                      navigate(
                        "/token-details/coin/" +
                          crypto.contract_details[0].chain +
                          "/" +
                          (crypto.contract_details[0].contract_address == "TBA"
                            ? "id=" + crypto._id
                            : crypto.contract_details[0].contract_address)
                      );
                    }}
                  >
                    <div className="crypto-slid-prgrs-cntr">
                      <span className="crypto-rank">#{index + 1}</span>
                      <img
                        src={crypto.logo_url}
                        alt=""
                        className="crypto-logo"
                      />
                      <div className="crypto-name">{crypto.symbol}</div>
                      <div className="crypto-osciltn-cntr">
                        <img
                          src={parseFloat(crypto.variation24h) > 0 ? Up : Down}
                          alt=""
                          className="price-osciltn-indx"
                        />
                        <div className="price-osciltn-info">{crypto.info}</div>
                        <div
                          style={{
                            color:
                              parseFloat(crypto.variation24h) >= 0
                                ? "rgb(46, 246, 46)"
                                : "rgb(220, 16, 16)",
                          }}
                        >
                          {
                          crypto.variation24h
                            ? formatCoinValue(crypto.variation24h)
                            : 0}
                          %
                        </div>
                      </div>
                    </div>
                  </div>
                );
              })}
            </div>
          );
        })}
      </div>
    </div>
  );
};

export default SlidingProgressBar;
