import React from "react";
import { Link } from "react-router-dom";
import "./../styles/footer.css";
import TelegramIcon from "./../images/icon/telegram-icon.svg";
import OfficialIcon from "./../images/icon/official.svg";
import TikTokIcon from "./../images/icon/tiktok-icon.svg";
import MailIcon from "./../images/icon/mail-icon.svg";
import XIcon from "./../images/icon/X-icon.svg";
import Youtube from "./../images/icon/youtube.svg";
import SiteLogo from "./Fourtis";

const Footer = () => {
  return (
    <div className="footer-container">
      <div className="footer">
        <div className="left-section-footer section-footer">
          <div className="site-logo-container">
            <Link to="/">
              {/* <img src={siteLogo} alt="" className="site-logo-ftr" /> */}
              <span className="site-logo-ftr">
                <SiteLogo />
              </span>
            </Link>
          </div>
          <div className="link-social-container">
            <a
              href="https://t.me/fourtislisting"
              className="link-social-footer"
            >
              <button className="btn-social-footer">
                <img src={TelegramIcon} alt="" className="icon-social-footer" />
                Fourtis listing alert
              </button>
            </a>
            <a
              href="https://t.me/fourtisannouncement"
              className="link-social-footer"
            >
              <button className="btn-social-footer">
                <img src={TelegramIcon} alt="" className="icon-social-footer" />
                Fourtis announcment
              </button>
            </a>
            <a
              href="https://t.me/FourtisTrending"
              className="link-social-footer"
            >
              <button className="btn-social-footer">
                <img src={TelegramIcon} alt="" className="icon-social-footer" />
                Fourtis trending
              </button>
            </a>
            <a href="https://t.me/fourtisglobal" className="link-social-footer">
              <button className="btn-social-footer">
                <img src={TelegramIcon} alt="" className="icon-social-footer" />
                Fourtis group global
              </button>
            </a>
            <a
              href="https://x.com/fourtislisting?s=21"
              className="link-social-footer"
            >
              <button className="btn-social-footer">
                <img src={XIcon} alt="" className="icon-social-footer" />
                Fourtis X listing
              </button>
            </a>
            <a
              href="https://x.com/fourtisofficial?s=21"
              className="link-social-footer"
            >
              <button className="btn-social-footer">
                <img src={XIcon} alt="" className="icon-social-footer" />
                Fourtis X official
              </button>
            </a>
            <a href="" className="link-social-footer">
              <button className="btn-social-footer">
                <img src={Youtube} alt="" className="icon-social-footer" />
                Fourtis youtube
                <img
                  src={OfficialIcon}
                  alt=""
                  className="official-icon-social-footer"
                />
              </button>
            </a>
            <a href="" className="link-social-footer">
              <button className="btn-social-footer">
                <img src={TikTokIcon} alt="" className="icon-social-footer" />
                Fourtis tiktok
              </button>
            </a>
            <a
              href="mailto:https://fourtissupport@fourtis.io"
              target="_blank"
              className="link-social-footer"
            >
              <button className="btn-social-footer">
                <img src={MailIcon} alt="" className="icon-social-footer" />
                Mail us
              </button>
            </a>
          </div>
          <div className="best-site-ftr">
            Number 1 crypto listing site for all global communities. Get the
            latest crypto info to catch coinshots early.
          </div>
          <div className="rsrvd-rights">Fourtis © All Rights Reserved.</div>
        </div>

        <div className="middle-left-sction-ftr-cntr">
          <div className="middle-section-footer section-footer">
            <h4 style={{ margin: "0" }}>Pages</h4>
            <ul className="page-recommend-list-footer">
              <ui className="page-recommend-item-footer">
                <Link to="/" className="page-recommend-link-footer">
                  Coin Ranking
                </Link>
              </ui>
              <ui className="page-recommend-item-footer">
                <Link to="/add-coin" className="page-recommend-link-footer">
                  Apply for Listing
                </Link>
              </ui>
              <ui className="page-recommend-item-footer">
                <Link to="/promote-coin" className="page-recommend-link-footer">
                  Promote Coin
                </Link>
              </ui>
            </ul>
          </div>

          {/* <div className="right-section-footer section-footer">
          <h4 style={{margin:"0"}}>Company</h4>
          <ul className="list-right-ftr">
            <li className="item-right-ftr">
              <a href="/our-partner" className="link-right-ftr">
                Partnership
              </a>
            </li>
            <li className="item-right-ftr">
              <a href="" className="link-right-ftr">
                Privacy Policy
              </a>
            </li>
          </ul>
        </div> */}
        </div>
      </div>
    </div>
  );
};

export default Footer;
