import React from 'react';
import SlidingProgressBar from '../component/micro/slidingProgressBar';
import CryptoCollageBanner from '../component/micro/cryptoCollageBanner';
import Carousel from '../component/micro/carousel';
import CoinPulseCompo from './../component/micro/coinPulseCompo'
import CryptoDashboard from '../component/micro/cryptoDashboard';

const CoinPulse = () => {
  return (
    <React.Fragment>
      <SlidingProgressBar />
      <CryptoCollageBanner />
      <Carousel />
      <CoinPulseCompo />
      <CryptoDashboard />
    </React.Fragment>
  )
}

export default CoinPulse