import React, { useEffect, useState, useRef } from "react";
import { Link, useNavigate } from "react-router-dom";
import "./../styles/header.css";
import SiteLogo from "./Fourtis";
import SearchIcon from "@mui/icons-material/Search";
import Stack from "@mui/material/Stack";
import Button from "@mui/material/Button";
import axios from "axios";
import Input from "@mui/joy/Input";
import { Box } from "@mui/material";

const Header = () => {
  const divRef = useRef(null);
  const [isVisible, setIsVisible] = useState(false);
  const navigate = useNavigate();
  const [searchedItems, setSearchedItems] = useState([]);
  const [searchInput, setSearchInput] = useState("");
  const [renderSearchResult, setRenderSearchResult] = useState(false);

  const getSearchedItems = (e) => {
    const query = e.target.value;
    setSearchInput(query);

    if (!isVisible) {
      setIsVisible(true);
    }
    axios
      .get(`https://fourtis.xyz/api/search?query=${query}`)
      .then((response) => {
        setSearchedItems(response.data.data);
      })
      .catch((error) => {
        console.error("Error fetching search results:", error);
      });
  };

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (divRef.current && !divRef.current.contains(event.target)) {
        setIsVisible(false); // Hide the div if clicked outside
      }
    };

    document.addEventListener("mousedown", handleClickOutside);

    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  const [showPromoteButton, setShowPromoteButton] = useState(false);

  useEffect(() => {
    setShowPromoteButton(window.innerWidth >= 1025);
    // Function to update the isMenuOpen state based on screen width
    const updateShowButtons = () => {
      setShowPromoteButton(window.innerWidth >= 1025);
    };
    window.addEventListener("resize", updateShowButtons);
    return () => {
      window.removeEventListener("resize", updateShowButtons);
    };
  }, []);

  return (
    <>
      <div className="header-container">
        <div className="header">
          <div
            className="left-section-header"
            style={{ display: "flex", alignItems: "center", gap: "10px" }}
          >
            <Link to="/" className="site-link">
              <SiteLogo></SiteLogo>
            </Link>
            <b>Fourtis</b>
          </div>
          <div className="middle-section-header">
            <Box
              display={"flex"}
              alignItems={"center"}
              sx={{
                backgroundColor: "#0b0e0e",
                borderRadius: "10px",
                padding: "5px",
              }}
            >
              <Input
                sx={{
                  backgroundColor: "#0b0e0e",
                  color: "white",
                }}
                placeholder="Search..."
                onChange={(e) => getSearchedItems(e)}
                variant="soft"
                size="md"
              ></Input>
              <SearchIcon></SearchIcon>
            </Box>

            {isVisible && (
              <div
                ref={divRef}
                className="search-results"
                style={{
                  overflow: "scroll",
                  overflowX: "hidden",
                }}
              >
                <span>Coin</span>
                {searchedItems.map((item, index) => {
                  return (
                    <div
                      key={"search" + index}
                      onClick={() => {
                        let navigateUrl = "";
                        if (item.type === "coin") {
                          navigateUrl = `/token-details/coin/${item.contract_details[0].chain}/${item.contract_details[0].contract_address}`;
                        } else if (item.type === "presale") {
                          navigateUrl = `/token-details/presale/${item.contract_details[0].chain}/${item.contract_details[0].contract_address}`;
                        }
                        navigate(navigateUrl);
                      }}
                      style={{
                        display: "flex",
                        flexDirection: "row",
                        justifyContent: "flex-start",
                        alignItems: "center",
                        gap: "10px",
                        cursor: "pointer",
                      }}
                    >
                      <div
                        style={{
                          display: "flex",
                          alignItems: "center",
                          gap: "10px",
                          fontSize: "0.9rem",
                        }}
                      >
                        <img
                          src={item.logo_url}
                          alt=""
                          width={"30px"}
                          style={{
                            borderRadius: "50px",
                          }}
                        />
                        <span
                          style={{
                            borderBottom: "0.5px solid white",
                          }}
                        >
                          {item.name}
                        </span>
                      </div>
                      <span
                        style={{
                          marginLeft: "auto",
                          marginRight: "5px",
                          backgroundColor: "rgb(89,89,89)",
                          borderRadius: "5px",
                          padding: "0 3px",
                          fontSize: "0.8rem",
                          fontWeight: "bold",
                        }}
                      >
                        {item.symbol}
                      </span>
                    </div>
                  );
                })}
              </div>
            )}
          </div>
          {showPromoteButton && (
            <div className="right-section-header">
              <Link to={"/add-coin"}>
                <Button
                  variant="outlined"
                  sx={{
                    borderColor: "#378e73",
                    color: "#378e73",
                    marginRight: "5px",
                  }}
                >
                  Apply For Listing
                </Button>
              </Link>

              <Link to={"/promote-coin"}>
                <Button
                  variant="contained"
                  sx={{
                    backgroundColor: "#378e73",
                    marginRight: "5px",
                  }}
                >
                  Promote Coin
                </Button>
              </Link>
            </div>
          )}
        </div>
      </div>
    </>
  );
};

export default Header;
