import React from "react";
import SlidingProgressBar from "../component/micro/slidingProgressBar";
import CryptoCollageBanner from "../component/micro/cryptoCollageBanner";
import Carousel from "../component/micro/carousel";
import CoinHubCompo from "../component/micro/coinHubCompo";
import CryptoTile from "../component/micro/cryptoTile";

const CoinHub = () => {
  return (
    <>
      <SlidingProgressBar />
      <CryptoCollageBanner />
      <Carousel />
      <CoinHubCompo />
      <CryptoTile />
    </>
  );
};

export default CoinHub;
