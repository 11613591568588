// components/VerifyEmail.js
import React, { useEffect, useState } from "react";
import { useSearchParams, useNavigate } from "react-router-dom";
import axios from "axios";
import { useNotifications } from "@toolpad/core";
import "./../styles/micro/loginRegister.css";

const VerifyEmail = () => {
  const [searchParams] = useSearchParams();
  const navigate = useNavigate();
  const notif = useNotifications();
  const [message, setMessage] = useState("");
  const [error, setError] = useState("");

  useEffect(() => {
    const verify = async () => {
      const token = searchParams.get("token");
      const id = searchParams.get("id");

      if (!token || !id) {
        setError("Invalid or missing verification token.");
        return;
      }

      try {
        const response = await axios.get(
          "https://fourtis.xyz/api/auth/verify-email",
          {
            params: {
              token,
              id,
            },
          }
        );

        if (response.data.message) {
          setMessage(response.data.message);
          notif.show(response.data.message, {
            severity: "success",
            autoHideDuration: 5000,
          });
        }
      } catch (err) {
        setError(
          err.response && err.response.data && err.response.data.message
            ? err.response.data.message
            : "An error occurred during email verification."
        );
        notif.show(error, {
          severity: "error",
          autoHideDuration: 5000,
        });
      }
    };

    verify();
  }, [searchParams, navigate, notif, error]);

  return (
    <div className="reset-password-container">
      <h2>Email Verification</h2>
      {message ? (
        <p>{message}</p>
      ) : error ? (
        <p className="error-message">{error}</p>
      ) : (
        <p>Verifying your email...</p>
      )}
    </div>
  );
};

export default VerifyEmail;
