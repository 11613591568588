import React from 'react'
import SlidingProgressBar from '../component/micro/slidingProgressBar';
import AddCoinCompo from '../component/micro/addCoinCompo';

const AddCoin = () => {
  return (
    <React.Fragment>
      <SlidingProgressBar />
      <AddCoinCompo />
    </React.Fragment>
  )
}

export default AddCoin;