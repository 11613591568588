import React, { useContext, useState, useEffect, useRef } from "react";
import { NavLink } from "react-router-dom";
import "./../styles/navbar.css";
import { CryptoDataContext } from "./../App";
import CoinRanking from "./../images/icon/coin-ranking.svg";
import CoinNews from "./../images/icon/coin-pulse.svg";
import CoinCalculate from "./../images/icon/rocket-svgrepo-com.svg";
import FourtisPartner from "./../images/icon/partner.svg";
import CoinClip from "./../images/icon/coin-clip.svg";
import ScanSmartContract from "./../images/icon/scan-smart-contract.svg";
import AddCoin from "./../images/icon/add-coin.svg";
import PromoteCoin from "./../images/icon/promote-coin.svg";
import Login from "./../images/icon/login-register-icon.svg";
import AccountCircleIcon from "@mui/icons-material/AccountCircle";
import Button from "@mui/joy/Button";
import LogoutIcon from "@mui/icons-material/Logout";
import SwapVertIcon from "@mui/icons-material/SwapVert";
import PasswordIcon from "@mui/icons-material/Password";
import SettingsIcon from "@mui/icons-material/Settings";

const Navbar = ({ isOpen, isModalOpen, isOpenNav, setIsOpenNav }) => {
  const { isLoggedInState, setIsLoggedInState } = useContext(CryptoDataContext);
  const [userInfo, setUserInfo] = useState({});
  const [isMenuOpen, setIsMenuOpen] = useState(false);
  const menuRef = useRef(null);
  const toggleMenu = () => {
    setIsMenuOpen(!isMenuOpen);
  };

  useEffect(() => {
    setIsOpenNav(window.innerWidth >= 1025);
    // Function to update the isMenuOpen state based on screen width
    const updateMenuState = () => {
      setIsOpenNav(window.innerWidth >= 1025);
    };
    window.addEventListener("resize", updateMenuState);
    return () => {
      window.removeEventListener("resize", updateMenuState);
    };
  }, []);

  const handleClickOutside = (event) => {
    if (menuRef.current && !menuRef.current.contains(event.target)) {
      setIsMenuOpen(false);
    }
  };

  const isLoggedIn = () => {
    if (localStorage.getItem("token")) {
      if (localStorage.getItem("exp") * 1000 < Date.now()) {
        localStorage.removeItem("token");
        localStorage.removeItem("user");
        localStorage.removeItem("exp");
        return false;
      }
      return JSON.parse(localStorage.getItem("user"));
    }
    return false;
  };

  useEffect(() => {
    if (isMenuOpen) {
      document.addEventListener("mousedown", handleClickOutside);
    } else {
      document.removeEventListener("mousedown", handleClickOutside);
    }

    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [isMenuOpen]);

  const logout = () => {
    localStorage.removeItem("token");
    localStorage.removeItem("exp");
    localStorage.removeItem("user");
    setUserInfo({});
    setIsLoggedInState(false);
    setIsMenuOpen(false);
  };

  const toggleNavbar = () => {
    setIsOpenNav(!isOpenNav);
  };

  useEffect(() => {
    const user = isLoggedIn();
    if (user) {
      setUserInfo(user);
    }
  }, [isLoggedInState]);

  return (
    <div>
      <button
        className={`menu-button ${isOpenNav ? "" : "close"}`}
        onClick={toggleNavbar}
      >
        ☰
      </button>

      <div
        className={`navbar ${isOpenNav ? "open" : ""}`}
        style={{
          display: "flex",
          flexDirection: "column",
          gap: "5px",
          height: "100%",
        }}
      >
        {isLoggedIn() && userInfo && userInfo.email ? (
          <div className="user-menu-container" ref={menuRef}>
            <div
              style={{
                display: "flex",
                flexDirection: "column",
                color: "white",
                padding: "10px",
                fontSize: "15px",
                gap: "5px",
              }}
            >
              <Button
                color="neutral"
                onClick={function () {}}
                size="sm"
                variant="outlined"
                sx={{
                  borderColor: "#121515",
                  color: "white",
                  ":hover": {
                    backgroundColor: "#0b0e0e",
                  },
                }}
              >
                <AccountCircleIcon sx={{ color: "white" }}></AccountCircleIcon>
                <span style={{ color: "white", marginLeft: "2px" }}>
                  {userInfo.name}
                </span>
              </Button>
              <Button
                color="neutral"
                onClick={isModalOpen}
                size="sm"
                variant="plain"
                sx={{
                  color: "white",
                  ":hover": {
                    backgroundColor: "#0b0e0e",
                    color: "white",
                  },
                }}
              >
                Change Password
              </Button>
              <Button
                color="neutral"
                onClick={() => logout()}
                size="sm"
                variant="plain"
                sx={{
                  color: "white",
                  ":hover": {
                    backgroundColor: "#0b0e0e",
                    color: "white",
                  },
                }}
              >
                <LogoutIcon></LogoutIcon>
                Log Out
              </Button>
            </div>
          </div>
        ) : (
          <button className="nav-btn" onClick={isOpen}>
            <img src={Login} alt="" className="navicon" />
            <span className="nav-item-title">Login / Register</span>
          </button>
        )}
        <div
          style={{
            height: "1px",
            width: "100%",
            backgroundColor: "#141717",
            marginTop: "2px",
            marginBottom: "2px",
          }}
        ></div>
        <NavLink to="https://swap-final-two.vercel.app">
          <Button
            color="neutral"
            size="sm"
            variant="outlined"
            sx={{
              marginTop: "5px",
              color: "white",
              backgroundColor: "#2e887d",
              boxShadow: '0px 0px 10px #298583',
              borderStyle: "none",
              width: "100%",
              ":hover": {
                backgroundColor: "white",
                color: "#378e73",
              },
            }}
          >
            FourDex Swap
          </Button>
        </NavLink>
        <NavLink
          to="/"
          className="nav-item"
          style={({ isActive }) =>
            isActive
              ? {
                  backgroundImage:
                    "linear-gradient(132deg, rgb(7, 89, 129), rgb(7, 89, 129), rgb(73, 210, 229) 93%)",
                }
              : {}
          }
        >
          <img src={CoinRanking} alt="" className="navicon" />
          Coin Ranking
        </NavLink>
        <NavLink
          to="/coin-calculate"
          className="nav-item"
          style={({ isActive }) =>
            isActive
              ? {
                  backgroundImage:
                    "linear-gradient(132deg, rgb(7, 89, 129), rgb(7, 89, 129), rgb(73, 210, 229) 93%)",
                }
              : {}
          }
        >
          <img src={CoinCalculate} alt="" className="navicon" />
          Coin Calculate
        </NavLink>
        <NavLink
          to="/coin-news"
          className="nav-item"
          style={({ isActive }) =>
            isActive
              ? {
                  backgroundImage:
                    "linear-gradient(132deg, rgb(7, 89, 129), rgb(7, 89, 129), rgb(73, 210, 229) 93%)",
                }
              : {}
          }
        >
          <img src={CoinNews} alt="" className="navicon" />
          Crypto News
        </NavLink>
        <NavLink
          to="/scan-smart-contract"
          className="nav-item"
          style={({ isActive }) =>
            isActive
              ? {
                  backgroundImage:
                    "linear-gradient(132deg, rgb(7, 89, 129), rgb(7, 89, 129), rgb(73, 210, 229) 93%)",
                }
              : {}
          }
        >
          <img src={ScanSmartContract} alt="" className="navicon" />
          Scan Smart Contract
        </NavLink>
        <NavLink
          to="/coin-clip"
          className="nav-item"
          style={({ isActive }) =>
            isActive
              ? {
                  backgroundImage:
                    "linear-gradient(132deg, rgb(7, 89, 129), rgb(7, 89, 129), rgb(73, 210, 229) 93%)",
                }
              : {}
          }
        >
          <img src={CoinClip} alt="" className="navicon" />
          Fourtis Clip
        </NavLink>
        <NavLink
          to="/our-partner"
          className="nav-item"
          style={({ isActive }) =>
            isActive
              ? {
                  backgroundImage:
                    "linear-gradient(132deg, rgb(7, 89, 129), rgb(7, 89, 129), rgb(73, 210, 229) 93%)",
                }
              : {}
          }
        >
          <img src={FourtisPartner} alt="" className="navicon" />
          Fourtis Partner
        </NavLink>
        <div
          style={{
            height: "1px",
            width: "100%",
            backgroundColor: "#141717",
            marginTop: "2px",
            marginBottom: "2px",
          }}
        ></div>
        <NavLink to="/add-coin">
          <Button
            color="neutral"
            size="sm"
            variant="outlined"
            sx={{
              color: "white",
              width: "100%",
              ":hover": {
                backgroundColor: "white",
                color: "black",
              },
            }}
          >
            <h1 className="add-btn">Apply For Listing</h1>
          </Button>
        </NavLink>
        <NavLink to="/promote-coin">
          <Button
            color="neutral"
            size="sm"
            variant="outlined"
            sx={{
              marginTop: "5px",
              color: "white",
              backgroundColor: "#378e73",
              borderStyle: "none",
              width: "100%",
              ":hover": {
                backgroundColor: "white",
                color: "#378e73",
              },
            }}
          >
            Promote Coin
          </Button>
        </NavLink>
      </div>
    </div>
  );
};

export default Navbar;

// style={({ isActive }) => (isActive ? { backgroundColor: '#3f444b'} : {})}
