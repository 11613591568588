import React from "react";
import "../../styles/micro/coinHub.css";
import bittez from "./../../images/partner/bittez.jpg";
import DGroup from "./../../images/partner/D.Group.jpg";
import tnd_gemsChat from "./../../images/partner/tnd_gemsChat.jpg";
import cryptoMario from "./../../images/partner/cryptoMario.jpg";
import Bullishbosslady from "./../../images/partner/bullisshBossLady.jpg";
import cryptoLand from "./../../images/partner/cryptoLand.jpg";
import dreamMakers from "./../../images/partner/dream_makers.jpg";
import cryptoAquila from "./../../images/partner/crypto_aquila.jpg";
import akatsukiCalls from "./../../images/partner/akatsuki_calls.jpg";
import newFoodCall from "./../../images/partner/newFoodCall.jpg";

import wizardCryptoCall from "./../../images/partner/wizardCryptoCall.jpg";
import spawncalls from "./../../images/partner/spawncalls.jpg";
import raiderGemX from "./../../images/partner/raiderGemX.jpg";
import aragornGem from "./../../images/partner/aragornGem.jpg";
import nelaJackson from "./../../images/partner/nelaJackson.jpg";
import cryptoOwen from "./../../images/partner/cryptoOwen.jpg";
import pepeArmy from "./../../images/partner/pepeArmy.jpg";
import BDVentures from "./../../images/partner/BDVentures.png";
import knightCall from "./../../images/partner/knightCall.jpg";
import GenzCoin from "./../../images/partner/GenzCoin.jpg";

import SharkingCrypto from "./../../images/partner/SharkingCrypto.jpg";
import TatsuoCalls from "./../../images/partner/TatsuoCalls.jpg";
import ZooCommunity from "./../../images/partner/ZooCommunity.jpg";
import Gilgamesh from "./../../images/partner/Gilgamesh.jpg";
// import BlockTalker from "./../../images/partner/BlockTalker.jpg";
import GemHaiWai from "./../../images/partner/GemHaiWai.jpg";
// import LowTaxTurkiye from "./../../images/partner/LowTaxTurkiye.jpg";
import MapplezInsider from "./../../images/partner/MapplezInsider.jpg";
import Cehlos from "./../../images/partner/Cehlos.jpg";
import PinkCat from "./../../images/partner/PinkCat.png";

import GemsIncubators from "./../../images/partner/GemsIncubators.jpg";
import SheikhGems from "./../../images/partner/Sheikh.jpg";
import RamJ from "./../../images/partner/RamJ.jpg";
import laskr from "./../../images/partner/laskar.jpeg";
import dwen from "./../../images/partner/dwen.jpeg";
import tribe from "./../../images/partner/tribe.jpeg";
import tk from "./../../images/partner/turhive.jpeg";
import KryptoBenzai from "./../../images/partner/KryptoBenzai.jpg";
import PresaleHunters from "./../../images/partner/PresaleHunters.jpg";
import HorizonClub from "./../../images/partner/HorizonClub.jpg";

import ZLaunchBot from "./../../images/partner/ZLaunchBot.jpg";
import CrypttraderDave from "./../../images/partner/CrypttraderDave.jpg";
import playboycrypt0 from "./../../images/partner/playboycrypt0.jpg";
import OGsCalls from "./../../images/partner/OGsCalls.jpg";
import RichCall from "./../../images/partner/RichCall.jpg";
import sahraCrypto from "./../../images/partner/sahraCrypto.jpg";
import truCall from "./../../images/partner/truCall.jpg";
import CryptoGems55552 from "./../../images/partner/CryptoGems55552.jpg";
import casinocall from "./../../images/partner/casinocall.png";
import BalineseCalls from "./../../images/partner/BalineseCalls.jpg";

import Gwendegenchannel from "./../../images/partner/Gwendegenchannel.jpg";
import CsDucks_MoveCall from "./../../images/partner/CsDucks_MoveCall.jpg";
// import UniqueTech from "./../../images/partner/UniqueTech.jpg";
import Lionswingtalk from "./../../images/partner/Lionswingtalk.jpg";
import faikbinarap from "./../../images/partner/faikbinarap.jpg";
import onemilliondolarok from "./../../images/partner/onemilliondolarok.jpg";
import CryptoVhaiDAO from "./../../images/partner/CryptoVhaiDAO.jpg";
import evanniche from "./../../images/partner/evanniche.jpg";
import defideck from "./../../images/partner/defideck.jpg";
import AltCoinQueen from "./../../images/partner/AltCoinQueen.jpg";

import CircleOfTrust from "./../../images/partner/CircleOfTrust.jpg";
import CryptoXHunter from "./../../images/partner/CryptoXHunter.jpg";
import abrar from "./../../images/partner/abrar.jpg";



const websites = {
  "Crypto News": [
    {
      name: "CoinVoice",
      url: "https://coinvoice.com",
      description: "CoinVoice focuses on the latest news...",
      image: "https://fourtis.xyz/images/CoinVoice.jpg",
    },
    {
      name: "Bitcoin Magazine",
      url: "https://bitcoinmagazine.com",
      description: "The Most Trusted Voice In...",
      image: "https://fourtis.xyz/images/Bitcoin_Magazine.jpg",
    },
    {
      name: "Coindesk",
      url: "https://coindesk.com",
      description: "Leader in cryptocurrency, Bitcoin,...",
      image: "https://fourtis.xyz/images/Coindesk.png",
    },
    {
      name: "Play to Earn Online Magazine",
      url: "https://playtoearn.online",
      description: "Editorial platform about play-to...",
      image: "https://fourtis.xyz/images/Play_to_Earn_Online_Magazine.jpg",
    },
    {
      name: "PANews",
      url: "https://panewsonline.com",
      description: "PANews is an award-winning...",
      image: "https://fourtis.xyz/images/PANews.webp",
    },
    {
      name: "Cointelegraph",
      url: "https://cointelegraph.com",
      description: "The most recent news about...",
      image: "https://fourtis.xyz/images/Cointelegraph.webp",
    },
    {
      name: "巴比特",
      url: "https://8btc.com",
      description: "巴比特 is the leading blockchain...",
      image: "https://fourtis.xyz/images/___.jpg",
    },
    {
      name: "金色财经 Jinse",
      url: "https://jinse.com",
      description: "Jinse is an objective, fair and...",
      image: "https://fourtis.xyz/images/_____Jinse.png",
    },
    {
      name: "crypto.news",
      url: "https://crypto.news",
      description: "Your go-to source of all things...",
      image: "https://fourtis.xyz/images/crypto.news.jpg",
    },
    {
      name: "Decrypt",
      url: "https://decrypt.co",
      description: "Decrypt was founded in 2018...",
      image: "https://fourtis.xyz/images/Decrypt.jpg",
    },
  ],
  "Crypto Wallets": [
    {
      name: "Ethereum 以太坊钱包",
      url: "https://ethereum.org/en/wallets",
      description: "Ether is the community-run...",
      image: "https://fourtis.xyz/images/Ethereum______.jpg",
    },
    {
      name: "TokenPocket",
      url: "https://tokenpocket.pro",
      description: "TokenPocket makes blockchain...",
      image: "https://fourtis.xyz/images/TokenPocket.jpg",
    },
    {
      name: "Coinbase Wallet",
      url: "https://wallet.coinbase.com",
      description: "Coinbase Wallet Your key to the...",
      image: "https://fourtis.xyz/images/Coinbase_Wallet.png",
    },
    {
      name: "Safepal",
      url: "https://safepal.io",
      description: "SafePal is your gateway to the...",
      image: "https://fourtis.xyz/images/Safepal.jpg",
    },
    {
      name: "Trust Wallet",
      url: "https://trustwallet.com",
      description: "Trust Wallet is the official crypto...",
      image: "https://fourtis.xyz/images/Trust_Wallet.webp",
    },
    {
      name: "Metamask 小狐狸",
      url: "https://metamask.io",
      description: "MetaMask is a software...",
      image: "https://fourtis.xyz/images/Metamask____.webp",
    },
    {
      name: "MEXC",
      url: "https://mexc.com",
      description: "Founded in 2018, MEXC is a...",
      image: "https://fourtis.xyz/images/MEXC.jpg",
    },
    {
      name: "imToken",
      url: "https://token.im",
      description: "imToken Secure, trusted digital...",
      image: "https://fourtis.xyz/images/imToken.jpg",
    },
    {
      name: "Mathwallet",
      url: "https://mathwallet.org",
      description: "A multi-chain crypto wallet for...",
      image: "https://fourtis.xyz/images/Mathwallet.jpg",
    },
  ],
  "Data Analytics": [
    {
      name: "DropsTab",
      url: "https://dropstab.com",
      description: "Your go-to crypto aggregator. Get...",
      image: "https://fourtis.xyz/images/DropsTab.jpg",
    },
    {
      name: "AlCoin",
      url: "https://alcoin.com",
      description: "AlCoin is the world's leader in...",
      image: "https://fourtis.xyz/images/AlCoin.jpg",
    },
    {
      name: "Nansen",
      url: "https://nansen.ai",
      description: "Nansen is a blockchain analytics...",
      image: "https://fourtis.xyz/images/Nansen.jpg",
    },
    {
      name: "CoinMarketCap",
      url: "https://coinmarketcap.com",
      description: "Coinmarketcap is a website that...",
      image: "https://fourtis.xyz/images/CoinMarketCap.webp",
    },
    {
      name: "Messari",
      url: "https://messari.io",
      description: "Messari offers a core filtering tool...",
      image: "https://fourtis.xyz/images/Messari.jpg",
    },
    {
      name: "CoinMarketCal",
      url: "https://coinmarketcal.com",
      description: "CoinMarketCal is the leading...",
      image: "https://fourtis.xyz/images/CoinMarketCal.jpg",
    },
    {
      name: "Glassnode studio",
      url: "https://studio.glassnode.com",
      description: "The most comprehensive library of...",
      image: "https://fourtis.xyz/images/Glassnode_studio.jpg",
    },
    {
      name: "IntoTheBlock",
      url: "https://intotheblock.com",
      description: "IntoTheBlock offers real-time...",
      image: "https://fourtis.xyz/images/IntoTheBlock.png",
    },
    {
      name: "TradingView",
      url: "https://tradingview.com",
      description: "We're a charting platform and...",
      image: "https://fourtis.xyz/images/TradingView.jpg",
    },
    {
      name: "Dune Analytics 沙丘分析",
      url: "https://dune.com",
      description: "Crypto data by and for the...",
      image: "https://fourtis.xyz/images/Dune_Analytics_____.png",
    },
    {
      name: "CoinGecko",
      url: "https://www.coingecko.com/",
      description: "CoinGecko provides a data platform...",
      image: "https://fourtis.xyz/images/CoinGecko.webp",
    },
    {
      name: "Token Unlocks",
      url: "https://token.unlocks.app/",
      description: "Token Unlocks Dashboard & ...",
      image: "https://fourtis.xyz/images/Token_Unlocks.jpg",
    },
  ],
  Launchpad: [
    {
      name: "Crypto.com",
      url: "https://crypto.com",
      description: "Coin Launches keeps you updated...",
      image: "https://fourtis.xyz/images/Crypto.com.jpg",
    },
    {
      name: "cryptorank",
      url: "https://cryptorank.io",
      description: "cryptorank's upcoming presale...",
      image: "https://fourtis.xyz/images/cryptorank.png",
    },
    {
      name: "ICO Drops",
      url: "https://icodrops.com",
      description: "ICO Drops is an independent...",
      image: "https://fourtis.xyz/images/ICO_Drops.jpg",
    },
    {
      name: "Icomarks",
      url: "https://icomarks.com",
      description: "ICOMarks is an independent...",
      image: "https://fourtis.xyz/images/Icomarks.jpg",
    },
    {
      name: "BSCPad",
      url: "https://bscpad.com",
      description: "Bscpad is the first decentralised...",
      image: "https://fourtis.xyz/images/BSCPad.png",
    },
    {
      name: "TrustPad",
      url: "https://trustpad.io",
      description: "The #1 multi-chain Launchpad...",
      image: "https://fourtis.xyz/images/TrustPad.jpg",
    },
    {
      name: "Red Kite Launchpad",
      url: "https://redkitepad.com",
      description: "Welcome to Red Kite, a cutting-...",
      image: "https://fourtis.xyz/images/Red_Kite_Launchpad.jpg",
    },
    {
      name: "Poolz",
      url: "https://poolz.finance",
      description: "Blockchain Agnostic Launchpad...",
      image: "https://fourtis.xyz/images/Poolz.jpg",
    },
    {
      name: "Seedify",
      url: "https://seedify.fund",
      description: "The incubator & launchpad for...",
      image: "https://fourtis.xyz/images/Seedify.jpg",
    },
    {
      name: "Avalaunch",
      url: "https://avalaunch.app",
      description: "Avalaunch Launchpad Efficient...",
      image: "https://fourtis.xyz/images/Avalaunch.jpg",
    },
    {
      name: "Polkastarter",
      url: "https://polkastarter.com",
      description: "Empowering projects to launch...",
      image: "https://fourtis.xyz/images/Polkastarter.jpg",
    },
    {
      name: "Unicrypt",
      url: "https://unicrypt.network",
      description: "Unicrypt begins offering a growing...",
      image: "https://fourtis.xyz/images/Unicrypt.jpg",
    },
    {
      name: "PinkSale",
      url: "https://pinksale.finance",
      description: "PinkSale helps everyone create...",
      image: "https://fourtis.xyz/images/PinkSale.png",
    },
  ],
  "Charting Tool": [
    {
      name: "Dextool",
      url: "https://dextools.io",
      description: "DEXTools, the gateway to DEFI...",
      image: "https://fourtis.xyz/images/Dextool.png",
    },
    {
      name: "Poocoin",
      url: "https://poocoin.app",
      description: "BSC ChartsView a chart of the...",
      image: "https://fourtis.xyz/images/Poocoin.jpg",
    },
    {
      name: "DexScan",
      url: "https://dexscan.app",
      description: "CoinMarketcap's own charting...",
      image: "https://fourtis.xyz/images/DexScan.webp",
    },
    {
      name: "dexview",
      url: "https://dexview.com",
      description: "dexview professional charting...",
      image: "https://fourtis.xyz/images/dexview.jpg",
    },
    {
      name: "DiTing",
      url: "https://diting.io",
      description: "DiTing is the world's first intellige...",
      image: "https://fourtis.xyz/images/DiTing.jpg",
    },
    {
      name: "AVE",
      url: "https://ave.org",
      description: "Ave is a decentralized on-chain...",
      image: "https://fourtis.xyz/images/AVE.jpg",
    },
    {
      name: "DEXScreener",
      url: "https://dexscreener.com",
      description: "Realtime price charts and trading...",
      image: "https://fourtis.xyz/images/DEXScreener.jpg",
    },
    {
      name: "GeckoTerminal",
      url: "https://geckoterminal.com",
      description: "Get real-time cryptocurrency price...",
      image: "https://fourtis.xyz/images/GeckoTerminal.png",
    },
  ],
  "Smart Contract Scanner": [
    {
      name: "SAFE Analyzer Bot",
      url: "https://safeanalyzer.io",
      description: "BlockChain Utility Tools...",
      image: "https://fourtis.xyz/images/SAFE_Analyzer_Bot.jpg",
    },
    {
      name: "TokenSniffer",
      url: "https://tokensniffer.com",
      description: "validation metrics and scam...",
      image: "https://fourtis.xyz/images/TokenSniffer.jpg",
    },
    {
      name: "TM查币",
      url: "https://tmcha.com",
      description: "Check contract TO TM...",
      image: "https://fourtis.xyz/images/TM__.jpg",
    },
    {
      name: "Honeypot.is",
      url: "https://honeypot.is",
      description: "simulates a buy and a sell...",
      image: "https://fourtis.xyz/images/Honeypot.is.jpg",
    },
    {
      name: "Pancake Club",
      url: "https://pancakeclub.finance",
      description: "Pancake Club specialises in...",
      image: "https://fourtis.xyz/images/Pancake_Club.jpg",
    },
    {
      name: "BSCheck",
      url: "https://bscheck.eu",
      description: "Free Binance Smart Chain...",
      image: "https://fourtis.xyz/images/BSCheck.jpg",
    },
  ],
  nftPlatforms: [
    {
      name: "OpenSea",
      url: "https://opensea.io",
      description: "The largest NFT marketplace...",
      image: "https://fourtis.xyz/images/OpenSea.png",
    },
    {
      name: "Blur",
      url: "https://blur.io",
      description: "The NFT marketplace for pro...",
      image: "https://fourtis.xyz/images/Blur.jpg",
    },
    {
      name: "BINANCE NFT",
      url: "https://www.binance.com/en/nft",
      description: "Marketplace The one-stop fo...",
      image: "https://fourtis.xyz/images/BINANCE_NFT.jpg",
    },
    {
      name: "欧易NFT平台",
      url: "https://www.okx.com/en/web3/nft/markets",
      description: "OKX NFT platform",
      image: "https://fourtis.xyz/images/__NFT__.jpg",
    },
    {
      name: "Uniswap NFT",
      url: "https://uniswap.org",
      description: "NFT Market Aggregator, trade i...",
      image: "https://fourtis.xyz/images/Uniswap_NFT.jpg",
    },
    {
      name: "AtomicHub",
      url: "https://wax.atomichub.io",
      description: "Buy, trade & create NFTs on the...",
      image: "https://fourtis.xyz/images/AtomicHub.jpg",
    },
    {
      name: "Magic Eden",
      url: "https://magiceden.io",
      description: "Multi-Chain NFT Platform:...",
      image: "https://fourtis.xyz/images/Magic_Eden.jpg",
    },
    {
      name: "Sorare",
      url: "https://sorare.com",
      description: "Sports NFT platform to collect...",
      image: "https://fourtis.xyz/images/Sorare.png",
    },
    {
      name: "RaribleNFT",
      url: "https://rarible.com",
      description: "Aggregated, community-centric,...",
      image: "https://fourtis.xyz/images/RaribleNFT.png",
    },
    {
      name: "tofuNFT",
      url: "https://tofunft.com",
      description: "tofuNFT is a fully functional...",
      image: "https://fourtis.xyz/images/tofuNFT.jpg",
    },
    {
      name: "AlienSwap NFT",
      url: "https://alienswap.io",
      description: "The NFT marketplace and...",
      image: "https://fourtis.xyz/images/AlienSwap_NFT.jpg",
    },
    {
      name: "Solanart",
      url: "https://solanart.io",
      description: "Buy or sell NFTs and save 100%...",
      image: "https://fourtis.xyz/images/Solanart.jpg",
    },
    {
      name: "MakersPlace",
      url: "https://makersplace.com",
      description: "The premier marketplace to...",
      image: "https://fourtis.xyz/images/MakersPlace.jpg",
    },
    {
      name: "SuperRare",
      url: "https://superrare.com",
      description: "The future of the CryptoArt...",
      image: "https://fourtis.xyz/images/SuperRare.png",
    },
    {
      name: "Nifty Gateway NFT",
      url: "https://niftygateway.com",
      description: "Nifty Gateway is an all-in-one...",
      image: "https://fourtis.xyz/images/Nifty_Gateway_NFT.png",
    },
    {
      name: "Element2.0",
      url: "https://element.market",
      description: "Aggregator&MKP, the most gas...",
      image: "https://fourtis.xyz/images/Element2.0.jpg",
    },
  ],
  "Our Partners": [
    {
      name: "LASKAR CRYPTO",
      image: laskr,
      description:
        "Laskar crypto is a web3 crypto community that focuses on crypto adoption and communities in Indonesia and globally.",
      url: "https://linktr.ee/LaskarCryptoOfficial",
    },
    {
      name: "Crypto Tribe", // 18
      image: tribe,
      description: "Crypto community since 2021，Where millionaire borns",
      url: "https://t.me/CryptoTribal",
    },
    {
      name: "Dwen Exchange", // 90.25
      image: dwen,
      description: "Important presale calls, follow us on @Dwen_Exchange!",
      url: "https://t.me/Dwen_Exchange ",
    },
    {
      name: "LOWTAX TÜRKİYE", // 2.73
      image: tk,
      description: "Discover the next big thing in crypto with LOWTAX TURKIYE",
      url: "https://t.me/LowTaxTurkiye",
    },
    {
      name: "pepeArmy", // 16.5
      image: pepeArmy,
      description:
        "Get in on exclusive crypto calls and market strategies with Pepe Army Calls on Telegram – join the community today!",
      url: "https://t.me/PepeArmyCalls_2023",
    },
    {
      name: "RamJ", // 19.4
      image: RamJ,
      description:
        "Join Ram J Official Calls on Telegram for top-tier crypto signals and insights to maximize your trading potential!",
      url: "https://t.me/RamJOfficiaLCaLLS",
    },
    {
      name: "akatsukiCalls", // 20.5
      image: akatsukiCalls,
      description:
        "Join Akatsuki Calls on Telegram for high-potential crypto calls and strategies to boost your portfolio!",
      url: "https://t.me/Akatsuki_Calls_x1000",
    },
    {
      name: "spawncalls", // 6.9
      image: spawncalls,
      description:
        "Get ahead in crypto trading with Spawn's Calls on Telegram – join for reliable signals and expert insights!",
      url: "https://t.me/spawnscalls",
    },
    {
      name: "Presale Hunters", // 3.1
      image: PresaleHunters,
      description:
        "Your Front-Row Seat to the Hottest Presales – Don’t Just Invest, Hunt Smart!",
      url: "https://t.me/presalehuntersofficial",
    },
    {
      name: "BDVentures",
      image: BDVentures,
      description:
        "Explore BD Ventures – your gateway to innovative investments and venture opportunities in Vietnam and beyond!",
      url: "https://linktr.ee/bdventures",
    },
    {
      name: "Horizon Club", // 9
      image: HorizonClub,
      description:
        "Beyond Limits – Where Exclusive Opportunities Meet Visionary Investors.",
      url: "https://t.me/HORIZONCLUB1",
    },
    {
      name: "Z Launch", // 8.1
      image: ZLaunchBot,
      description:
        "Power Up Your Portfolio – Your Gateway to the Next Big Launch!",
      url: "https://t.me/ZLaunchBotOfficial",
    },
    {
      name: "CircleOfTrust", //.9
      image: CircleOfTrust,
      description:
        "Building Bridges of Trust in the Crypto World!",
      url: "https://t.me/CircleOfTrust",
    },
    {
      name: "Gilgamesh", // 1.4
      image: Gilgamesh,
      description:
        "Connect with Gilgamesh311 on Telegram for exclusive crypto insights, trading tips, and community-driven strategies!",
      url: "https://t.me/Gilgamesh311",
    },
    {
      name: "knightCall", // 9
      image: knightCall,
      description:
        "Join KnightCall on Telegram for expert crypto signals, market insights, and strategies to enhance your trading journey!",
      url: "https://t.me/Knight_call",
    },
    {
      name: "GenzCoin", // 21.1
      image: GenzCoin,
      description:
        "Join GenzCoin on Telegram for exclusive crypto gem calls and insights to boost your investment potential!",
      url: "https://t.me/jasongemx100",
    },
    {
      name: "SharkingCrypto", // 7.4
      image: SharkingCrypto,
      description:
        "Dive into the world of crypto with Sharking Crypto on Telegram – join for top signals, insights, and strategies to stay ahead!",
      url: "https://t.me/SharkingCrypto",
    },
    {
      name: "TatsuoCalls", // 4.3
      image: TatsuoCalls,
      description:
        "Join Tatsuo Calls on Telegram for premium crypto calls, market analysis, and insights to elevate your trading game!",
      url: "https://t.me/tatsuocalls",
    },
    {
      name: "casinocall", // 3
      image: casinocall,
      description: "Take the Bet on Winning Calls – High Stakes, High Rewards!",
      url: "https://t.me/casinocall",
    },
    {
      name: "CryptoGems", //52.86
      image: CryptoGems55552,
      description:
        "Uncover Rare Gems – Your Gateway to Hidden Crypto Treasures!",
      url: "https://t.me/+mqp6ZvZxiRYwNmQ0",
    },
    {
      name: "ZooCommunity", // 9.3
      image: ZooCommunity,
      description:
        "Join Zoo Community VN on Telegram for exclusive insights, crypto discussions, and a vibrant community focused on the latest market trends!",
      url: "https://t.me/ZooCommunityVN01",
    },
    {
      name: "cryptoMario", // 1.2
      image: cryptoMario,
      description:
        "Follow @CryptooMario on X for the latest crypto insights, market trends, and valuable updates to boost your financial journey!",
      url: "https://t.me/CryptoMarioPreSale",
    },
    {
      name: "Gwendegenchannel", // .33
      image: Gwendegenchannel,
      description:
        "Smart Moves, Steady Gains – Navigate the Crypto World with Precision!",
      url: "https://t.me/Gwendegenchannel",
    },
    {
      name: "newFoodCall", //4.4
      image: newFoodCall,
      description:
        "Join NewFoodCall on Telegram for the latest insights and updates on food-related investments and trends!",
      url: "https://t.me/Newfoodcall",
    },
    {
      name: "BalineseCalls", // 14.1
      image: BalineseCalls,
      description:
        "Island Vibes, Premium Calls – Discover Exotic Gains with Balinese Insight!",
      url: "https://t.me/BalineseCalls",
    },
    {
      name: "Cs duck", // 3.6
      image: CsDucks_MoveCall,
      description:
        "Fly High with Precision Calls – Join the Flock for Profitable Moves!",
      url: "https://t.me/CsDucks_MoveCall",
    },
    {
      name: "Gemhai", //33.1
      image: GemHaiWai,
      description:
        "Join GemHaiWai on Telegram for insider access to high-potential crypto gems and expert investment insights!",
      url: "https://t.me/gemhaiwai",
    },
    {
      name: "Mapplez", //7.8
      image: MapplezInsider,
      description:
        "Join Mapplez Insiders for exclusive crypto insights, signals, and expert strategies to stay ahead in the market!",
      url: "https://t.me/Mapplez_Insiders",
    },
    {
      name: "cryptoAquila", // 2.68
      image: cryptoAquila,
      description:
        "Follow @crypto_aquila_1 on X for sharp insights, expert analysis, and the latest trends in the crypto world!",
      url: "https://t.me/crypto_aquila",
    },
    {
      name: "aragornGem", // 2.4
      image: aragornGem,
      description:
        "Discover hidden crypto gems with Aragorn Gem on Telegram – join for expert insights and investment opportunities!",
      url: "https://t.me/aragorngem",
    },
    {
      name: "CryptoXHunter", // 2.87
      image: CryptoXHunter,
      description:
        "CryptoX Hunts: Your Ultimate Guide to Hidden Crypto Treasures!",
      url: "https://t.me/CryptoXHunterChat",
    },
    {
      name: "sahraCrypto", // 7.1
      image: sahraCrypto,
      description:
        "Navigate the Desert of Opportunity – Discover Hidden Gems with SahraCrypto!",
      url: "https://t.me/sahracrypto",
    },
    {
      name: "Wizard", // 17.8
      image: wizardCryptoCall,
      description:
        "Unlock expert crypto calls and exclusive insights with Wizard Crypto Calls on Telegram – join now to enhance your trading journey!",
      url: "https://t.me/WizardCryptoCalls",
    },
    {
      name: "LionWing", // 5.3
      image: Lionswingtalk,
      description:
        "Fly High with Precision Calls – Join the Flock for Profitable Moves!",
      url: "https://t.me/Lionswingtalk",
    },
    {
      name: "PinkCat", // 24.2
      image: PinkCat,
      description:
        "Unlock the Power of Purr-suasive Investments with Pink Cat KOLs – Where Exclusive Insights Meet High Potential!",
      url: "https://t.me/pinkcatcommunity",
    },
    {
      name: "OGsCalls", // 2.4
      image: OGsCalls,
      description:
        "Stay Ahead with Expert Calls – Where the OGs Lead, Profits Follow!",
      url: "https://t.me/OGsCalls",
    },
    {
      name: "Tru Call", // 6.3
      image: truCall,
      description:
        "Real Calls, Real Gains – Your Trusted Source for Winning Moves!",
      url: "https://t.me/trucallchannelno1",
    },
    {
      name: "Sheikh", // 3.66
      image: SheikhGems,
      description:
        "Unlock Exclusive Crypto Treasures with SheikhGems – Follow for Premium Insights and Hidden Opportunities!",
      url: "https://t.me/SheikhGems",
    },
    {
      name: "cryptoLand",
      image: cryptoLand,
      description:
        "CRYPTO LAND is a Web3 and Crypto marketing and promotion community.",
      url: "https://linktr.ee/CryptoLand_Off",
    },
    {
      name: "Dream Makers", // 18.7
      image: dreamMakers,
      description:
        "Join our Channel on Telegram for inspiration, success stories, and strategies to turn your dreams into reality!",
      url: "https://t.me/DREAMMAKERS_CHANNEL",
    },
    {
      name: "DGroup", // 38
      image: DGroup,
      description:
        "Unlock exclusive insights and opportunities with D.Group – join our Telegram channel to stay connected!",
      url: "https://t.me/DGroup_Channel",
    },
    {
      name: "TND-gemsChat", //14.6
      image: tnd_gemsChat,
      description:
        "Discover the next big thing in crypto with TND Gems – your source for valuable insights and opportunities! Click to explore",
      url: "https://t.me/TND_GemsChat",
    },
    {
      name: "GemsIncubators", // 11.9
      image: GemsIncubators,
      description:
        "Discover Tomorrow's Crypto Gems Today with Gems Incubators – Your Gateway to High-Growth Opportunities!",
      url: "https://linktr.ee/gemsincubators",
    },
    {
      name: "RichCall", // 2.7
      image: RichCall,
      description:
        "Unlock Wealth with Precision Calls – Your Guide to BSC and SOL Gains!",
      url: "https://t.me/richcallbscsol",
    },


    {
      name: "Cehlos", // 277.4
      image: Cehlos,
      description:
        "Follow @cehlos on X for unique insights, crypto market updates, and strategies to elevate your trading game!",
      url: "https://x.com/cehlos?t=4zcJy71_Uxp8yJ07ENwLtQ&s=09",
    },
    {
      name: "BitTez", // 39.5
      image: bittez,
      description:
        "Discover Bittez – where innovation meets the future of digital finance! Click to explore more.",
      url: "https://x.com/Bittez_",
    },
    {
      name: "Bullishbosslady", // 37
      image: Bullishbosslady,
      description:
        "Empower your crypto journey with insights from @Bulishbosslady on X – follow for market updates, trends, and strategies!",
      url: "https://x.com/Bulishbosslady?s=21",
    },
    {
      name: "raiderGemX", // 28.2
      image: raiderGemX,
      description:
        "Discover top crypto gems and insider tips by following @Raider_GemX on X – your guide to hidden opportunities!",
      url: "http://x.com/Raider_GemX",
    },
    {
      name: "nelaJackson", // 25.3
      image: nelaJackson,
      description:
        "Stay informed with @NelaJackson1 on X – follow for the latest crypto insights, strategies, and market updates!",
      url: "https://x.com/NelaJackson1",
    },
    {
      name: "cryptoOwen", // 62.7
      image: cryptoOwen,
      description:
        "Stay ahead in the crypto world with @Crypto_Owen_ on X – follow for timely insights, expert analysis, and market trends!",
      url: "https://x.com/Crypto_Owen_",
    },
    {
      name: "KryptoBenzai", // 188.3
      image: KryptoBenzai,
      description: "Empowering Your Crypto Journey, One Insight at a Time.",
      url: "https://x.com/kryptoBenzai",
    },
    {
      name: "CrypttraderDave", // 56.4
      image: CrypttraderDave,
      description:
        "Trading Insights Unleashed – Navigate the Crypto Seas with Dave!",
      url: "https://x.com/CrypttraderDave",
    },
    {
      name: "playboycrypt0", // 68.8
      image: playboycrypt0,
      description: "Bold Moves, Big Gains – Redefining the Crypto Game!",
      url: "https://x.com/playboycrypt0",
    },
    {
      name: "Abrar", // 66.9
      image: abrar,
      description: "Bold Moves, Big Gains – Redefining the Crypto Game!",
      url: "https://x.com/AbrarTheCrypto_",
    },
    {
      name: "faikbinarap", // 26.6
      image: faikbinarap,
      description:
        "Your Path to Smart Investments – Insightful Calls for Financial Growth!",
      url: "https://x.com/FaikbinArap",
    },
    {
      name: "Mahfuz", // 41.1 k
      image: CryptoVhaiDAO,
      description:
        "Empowering Decentralized Dreams – Where Community Drives Innovation!",
      url: "https://twitter.com/mahfuzxallum",
    },
    {
      name: "Honghong", // 34.9
      image: onemilliondolarok,
      description:
        "Turning Ambitions into Millions – Your Journey to Wealth Starts Here!",
      url: "https://x.com/hongngo38104169",
    },
    {
      name: "evanniche", // 79.5
      image: evanniche,
      description:
        "Evanniche: Your Key to Exclusive, Curated Crypto Insights!",
      url: "https://x.com/evancrypt",
    },
    {
      name: "defideck", // 104.2
      image: defideck,
      description: "DeFiDeck: Unlocking the Future of Finance, One Insight at a Time!",
      url: "https://x.com/defideck",
    },
    {
      name: "AltQueen", // 50.7
      image: AltCoinQueen,
      description:
        "Queen Altcoin: Reigning Over the Realm of Altcoins, One Gem at a Time!",
      url: "https://x.com/queen_altcoin",
    },
    // {
    //   name: "UniqueTech", // 65.57
    //   image: UniqueTech,
    //   description:
    //     "Exploring the Edge of Innovation – Where Tech Meets Uniqueness!",
    //   url: "https://t.me/TheUniqueTech",
    // },
    // {
    //   name: "BlockTalker", // 25.8
    //   image: BlockTalker,
    //   description:
    //     "Follow @BlockTalkers_io on X for insightful blockchain news, market analysis, and the latest trends in the crypto world!",
    //   url: "https://x.com/BlockTalkers_io",
    // },
  ],
};

const CoinHubCompo = () => {
  return (
    <>
      <h1 style={{ marginBottom: "0", textAlign: "center" }}> DOCS </h1>
      <div className="coin-hub">
        {Object.keys(websites).map((category) => (
          <div key={category} className="category">
            <h2>{category}</h2>
            <div className="all-button">
              <button>All</button>
            </div>
            <div className="websites">
              {websites[category].map((site) => (
                <a
                  key={site.name}
                  href={site.url}
                  target="_blank"
                  rel="noopener noreferrer"
                  className="website-card"
                >
                  <div className="img-cntr">
                    <img
                      src={site.image}
                      alt=""
                      className="img-hub"
                      width={40}
                      height={40}
                    />
                  </div>
                  <div className="name-discription">
                    <h3>{site.name}</h3>
                    <p>{site.description}</p>
                  </div>
                </a>
              ))}
            </div>
          </div>
        ))}
      </div>
    </>
  );
};

export default CoinHubCompo;
