import React, { useState } from "react";
import "./../../styles/micro/scanSmartContract.css";
import axios from "axios";
import { ethers } from "ethers";

const ScanSmartContractCompo = () => {
  const [network, setNetwork] = useState("");
  const [contractAddress, setContractAddress] = useState("");
  const [displayOptions, setDisplayOptions] = useState(false);
  const [scanning, setScanning] = useState(false);
  const [scanResults, setScanResults] = useState(null);
  const handleOption = (option) => {
    setNetwork(option);
    setDisplayOptions(false);
  };

  const handleChangeInput = (event) => {
    const value = event.target.value;
    setContractAddress(value);
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    axios.post("api address", { network, contractAddress });
  };

  const scan = async () => {
    setScanning(true);
    if (ethers.isAddress(contractAddress)) {
      try {
        const result = await axios.get(
          `https://fourtis.xyz/api/scan?chain=${network}&address=${contractAddress}`
        );
        setScanResults(result.data.data);
      } catch (e) {}
    }
    setScanning(false);
  };

  return (
    <div className="scan-contract-cntr">
      <h1>Scan Smart Contract</h1>
      <div className="scan-form" action="" id="scan-contract-form">
        <div className="network-cntr">
          <div
            className="network-selected"
            onClick={() => setDisplayOptions(!displayOptions)}
          >
            {" "}
            {network === "" ? "select..." : network}
            <div className="svg-icon">
              <svg
                width="18"
                height="18"
                viewBox="0 0 15 15"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
                style={{ color: "#868e96" }}
                data-chevron="true"
              >
                <path
                  d="M4.93179 5.43179C4.75605 5.60753 4.75605 5.89245 4.93179 6.06819C5.10753 6.24392 5.39245 6.24392 5.56819 6.06819L7.49999 4.13638L9.43179 6.06819C9.60753 6.24392 9.89245 6.24392 10.0682 6.06819C10.2439 5.89245 10.2439 5.60753 10.0682 5.43179L7.81819 3.18179C7.73379 3.0974 7.61933 3.04999 7.49999 3.04999C7.38064 3.04999 7.26618 3.0974 7.18179 3.18179L4.93179 5.43179ZM10.0682 9.56819C10.2439 9.39245 10.2439 9.10753 10.0682 8.93179C9.89245 8.75606 9.60753 8.75606 9.43179 8.93179L7.49999 10.8636L5.56819 8.93179C5.39245 8.75606 5.10753 8.75606 4.93179 8.93179C4.75605 9.10753 4.75605 9.39245 4.93179 9.56819L7.18179 11.8182C7.35753 11.9939 7.64245 11.9939 7.81819 11.8182L10.0682 9.56819Z"
                  fill="currentColor"
                  fill-rule="evenodd"
                  clip-rule="evenodd"
                ></path>
              </svg>
            </div>
          </div>
          {displayOptions === true && (
            <div className="scan-options">
              <div className="scan-option" onClick={() => handleOption("bsc")}>
                BSC
              </div>
              <div
                className="scan-option"
                onClick={() => handleOption("ether")}
              >
                ETH
              </div>
              <div
                className="scan-option"
                onClick={() => handleOption("optimism")}
              >
                Optimism
              </div>
              <div className="scan-option" onClick={() => handleOption("base")}>
                Base
              </div>
              <div
                className="scan-option"
                onClick={() => handleOption("blast")}
              >
                Blast
              </div>
              <div
                className="scan-option"
                onClick={() => handleOption("arbitrum")}
              >
                Arbitrum
              </div>
              <div
                className="scan-option"
                onClick={() => handleOption("polygon")}
              >
                Polygon
              </div>
              <div
                className="scan-option"
                onClick={() => handleOption("zksync")}
              >
                ZkSync
              </div>
              <div className="scan-option" onClick={() => handleOption("heco")}>
                Heco
              </div>
              <div
                className="scan-option"
                onClick={() => handleOption("avalanche")}
              >
                Avalanche
              </div>
              <div
                className="scan-option"
                onClick={() => handleOption("fantom")}
              >
                FTM
              </div>
              <div
                className="scan-option"
                onClick={() => handleOption("harmony")}
              >
                HarmonyOn
              </div>
              <div
                className="scan-option"
                onClick={() => handleOption("cronos")}
              >
                Crons
              </div>
            </div>
          )}
          <input
            type="text"
            placeholder="Contract Address ..."
            className="contract-address-input"
            onChange={handleChangeInput}
          />
        </div>
        <button className="check-btn" onClick={scan}>
          {scanning ? "..." : "Scan"}
        </button>
      </div>
      {scanResults && (
        <div className="scan-result">
          <h2>Scan Results</h2>
          <div className="result-grid">
            <div>
              <strong>Open Source:</strong> {scanResults.isOpenSource}
            </div>
            <div>
              <strong>Honeypot:</strong> {scanResults.isHoneypot}
            </div>
            <div>
              <strong>Mintable:</strong> {scanResults.isMintable}
            </div>
            <div>
              <strong>Proxy:</strong> {scanResults.isProxy}
            </div>
            <div>
              <strong>Slippage Modifiable:</strong>{" "}
              {scanResults.slippageModifiable}
            </div>
            <div>
              <strong>Blacklisted:</strong> {scanResults.isBlacklisted}
            </div>
            <div>
              <strong>Sell Tax:</strong>
              {scanResults.sellTax.max * 100}% ({scanResults.sellTax.status})
            </div>
            <div>
              <strong>Buy Tax:</strong>
              {scanResults.buyTax.max * 100}% ({scanResults.buyTax.status})
            </div>
            <div>
              <strong>Contract Renounced:</strong>{" "}
              {scanResults.isContractRenounced}
            </div>
            <div>
              <strong>Potential Scam:</strong> {scanResults.isPotentiallyScam}
            </div>
            <div>
              <strong>Updated At:</strong>{" "}
              {new Date(scanResults.updatedAt).toLocaleString()}
            </div>
          </div>
        </div>
      )}
      <div className="scan-result"></div>
    </div>
  );
};

export default ScanSmartContractCompo;
