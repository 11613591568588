import React, { useEffect, useRef, useState } from "react";
import axios from "axios";
import "./../../styles/micro/coinForm.css";
import { ethers } from "ethers";
import { useNotifications } from "@toolpad/core";
import { formatCoinValue } from "../../helpers/formatPrice";

const CoinForm = ({ type }) => {
  //Selected chain
  const [networkCoinSelected, setNetworkCoinSelected] = useState("bsc");
  const notif = useNotifications();

  //Contract details
  const [contracts, setContracts] = useState([
    {
      chain: "bsc",
      id: 0,
      contract_address: "",
      pre_launch: false,
    },
  ]);

  //Contract to be announced
  const [isChecked, setIsChecked] = useState(false);

  //Token name and symbol
  const [nameCoin, setNameCoin] = useState("");
  const [symbolCoin, setSymbolCoin] = useState("");

  //Launch time and dates for coin
  const [launchDate, setLaunchDate] = useState("");
  const [launchTime, setLaunchTime] = useState("");

  //Launch time and dates for presale
  const [launchDatePresale, setLaunchDatePresale] = useState("");
  const [launchTimePresale, setLaunchTimePresale] = useState("");
  const [endDatePresale, setEndDatePresale] = useState("");
  const [endTimePresale, setEndTimePresale] = useState("");

  //soft and hardcap
  const [softCap, setSoftCap] = useState("");
  const [hardCap, setHardCap] = useState("");

  //Presale link
  const [presaleLink, setPresaleLink] = useState("");

  //HardCapw
  const [overview, setOverview] = useState("");
  const [submitting, setSubmitting] = useState(false);

  const [displayOptionsCoin, setDisplayOptionsCoin] = useState(false);
  const [visibleDisplayOptions, setVisibleDisplayOptions] = useState(null);

  const [message, setMessage] = useState({
    severity: "error",
    message: "",
  });

  const [link, setLink] = useState([
    {
      title: "",
      id: 0,
      url: "",
    },
  ]);
  const [linkOptions, setLinkOptions] = useState([
    "Website",
    "English Telegram",
    "Chineese Telegram",
    "twitter",
    "Medium",
    "Discord",
    "Others",
  ]);
  const [networkLinkSelected, setNetworkLinkSelected] = useState("");
  const [displayOptionsLink, setDisplayOptionsLink] = useState(false);
  const [visibleDisplayOptionsCoinLogo, setVisibleDisplayOptionsCoinLogo] =
    useState(null);

  const handleOptionCoin = (id, option) => {
    setNetworkCoinSelected(option);
    setDisplayOptionsCoin(false);
    onChainChange(id, option);
  };

  const handleDisplayOptions = (index) => {
    setDisplayOptionsCoin(!displayOptionsCoin);
    setVisibleDisplayOptions(index);
  };

  const onContractChange = (id, value) => {
    let newContracts = [...contracts];
    let index = newContracts.findIndex((el) => el.id === id);
    let item = newContracts[index];
    item.contract_address = value;
    newContracts[index] = item;
    setContracts([...newContracts]);
  };

  const onChainChange = (id, value) => {
    let newContracts = [...contracts];
    let index = newContracts.findIndex((el) => el.id === id);
    let item = newContracts[index];
    item.chain = value;
    newContracts[index] = item;
    setContracts([...newContracts]);
  };

  const handleDelete = (id) => {
    let newContracts = contracts.filter((e) => e.id !== id);

    setContracts([...newContracts]);
  };

  const handleCheckboxChange = (event) => {
    setIsChecked(event.target.checked);
    let newContracts = [...contracts];
    for (let i = 0; i < contracts.length; i++) {
      newContracts[i].pre_launch = event.target.checked;
      newContracts[i].contract_address = "TBA";
    }
    setContracts(newContracts);
  };

  const handleOptionLink = (id, option) => {
    setNetworkLinkSelected(option);
    setDisplayOptionsLink(false);
    onLinkTitleChange(id, option);
  };

  const onLinkTitleChange = (id, value) => {
    let newLink = [...link];
    let index = newLink.findIndex((el) => el.id === id);
    let item = newLink[index];
    item.title = value;
    newLink[index] = item;
    setLink([...newLink]);
  };

  const onLinkChange = (id, value) => {
    let newLink = [...link];
    let index = newLink.findIndex((el) => el.id === id);
    let item = newLink[index];
    item.url = value;
    newLink[index] = item;
    setLink([...newLink]);
  };

  const handleDisplayOptionsCoinLogo = (index) => {
    setDisplayOptionsLink(!displayOptionsLink);
    setVisibleDisplayOptionsCoinLogo(index);
  };
  const handleDeleteCoinLogo = (id) => {
    let newLink = link.filter((e) => e.id !== id);
    setLink([...newLink]);
  };

  const [selectedFiles, setSelectedFiles] = useState([]);
  const [backgroundImage, setBackgroundImage] = useState(null); // State to store the image URL
  const [uploadedFiles, setUploadedFiles] = useState([]);

  const handleFileChange = (event) => {
    setSelectedFiles(Array.from(event.target.files));
    const fileList = event.target.files;
    if (fileList.length > 0) {
      const imageURL = URL.createObjectURL(fileList[0]);
      setBackgroundImage(imageURL);
    }
  };

  const inputFile = useRef(null);

  function verifyPinksaleUrl(input) {
    // Define a regular expression to match the expected PinkSale URL format
    const regex =
      /^https:\/\/www\.pinksale\.finance\/launchpad\/[a-zA-Z0-9]+\/[a-zA-Z0-9]+$/;

    // Verify if the input matches the expected URL format
    if (regex.test(input)) {
      return true;
    } else {
      return false;
    }
  }

  const handleLogoUpload = async (e) => {
    const formData = new FormData();
    selectedFiles.forEach((file) => {
      formData.append("image", file);
    });
    try {
      const response = await fetch("https://fourtis.xyz/api/upload", {
        method: "POST",
        body: formData,
        headers: {
          Authorization: `Bearer ${localStorage.getItem("token")}`,
        },
      });

      if (response.ok) {
        return true;
      } else {
        console.error("File upload failed.");
      }
    } catch (error) {
      console.error("Error uploading file:", error);
    }
    return false;
  };

  const validateData = () => {
    if (!selectedFiles || selectedFiles.length == 0) {
      return {
        severity: "error",
        message: "Please select logo first!",
      };
    }
    if (!nameCoin) {
      return {
        severity: "error",
        message: "Please chose a name!",
      };
    }
    if (!symbolCoin) {
      return {
        severity: "error",
        message: "Please chose a symbol!",
      };
    }
    if (!launchDate) {
      return {
        severity: "error",
        message: "Please chose a launch date!",
      };
    }
    if (!launchTime) {
      return {
        severity: "error",
        message: "Please chose a launch time!",
      };
    }
    if (!overview) {
      return {
        severity: "error",
        message: "Overview can not be empty!",
      };
    }

    const urlPattern =
      /^(https?:\/\/)?([a-zA-Z0-9-]+\.)+[a-zA-Z]{2,}(:\d{1,5})?(\/[^\s]*)?$/;

    for (let i = 0; i < link.length; i++) {
      if (!link[i].url) {
        return {
          severity: "error",
          message: "Links can not be empty",
        };
      } else if (!urlPattern.test(link[i].url)) {
        return {
          severity: "error",
          message: "Invalid link found!",
        };
      }
    }

    for (let i = 0; i < contracts.length; i++) {
      if (!contracts[i].contract_address && !isChecked) {
        return {
          severity: "error",
          message: "Contracts can not be empty",
        };
      }
    }

    //presale verificationsj
    if (type == "presale") {
      if (!presaleLink) {
        return {
          severity: "error",
          message: "Presale link can not be empty!",
        };
      } else if (!verifyPinksaleUrl(presaleLink)) {
        return {
          severity: "error",
          message: "Presale link must be a valid pinksale url!",
        };
      }
      if (!launchDatePresale) {
        return {
          severity: "error",
          message: "Presale start date can not be empty!",
        };
      }
      if (!launchTimePresale) {
        return {
          severity: "error",
          message: "Presale start time can not be empty!",
        };
      }
      if (!endDatePresale) {
        return {
          severity: "error",
          message: "Presale end date can not be empty!",
        };
      }
      if (!endTimePresale) {
        return {
          severity: "error",
          message: "Presale end time can not be empty!",
        };
      }
      if (softCap && hardCap) {
        if (parseFloat(hardCap) < parseFloat(softCap)) {
          return {
            severity: "error",
            message: "Hard cap must be bigger than soft cap!",
          };
        }
      }
    }

    return 1;
  };

  const handleCoinSubmit = async (e) => {
    e.preventDefault();
    if (!localStorage.getItem("token")) {
      return notif.show("You have to be logged in to use this feature!", {
        autoHideDuration: 5000,
        severity: "info",
      });
    }
    const validationResult = validateData();
    if (validationResult != 1) {
      return notif.show(validationResult.message, {
        autoHideDuration: 5000,
        severity: "error",
      });
    }
    setSubmitting(true);
    setMessage({
      severity: "error",
      message: "",
    });
    let dataToSend;
    if (type == "coin") {
      dataToSend = {
        name: nameCoin,
        symbol: symbolCoin,
        launch_date: launchDate,
        launch_time: launchTime,
        overview: overview,
        contract_details: [...contracts],
        logo_url: "",
        links: link,
      };
    } else {
      dataToSend = {
        name: nameCoin,
        symbol: symbolCoin,
        launch_date: launchDate,
        launch_time: launchTime,
        overview: overview,
        contract_details: [...contracts],
        logo_url: "",
        links: link,
        presale_details: {
          start_date: launchDatePresale,
          start_time: launchTimePresale, // Specific time as string
          end_date: endDatePresale,
          end_time: endTimePresale, // Specific time as string
          platform: "",
          link: presaleLink,
          soft_cap: softCap,
          hard_cap: hardCap,
        },
      };
    }
    const result = handleLogoUpload();
    if (result) {
      //if sucessfull, then submit token info
      dataToSend.logo_url =
        "https://fourtis.xyz/images/" + selectedFiles[0].name;

      try {
        const result = await axios.post(
          type == "coin"
            ? "https://fourtis.xyz/api/coins"
            : "https://fourtis.xyz/api/presales",
          {
            ...dataToSend,
          },
          {
            headers: {
              Authorization: `Bearer ${localStorage.getItem("token")}`,
            },
          }
        );
        notif.show(
          type == "coin"
            ? "Coin submited for review"
            : "Presale submitted for review!",
          {
            severity: "success",
          }
        );
      } catch (e) {}
    }
    setSubmitting(false);
  };

  useEffect(() => {}, [link]);

  return (
    <div className="contract-coin-details">
      <div className="coin-contract-coin-logo">
        <div className="contract-coin-cntr">
          <div className="contract-details">
            <h2>Contract Details</h2>
            <div className="chain-cntrct-adrs-cntr">
              {contracts.map((element, index) => (
                <div key={index} className="chain-cntrct-adrs">
                  <div className="chain">
                    <h4 onClick={() => handleDisplayOptions(index)}>
                      Chain
                      <span>*</span>
                    </h4>
                    <div
                      className="network-coin"
                      onClick={() => handleDisplayOptions(index)}
                      style={{
                        marginLeft: "auto",
                        backgroundColor: "#0b0e0e",
                        color: "white",
                        borderStyle: "none",
                        border:
                          visibleDisplayOptions === index &&
                          displayOptionsCoin === true
                            ? "1px solid #378e73"
                            : "none",
                      }}
                    >
                      <span>{element.chain}</span>
                      <span>
                        <svg
                          width="18"
                          height="18"
                          viewBox="0 0 15 15"
                          fill="none"
                          xmlns="http://www.w3.org/2000/svg" // Corrected namespace
                          style={{ color: "#868e96" }}
                          data-chevron="true"
                        >
                          <path
                            d="M4.93179 5.43179C4.75605 5.60753 4.75605 5.89245 4.93179 6.06819C5.10753 6.24392 5.39245 6.24392 5.56819 6.06819L7.49999 4.13638L9.43179 6.06819C9.60753 6.24392 9.89245 6.24392 10.0682 6.06819C10.2439 5.89245 10.2439 5.60753 10.0682 5.43179L7.81819 3.18179C7.73379 3.0974 7.61933 3.04999 7.49999 3.04999C7.38064 3.04999 7.26618 3.0974 7.18179 3.18179L4.93179 5.43179ZM10.0682 9.56819C10.2439 9.39245 10.2439 9.10753 10.0682 8.93179C9.89245 8.75606 9.60753 8.75606 9.43179 8.93179L7.49999 10.8636L5.56819 8.93179C5.39245 8.75606 5.10753 8.75606 4.93179 8.93179C4.75605 9.10753 4.75605 9.39245 4.93179 9.56819L7.18179 11.8182C7.35753 11.9939 7.64245 11.9939 7.81819 11.8182L10.0682 9.56819Z"
                            fill="currentColor"
                            fill-rule="evenodd"
                            clip-rule="evenodd"
                          ></path>
                        </svg>
                      </span>
                      {visibleDisplayOptions === index &&
                        displayOptionsCoin === true && (
                          <div
                            className="coin-options"
                            style={{
                              backgroundColor: "black",
                              padding: "5px",
                              color: "white",
                              borderStyle: "none",
                            }}
                          >
                            <div
                              style={{
                                backgroundColor: "#0b0e0e",
                                color: "white",
                                borderStyle: "none",
                              }}
                              className="coin-option"
                              onClick={() =>
                                handleOptionCoin(element.id, "ether")
                              }
                            >
                              ETH
                            </div>
                            <div
                              style={{
                                backgroundColor: "#0b0e0e",
                                color: "white",
                                borderStyle: "none",
                              }}
                              className="coin-option"
                              onClick={() =>
                                handleOptionCoin(element.id, "solana")
                              }
                            >
                              SOL
                            </div>
                            <div
                              style={{
                                backgroundColor: "#0b0e0e",
                                color: "white",
                                borderStyle: "none",
                              }}
                              className="coin-option"
                              onClick={() =>
                                handleOptionCoin(element.id, "bsc")
                              }
                            >
                              BSC
                            </div>
                            <div
                              style={{
                                backgroundColor: "#0b0e0e",
                                color: "white",
                                borderStyle: "none",
                              }}
                              className="coin-option"
                              onClick={() =>
                                handleOptionCoin(element.id, "tron")
                              }
                            >
                              TRON
                            </div>
                            <div
                              style={{
                                backgroundColor: "#0b0e0e",
                                color: "white",
                                borderStyle: "none",
                              }}
                              className="coin-option"
                              onClick={() =>
                                handleOptionCoin(element.id, "base")
                              }
                            >
                              Base
                            </div>
                            <div
                              style={{
                                backgroundColor: "#0b0e0e",
                                color: "white",
                                borderStyle: "none",
                              }}
                              className="coin-option"
                              onClick={() =>
                                handleOptionCoin(element.id, "polygon")
                              }
                            >
                              Polygon
                            </div>
                            <div
                              style={{
                                backgroundColor: "#0b0e0e",
                                color: "white",
                                borderStyle: "none",
                              }}
                              className="coin-option"
                              onClick={() =>
                                handleOptionCoin(element.id, "blast")
                              }
                            >
                              Blast
                            </div>
                            <div
                              style={{
                                backgroundColor: "#0b0e0e",
                                color: "white",
                                borderStyle: "none",
                              }}
                              className="coin-option"
                              onClick={() =>
                                handleOptionCoin(element.id, "avalanche")
                              }
                            >
                              AVAX
                            </div>
                            <div
                              style={{
                                backgroundColor: "#0b0e0e",
                                color: "white",
                                borderStyle: "none",
                              }}
                              className="coin-option"
                              onClick={() =>
                                handleOptionCoin(element.id, "arbitrum")
                              }
                            >
                              Arbitrum
                            </div>
                            <div
                              style={{
                                backgroundColor: "#0b0e0e",
                                color: "white",
                                borderStyle: "none",
                              }}
                              className="coin-option"
                              onClick={() =>
                                handleOptionCoin(element.id, "fantom")
                              }
                            >
                              FTM
                            </div>
                            <div
                              style={{
                                backgroundColor: "#0b0e0e",
                                color: "white",
                                borderStyle: "none",
                              }}
                              className="coin-option"
                              onClick={() =>
                                handleOptionCoin(element.id, "ton")
                              }
                            >
                              TON
                            </div>
                            <div
                              style={{
                                backgroundColor: "#0b0e0e",
                                color: "white",
                                borderStyle: "none",
                              }}
                              className="coin-option"
                              onClick={() =>
                                handleOptionCoin(element.id, "optimism")
                              }
                            >
                              Optimism
                            </div>
                            <div
                              style={{
                                backgroundColor: "#0b0e0e",
                                color: "white",
                                borderStyle: "none",
                              }}
                              className="coin-option"
                              onClick={() =>
                                handleOptionCoin(element.id, "aptos")
                              }
                            >
                              Aptos
                            </div>
                            <div
                              style={{
                                backgroundColor: "#0b0e0e",
                                color: "white",
                                borderStyle: "none",
                              }}
                              className="coin-option"
                              onClick={() =>
                                handleOptionCoin(element.id, "zksync")
                              }
                            >
                              zkSync
                            </div>
                            <div
                              style={{
                                backgroundColor: "#0b0e0e",
                                color: "white",
                                borderStyle: "none",
                              }}
                              className="coin-option"
                              onClick={() =>
                                handleOptionCoin(element.id, "heco")
                              }
                            >
                              Heco
                            </div>
                            <div
                              style={{
                                backgroundColor: "#0b0e0e",
                                color: "white",
                                borderStyle: "none",
                              }}
                              className="coin-option"
                              onClick={() =>
                                handleOptionCoin(element.id, "redlight")
                              }
                            >
                              Redlight
                            </div>
                            <div
                              style={{
                                backgroundColor: "#0b0e0e",
                                color: "white",
                                borderStyle: "none",
                              }}
                              className="coin-option"
                              onClick={() =>
                                handleOptionCoin(element.id, "dogechain")
                              }
                            >
                              Dogechain
                            </div>
                            <div
                              style={{
                                backgroundColor: "#0b0e0e",
                                color: "white",
                                borderStyle: "none",
                              }}
                              className="coin-option"
                              onClick={() =>
                                handleOptionCoin(element.id, "cronos")
                              }
                            >
                              Cronos
                            </div>
                          </div>
                        )}
                    </div>
                  </div>
                  <div className="cntrct-adrs">
                    <label htmlFor={`cntrct-adrs-inpt-${index}`}>
                      <h4>
                        Contract Address
                        <span>*</span>
                      </h4>
                    </label>
                    <div style={{ display: "flex", flexDirection: "row" }}>
                      <input
                        style={{
                          backgroundColor: "#0b0e0e",
                          color: "white",
                          borderStyle: "none",
                        }}
                        type="text"
                        className="cntrct-adrs-inpt"
                        id={`cntrct-adrs-inpt-${index}`}
                        value={element.contract}
                        onChange={(e) =>
                          onContractChange(element.id, e.target.value)
                        }
                      />
                      {index > 0 && (
                        <span
                          className="delete-icon"
                          onClick={() => handleDelete(element.id)}
                        >
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            class="icon icon-tabler icon-tabler-trash"
                            width="20"
                            height="20"
                            viewBox="0 0 24 24"
                            stroke="currentColor"
                            stroke-width="2"
                            fill="none"
                            stroke-linecap="round"
                            stroke-linejoin="round"
                          >
                            <path
                              stroke="none"
                              d="M0 0h24v24H0z"
                              fill="none"
                            ></path>
                            <line x1="4" y1="7" x2="20" y2="7"></line>
                            <line x1="10" y1="11" x2="10" y2="17"></line>
                            <line x1="14" y1="11" x2="14" y2="17"></line>
                            <path d="M5 7l1 12a2 2 0 0 0 2 2h8a2 2 0 0 0 2 -2l1 -12"></path>
                            <path d="M9 7v-3a1 1 0 0 1 1 -1h4a1 1 0 0 1 1 1v3"></path>
                          </svg>
                        </span>
                      )}
                    </div>
                  </div>
                </div>
              ))}
            </div>
            {!isChecked && (
              <div className="add-chain-btn">
                <button
                  onClick={(event) => {
                    event.preventDefault();
                    setContracts([
                      ...contracts,
                      {
                        chain: "bsc",
                        id: contracts[contracts.length - 1].id + 1,
                        contract: "",
                      },
                    ]);
                  }}
                  className="add-chain-button"
                >
                  Add Chain
                </button>
              </div>
            )}
            <div className="pre-launch-coin-tik">
              <input
                type="checkbox"
                id="prelaunch-input"
                className="prelaunch-input"
                checked={isChecked}
                onChange={handleCheckboxChange}
              />
              <label htmlFor="prelaunch-input" className="prelaunch-title">
                Pre-launch coin. No CA. Contract to be announced.
              </label>
            </div>
          </div>
          <div className="coin-details-form">
            <h2>Coin Details</h2>
            <div className="add-coin-info">
              <div className="name-coin section-coin-info">
                <label htmlFor="name-coin">
                  {" "}
                  Name <span> * </span>
                </label>{" "}
                <br />
                <input
                  type="text"
                  id="name-coin"
                  required
                  onChange={(e) => setNameCoin(e.target.value)}
                  style={{
                    backgroundColor: "#0b0e0e",
                    color: "white",
                    borderStyle: "none",
                  }}
                />
              </div>
              <div className="symbol-coin section-coin-info">
                <label htmlFor="symbol-coin">
                  {" "}
                  Symbol <span> * </span>
                </label>{" "}
                <br />
                <input
                  type="text"
                  id="symbol-coin"
                  required
                  onChange={(e) => setSymbolCoin(e.target.value)}
                  style={{
                    backgroundColor: "#0b0e0e",
                    color: "white",
                    borderStyle: "none",
                  }}
                />
              </div>
              <div className="launch-date-coin section-coin-info">
                <label htmlFor="launch-date-coin">
                  {" "}
                  Launch Date <span> * </span>
                </label>{" "}
                <br />
                <input
                  style={{
                    backgroundColor: "#0b0e0e",
                    color: "white",
                    borderStyle: "none",
                  }}
                  type="date"
                  id="launch-date-coin"
                  autoComplete="off"
                  required
                  placeholder="Pick Date ..."
                  onChange={(e) => setLaunchDate(e.target.value)}
                />
              </div>
              <div className="launch-time-coin section-coin-info">
                <label htmlFor="launch-time-coin">
                  {" "}
                  Launch Time <span> * </span>
                </label>{" "}
                <br />
                <input
                  style={{
                    backgroundColor: "#0b0e0e",
                    color: "white",
                    borderStyle: "none",
                  }}
                  type="time"
                  id="launch-time-coin"
                  required
                  onChange={(e) => setLaunchTime(e.target.value)}
                />
              </div>
              <div className="coin-overview section-coin-info">
                <label htmlFor="coin-overview">
                  Coin Overview <span> * </span>
                </label>{" "}
                <br />
                <textarea
                  name="overview"
                  id="coin-overview"
                  className="text-area-overview-coin"
                  required
                  onChange={(e) => setOverview(e.target.value)}
                  style={{
                    marginLeft: "auto",
                    backgroundColor: "#0b0e0e",
                    color: "white",
                    borderStyle: "none",
                  }}
                ></textarea>
              </div>
            </div>
            {type == "presale" && <h2>Presale Details</h2>}
            {type == "presale" && (
              <div className="add-coin-info">
                <div className="launch-time-coin section-coin-info">
                  <label htmlFor="presale-launch-date-coin">
                    {" "}
                    Presale Launch Date <span> * </span>
                  </label>{" "}
                  <br />
                  <input
                    style={{
                      backgroundColor: "#0b0e0e",
                      color: "white",
                      borderStyle: "none",
                    }}
                    type="date"
                    id="presale-launch-date-coin"
                    required
                    onChange={(e) => setLaunchDatePresale(e.target.value)}
                  />
                </div>
                <div className="launch-time-coin section-coin-info">
                  <label htmlFor="presale-launch-time-coin">
                    {" "}
                    Presale Launch Time <span> * </span>
                  </label>{" "}
                  <br />
                  <input
                    type="time"
                    id="presale-launch-time-coin"
                    required
                    onChange={(e) => setLaunchTimePresale(e.target.value)}
                    style={{
                      backgroundColor: "#0b0e0e",
                      color: "white",
                      borderStyle: "none",
                    }}
                  />
                </div>
                <div className="launch-time-coin section-coin-info">
                  <label htmlFor="presale-launch-date-coin">
                    {" "}
                    Presale Ends At Date <span> * </span>
                  </label>{" "}
                  <br />
                  <input
                    type="date"
                    id="presale-launch-date-coin"
                    required
                    style={{
                      backgroundColor: "#0b0e0e",
                      color: "white",
                      borderStyle: "none",
                    }}
                    onChange={(e) => setEndDatePresale(e.target.value)}
                  />
                </div>
                <div className="launch-time-coin section-coin-info">
                  <label htmlFor="presale-launch-time-coin">
                    {" "}
                    Presale Ends At Time <span> * </span>
                  </label>{" "}
                  <br />
                  <input
                    type="time"
                    id="presale-launch-time-coin"
                    required
                    onChange={(e) => setEndTimePresale(e.target.value)}
                    style={{
                      backgroundColor: "#0b0e0e",
                      color: "white",
                      borderStyle: "none",
                    }}
                  />
                </div>
                <div className="launch-time-coin section-coin-info">
                  <label htmlFor="presale-launch-time-coin"> Hard Cap</label>{" "}
                  <br />
                  <input
                    type="text"
                    id="presale-launch-time-coin"
                    required
                    onChange={(e) => setHardCap(e.target.value)}
                    style={{
                      backgroundColor: "#0b0e0e",
                      color: "white",
                      borderStyle: "none",
                    }}
                  />
                </div>
                <div className="launch-time-coin section-coin-info">
                  <label htmlFor="presale-launch-time-coin"> Soft Cap</label>{" "}
                  <br />
                  <input
                    type="text"
                    id="presale-launch-time-coin"
                    required
                    onChange={(e) => setSoftCap(e.target.value)}
                    style={{
                      backgroundColor: "#0b0e0e",
                      color: "white",
                      borderStyle: "none",
                    }}
                  />
                </div>
                <div className="launch-time-coin section-coin-info">
                  <label htmlFor="presale-launch-time-coin">Presale Link</label>{" "}
                  <br />
                  <input
                    type="text"
                    id="presale-launch-time-coin"
                    required
                    onChange={(e) => setPresaleLink(e.target.value)}
                    style={{
                      backgroundColor: "#0b0e0e",
                      color: "white",
                      borderStyle: "none",
                    }}
                  />
                </div>
              </div>
            )}
          </div>
        </div>

        <div className="coin-logo-link-cntr">
          <div
            style={{
              display: "flex",
              flexDirection: "row",
              alignItems: "center",
            }}
          >
            <span
              style={{
                fontSize: "20px",
                fontWeight: "bold",
                marginRight: "10px",
              }}
            >
              {" "}
              Coin Logo
            </span>{" "}
            <input
              type="file"
              ref={inputFile}
              id="upload-file"
              accept="image/png,image/gif,image/jpeg,image/svg+xml,image/webp"
              onChange={handleFileChange}
              multiple
              style={{
                marginLeft: "auto",
                backgroundColor: "#0b0e0e",
                color: "white",
                borderStyle: "none",
              }}
            />
          </div>
          <div
            className="coin-logo"
            style={{
              backgroundColor: "rgba(205, 205, 205, 0.25)",
              borderRadius: "10px",
            }}
          >
            <label
              className="upload-cntr"
              htmlFor="upload-file"
              style={{
                backgroundImage: backgroundImage
                  ? `url(${backgroundImage})`
                  : "none",
                backgroundSize: "contain",
                backgroundPosition: "center",
                backgroundRepeat: "no-repeat",
              }}
            >
              <div
                style={{
                  marginTop: "auto",
                  marginBlock: "auto",
                  color: "black",
                }}
              >
                {!backgroundImage && <span>Your selected logo</span>}
              </div>
            </label>
          </div>
          <h2> Links </h2>
          <div className="links-cntr">
            {link.map((element, index) => (
              <div key={index} className="chain-cntrct-adrs">
                <div className="chain">
                  <h4 onClick={() => handleDisplayOptionsCoinLogo(index)}>
                    title
                    <span>*</span>
                  </h4>
                  <div
                    className="network-coin"
                    onClick={() => handleDisplayOptionsCoinLogo(index)}
                    style={{
                      backgroundColor: "#0b0e0e",
                      color: "white",
                      borderStyle: "none",
                      border:
                        visibleDisplayOptionsCoinLogo === index &&
                        displayOptionsLink === true
                          ? "1px solid #378e73"
                          : "none",
                    }}
                  >
                    <span>{element.title}</span>
                    <span>
                      <svg
                        width="18"
                        height="18"
                        viewBox="0 0 15 15"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg" // Corrected namespace
                        style={{ color: "#868e96" }}
                        data-chevron="true"
                      >
                        <path
                          d="M4.93179 5.43179C4.75605 5.60753 4.75605 5.89245 4.93179 6.06819C5.10753 6.24392 5.39245 6.24392 5.56819 6.06819L7.49999 4.13638L9.43179 6.06819C9.60753 6.24392 9.89245 6.24392 10.0682 6.06819C10.2439 5.89245 10.2439 5.60753 10.0682 5.43179L7.81819 3.18179C7.73379 3.0974 7.61933 3.04999 7.49999 3.04999C7.38064 3.04999 7.26618 3.0974 7.18179 3.18179L4.93179 5.43179ZM10.0682 9.56819C10.2439 9.39245 10.2439 9.10753 10.0682 8.93179C9.89245 8.75606 9.60753 8.75606 9.43179 8.93179L7.49999 10.8636L5.56819 8.93179C5.39245 8.75606 5.10753 8.75606 4.93179 8.93179C4.75605 9.10753 4.75605 9.39245 4.93179 9.56819L7.18179 11.8182C7.35753 11.9939 7.64245 11.9939 7.81819 11.8182L10.0682 9.56819Z"
                          fill="currentColor"
                          fill-rule="evenodd"
                          clip-rule="evenodd"
                        ></path>
                      </svg>
                    </span>
                    {visibleDisplayOptionsCoinLogo === index &&
                      displayOptionsLink === true && (
                        <div
                          className="coin-options"
                          style={{
                            backgroundColor: "black",
                            color: "white",
                            borderStyle: "none",
                            padding: "5px",
                          }}
                        >
                          {linkOptions.map((link, index) => {
                            return (
                              <div
                                style={{
                                  backgroundColor: "#0b0e0e",
                                  color: "white",
                                  borderStyle: "none",
                                }}
                                className="coin-option"
                                key={index}
                                onClick={() =>
                                  handleOptionLink(element.id, link)
                                }
                              >
                                {link}
                              </div>
                            );
                          })}
                        </div>
                      )}
                  </div>
                </div>
                <div className="cntrct-adrs">
                  <label htmlFor={`cntrct-adrs-inpt-${index}`}>
                    <h4>
                      Link
                      <span>*</span>
                    </h4>
                  </label>
                  <div style={{ display: "flex", flexDirection: "row" }}>
                    <input
                      type="url"
                      className="cntrct-adrs-inpt"
                      id={`cntrct-adrs-inpt-${index}`}
                      value={element.url}
                      onChange={(e) => onLinkChange(element.id, e.target.value)}
                      style={{
                        marginLeft: "auto",
                        backgroundColor: "#0b0e0e",
                        color: "white",
                        borderStyle: "none",
                      }}
                    />
                    {index > 0 && (
                      <span
                        className="delete-icon"
                        onClick={() => handleDeleteCoinLogo(element.id)}
                      >
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          class="icon icon-tabler icon-tabler-trash"
                          width="20"
                          height="20"
                          viewBox="0 0 24 24"
                          stroke="currentColor"
                          stroke-width="2"
                          fill="none"
                          stroke-linecap="round"
                          stroke-linejoin="round"
                        >
                          <path
                            stroke="none"
                            d="M0 0h24v24H0z"
                            fill="none"
                          ></path>
                          <line x1="4" y1="7" x2="20" y2="7"></line>
                          <line x1="10" y1="11" x2="10" y2="17"></line>
                          <line x1="14" y1="11" x2="14" y2="17"></line>
                          <path d="M5 7l1 12a2 2 0 0 0 2 2h8a2 2 0 0 0 2 -2l1 -12"></path>
                          <path d="M9 7v-3a1 1 0 0 1 1 -1h4a1 1 0 0 1 1 1v3"></path>
                        </svg>
                      </span>
                    )}
                  </div>
                </div>
              </div>
            ))}
            <div className="add-chain-btn">
              <button
                onClick={(event) => {
                  event.preventDefault();
                  setLink([
                    ...link,
                    {
                      title: "Select Title",
                      id: link[link.length - 1].id + 1,
                      link: "",
                    },
                  ]);
                }}
                className="add-chain-button"
              >
                Add Link
              </button>
            </div>
          </div>
        </div>
      </div>
      {message.message != "" && (
        <span
          style={{
            color: message.severity == "error" ? "red" : "green",
            fontWeight: "bold",
            fontSize: "20px",
          }}
        >
          * {message.message}
        </span>
      )}

      <button
        onClick={(e) => handleCoinSubmit(e)}
        className="submit-btn"
        style={{
          opacity: submitting ? "0.7" : "1",
          pointerEvents: submitting ? "none" : "all",
        }}
      >
        {" "}
        {submitting ? "..." : "Submit"}
      </button>
    </div>
  );
};

export default CoinForm;
