import React from 'react'
import SlidingProgressBar from '../component/micro/slidingProgressBar'
import CryptoCollageBanner from '../component/micro/cryptoCollageBanner'
import Carousel from '../component/micro/carousel'
import CoinClipCompo from "./../component/micro/coinClipCompo";

const CoinClip = () => {
  return (
    <>
      <SlidingProgressBar />
      <CryptoCollageBanner />
      <Carousel />
      <CoinClipCompo />
    </>
  )
}

export default CoinClip