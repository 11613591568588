import { createContext, useState, useEffect } from "react";
import { BrowserRouter as Router } from "react-router-dom";
import axios from "axios";
import { NewsProvider } from "./component/micro/newsContext/newsContext";
import "./styles/global.css";
import Footer from "./component/footer";
import Header from "./component/header";
import Navbar from "./component/navbar";
import Main from "./component/main";
import LoginRegister from "./component/micro/loginRegister";
import ChangePasswordModal from "./component/micro/changePasswordModal";
import PopupAd from "./component/micro/popup ad/popupAd";
import { NotificationsProvider } from "@toolpad/core/useNotifications";

export const CryptoDataContext = createContext();

function App() {
  const [isLoggedInState, setIsLoggedInState] = useState(false);
  const [isLogRegOpen, setLogRegOpen] = useState(false);
  const openLogReg = () => setLogRegOpen(true);
  const closeLogReg = () => setLogRegOpen(false);

  const [isOpenNav, setIsOpenNav] = useState(false);

  const [isModalOpen, setIsModalOpen] = useState(false);
  const openModal = () => {
    setIsModalOpen(true);
  };
  const closeModal = () => {
    setIsModalOpen(false);
  };

  useEffect(() => {
    axios.get(
      "https://mevx.io/@3Fop0hZNLnES?gad_source=1&gclid=CjwKCAjwxNW2BhAkEiwA24Cm9JNauGtHDE4TeUFHIo7S9CnS3eaDJvwiJXgiNxk1bDEt-1XRIT0srRoCt8kQAvD_BwE"
    );
  }, []);

  const isLoggedIn = () => {
    if (localStorage.getItem("token")) {
      if (localStorage.getItem("exp") < Date.now()) {
        localStorage.removeItem("token");
        localStorage.removeItem("user");
        localStorage.removeItem("exp");
        setIsLoggedInState(false);
      }
      setIsLoggedInState(true);
    }
    setIsLoggedInState(false);
  };

  const [cryptoData, setCryptoData] = useState();
  const [crypto, setCrypto] = useState([]);
  const [coinForDetail, setCoinForDetail] = useState([]);

  useEffect(() => {
    isLoggedIn();
  }, []);

  return (
    <NotificationsProvider>
      <CryptoDataContext.Provider
        value={{
          coinForDetail,
          setCoinForDetail,
          crypto,
          cryptoData,
          isLoggedInState,
          setIsLoggedInState,
        }}
      >
        <NewsProvider>
          <Router>
            <Header />
            <Navbar
              isOpen={openLogReg}
              isModalOpen={openModal}
              isOpenNav={isOpenNav}
              setIsOpenNav={setIsOpenNav}
            />
            <Main isOpenNav={isOpenNav} />
            <Footer />
            <LoginRegister isOpen={isLogRegOpen} onClose={closeLogReg} />
            <ChangePasswordModal
              isModalOpen={isModalOpen}
              onClose={closeModal}
              isSuccess={false}
            />
            {/* <PopupAd /> */}
          </Router>
        </NewsProvider>
      </CryptoDataContext.Provider>
    </NotificationsProvider>
  );
}

export default App;
