const handleError = (e) => {
  if (e.response) {
    if (e.data) {
      if (e.message) {
        if (e.message === "Token is not valid") {
          return "Login Expired";
        }
      }
    }
  }
  return "Something went wrong!";
};

module.exports = {
  handleError,
};
