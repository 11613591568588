import React, { useEffect, useRef, useState } from "react";
import PropTypes from "prop-types";
import "../../styles/custom/DexChartIframe.css"; // Import the CSS file for styling

// Utility function to get the chain name (ensure this function is defined/imported)
const getChainName = (chain) => {
  if (chain == "ether") {
    return "ethereum";
  }
  return chain.toLowerCase();
};

const DexChartIframe = ({ coin, onChartLoad }) => {
  const iframeRef = useRef(null);
  const containerRef = useRef(null);
  const [iframeHeight, setIframeHeight] = useState(200); // Initial height
  const [iframeWidth, setIframeWidth] = useState("100%"); // Initial width

  // Function to update iframe height
  const updateIframeHeight = () => {
    const iframe = iframeRef.current;
    if (iframe) {
      try {
        const iframeDocument =
          iframe.contentDocument || iframe.contentWindow.document;
        const height = iframeDocument.body.scrollHeight;
        setIframeHeight(height);
      } catch (error) {
        console.error("Error accessing iframe content:", error);
        setIframeHeight(200); // Fallback height
      }
    }
  };

  // Function to update iframe width based on container's width
  const updateIframeWidth = () => {
    const container = containerRef.current;
    if (container) {
      const containerWidth = container.offsetWidth;
      setIframeWidth(containerWidth);
    }
  };

  useEffect(() => {
    const iframe = iframeRef.current;
    const container = containerRef.current;

    if (!iframe || !container) return;

    // Initialize ResizeObserver to watch container size
    const resizeObserver = new ResizeObserver(() => {
      updateIframeWidth();
      updateIframeHeight();
    });

    resizeObserver.observe(container);

    // Initial adjustments
    updateIframeWidth();
    updateIframeHeight();

    // Handle window resize events
    const handleWindowResize = () => {
      updateIframeWidth();
      updateIframeHeight();
    };

    window.addEventListener("resize", handleWindowResize);

    // Cleanup on unmount
    return () => {
      resizeObserver.unobserve(container);
      window.removeEventListener("resize", handleWindowResize);
    };
  }, [coin]); // Re-run effect if `coin` prop changes

  const src = `https://dexscreener.com/${getChainName(
    coin.contract_details[0].chain
  )}/${coin.contract_details[0].contract_address}?embed=1&theme=dark&info=0`;

  return (
    <div className="iframe-container" ref={containerRef}>
      <iframe
        title={`${coin.symbol} Chart`}
        src={src}
        allowFullScreen
        className="dex-chart-iframe"
        ref={iframeRef}
        style={{
          width: iframeWidth,
          height: `${iframeHeight}px`,
          border: "none",
          overflow: "auto",
        }}
        onLoad={() => {
          updateIframeHeight();
          if (onChartLoad && typeof onChartLoad === "function") {
            onChartLoad();
          }
        }}
      ></iframe>
    </div>
  );
};

DexChartIframe.propTypes = {
  coin: PropTypes.shape({
    symbol: PropTypes.string.isRequired,
    contract_details: PropTypes.arrayOf(
      PropTypes.shape({
        chain: PropTypes.string.isRequired,
        contract_address: PropTypes.string.isRequired,
      })
    ).isRequired,
  }).isRequired,
  onChartLoad: PropTypes.func, // Optional callback
};

export default DexChartIframe;
