import React, { useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import "./../styles/micro/coinNewsDetails.css";
import SlidingProgressBar from "../component/micro/slidingProgressBar";
import axios from "axios";
import DOMPurify from "dompurify";
import { format } from "date-fns"; // Optional for date formatting
import fourtis from "../images/Circle.png";

const CoinNewsDetails = () => {
  const { category, page, id } = useParams();
  const [showNotification, setShowNotification] = useState(false);
  const [newsItem, setNewsItem] = useState({});
  const [isExpanded, setIsExpanded] = useState(false);

  const announcement = {
    category: "All",
    title: "Platform Launch: Welcome to Fourtis!",
    text: "We are excited to announce the launch of Fourtis! Our platform brings together the latest in cryptocurrency rankings, news, and analysis. Whether you're interested in DeFi, NFTs, or the latest meme coins, Fourtis is your go-to source for real-time updates.",
    description:
      "Fourtis has officially launched, introducing a comprehensive platform...",
    content:
      "Fourtis has officially launched, introducing a comprehensive platform for cryptocurrency enthusiasts, traders, and investors. Explore a wealth of features and stay updated on all the major developments in the crypto world.",
    publishedAt: "09-17-2024",
    urlToImage: fourtis,
    site: "CoinIndex",
    url: "https://fourtis.io",
    source: "https://fourtis.io",
    id: 100000,
  };

  useEffect(() => {
    if (id == 100000) {
      setNewsItem(announcement);
    } else {
      getNews(category, page, id);
    }
  }, [category, page, id]);

  const getNews = async (category, page, id) => {
    try {
      const response = await axios.get(
        `https://fourtis.xyz/api/new/${category}/${page}/${id}`
      );
      if (response.data) {
        setNewsItem(response.data);
        setIsExpanded(false); // Reset expansion state when new news item is loaded
      }
    } catch (error) {
      console.error("Error fetching news:", error);
      // Optionally, set an error state here to inform the user
    }
  };

  useEffect(() => {
    if (showNotification) {
      const timer = setTimeout(() => {
        setShowNotification(false);
      }, 5000);
      return () => clearTimeout(timer);
    }
  }, [showNotification]);

  const handleCopyUrl = () => {
    const url = window.location.href; // Get the current URL
    navigator.clipboard
      .writeText(url)
      .then(() => {
        setShowNotification(true);
      })
      .catch((err) => {
        console.error("Failed to copy: ", err);
      });
  };

  const toggleExpansion = () => {
    setIsExpanded(!isExpanded);
  };

  // Helper function to open a new window with specific dimensions
  const openShareWindow = (url) => {
    const width = 600;
    const height = 600;
    const left = window.innerWidth / 2 - width / 2;
    const top = window.innerHeight / 2 - height / 2;
    window.open(
      url,
      "_blank",
      `toolbar=no, location=no, directories=no, status=no, 
      menubar=no, scrollbars=yes, resizable=yes, copyhistory=no, 
      width=${width}, height=${height}, top=${top}, left=${left}`
    );
  };

  // Share functions for each platform
  const shareOnFacebook = () => {
    const shareUrl = `https://www.facebook.com/sharer/sharer.php?u=${encodeURIComponent(
      newsItem.url
    )}`;
    openShareWindow(shareUrl);
  };

  const shareOnTwitter = () => {
    const shareText = encodeURIComponent(
      `${newsItem.title} - ${newsItem.description}`
    );
    const shareUrl = `https://twitter.com/intent/tweet?url=${encodeURIComponent(
      newsItem.url
    )}&text=${shareText}`;
    openShareWindow(shareUrl);
  };

  const shareOnTelegram = () => {
    const shareUrl = `https://t.me/share/url?url=${encodeURIComponent(
      newsItem.url
    )}&text=${encodeURIComponent(newsItem.title)}`;
    openShareWindow(shareUrl);
  };

  const shareOnLinkedIn = () => {
    const shareUrl = `https://www.linkedin.com/sharing/share-offsite/?url=${encodeURIComponent(
      newsItem.url
    )}`;
    openShareWindow(shareUrl);
  };

  const shareButtonStyle = {
    backgroundColor: "#402d12",
  };
  const facebookButtonStyle = {
    backgroundColor: "#3b5998", // Updated to Facebook's brand color
  };
  const twitterButtonStyle = {
    backgroundColor: "#1da1f2", // Updated to Twitter's brand color
  };
  const telegramButtonStyle = {
    backgroundColor: "#0088cc", // Updated to Telegram's brand color
  };
  const linkedInButtonStyle = {
    backgroundColor: "#0077b5", // LinkedIn's brand color
  };

  if (!newsItem || Object.keys(newsItem).length === 0) {
    return <div className="loading">Loading...</div>;
  }

  // Replace "[+NNNN chars]" with "read more..."
  const truncatedContent = newsItem.content.replace(/\[\+\d+\s+chars\]/g, "");
  const sanitizedTruncatedContent = DOMPurify.sanitize(truncatedContent);
  const sanitizedFullContent = DOMPurify.sanitize(newsItem.content);

  // Format the published date
  const formattedDate = format(new Date(newsItem.publishedAt), "PPP p"); // Example: Oct 2nd, 2024, 12:31 PM

  return (
    <>
      <SlidingProgressBar />
      <div className="news-details-container">
        <h1 className="news-title">{newsItem.title}</h1>
        <div className="news-meta">
          <span className="news-author">
            By {newsItem.author || "Unknown Author"}
          </span>
          <span className="news-date">{formattedDate}</span>
          <span className="news-source">Source: {newsItem.source.name}</span>
        </div>
        <div className="news-image-container">
          <img
            src={newsItem.urlToImage}
            alt={newsItem.title}
            className="news-image"
            width={400}
          />
        </div>
        <div className="share-buttons">
          <button
            onClick={shareOnFacebook}
            style={facebookButtonStyle}
            className="share-button"
            aria-label="Share on Facebook"
          >
            {/* Facebook SVG */}
            <svg
              xmlns="http://www.w3.org/2000/svg"
              className="icon icon-tabler icon-tabler-brand-facebook"
              width="20"
              height="20"
              viewBox="0 0 24 24"
              stroke="currentColor"
              strokeWidth="2"
              fill="none"
              strokeLinecap="round"
              strokeLinejoin="round"
            >
              <path stroke="none" d="M0 0h24v24H0z" fill="none"></path>
              <path d="M7 10v4h3v7h4v-7h3l1 -4h-4v-2a1 1 0 0 1 1 -1h3v-4h-3a5 5 0 0 0 -5 5v2h-3"></path>
            </svg>
          </button>
          <button
            onClick={shareOnTwitter}
            style={twitterButtonStyle}
            className="share-button"
            aria-label="Share on Twitter"
          >
            {/* Twitter SVG */}
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="20"
              height="20"
              viewBox="0 0 24 24"
              fill="none"
              stroke="currentColor"
              strokeWidth="2"
              strokeLinecap="round"
              strokeLinejoin="round"
              className="tabler-icon tabler-icon-brand-twitter"
            >
              <path d="M4 4l11.733 16h4.267l-11.733-16z"></path>
              <path d="M4 20l6.768-6.768m2.46-2.46l6.772-6.772"></path>
            </svg>
          </button>
          <button
            onClick={shareOnTelegram}
            style={telegramButtonStyle}
            className="share-button"
            aria-label="Share on Telegram"
          >
            {/* Telegram SVG */}
            <svg
              xmlns="http://www.w3.org/2000/svg"
              className="icon icon-tabler icon-tabler-brand-telegram"
              width="20"
              height="20"
              viewBox="0 0 24 24"
              stroke="currentColor"
              strokeWidth="2"
              fill="none"
              strokeLinecap="round"
              strokeLinejoin="round"
            >
              <path stroke="none" d="M0 0h24v24H0z" fill="none"></path>
              <path d="M15 10l-4 4l6 6l4-16l-18 7l4 2l2 6l3-4"></path>
            </svg>
          </button>
          <button
            onClick={shareOnLinkedIn}
            style={linkedInButtonStyle}
            className="share-button"
            aria-label="Share on LinkedIn"
          >
            {/* LinkedIn SVG */}
            <svg
              xmlns="http://www.w3.org/2000/svg"
              className="icon icon-tabler icon-tabler-brand-linkedin"
              width="20"
              height="20"
              viewBox="0 0 24 24"
              stroke="currentColor"
              strokeWidth="2"
              fill="none"
              strokeLinecap="round"
              strokeLinejoin="round"
            >
              <path stroke="none" d="M0 0h24v24H0z" fill="none"></path>
              <rect x="4" y="4" width="16" height="16" rx="2"></rect>
              <line x1="8" y1="11" x2="8" y2="16"></line>
              <line x1="8" y1="8" x2="8" y2="8.01"></line>
              <line x1="12" y1="16" x2="12" y2="11"></line>
              <path d="M16 16v-3a2 2 0 0 0 -4 0"></path>
            </svg>
          </button>
          <button
            onClick={handleCopyUrl}
            style={shareButtonStyle}
            className="share-button"
            aria-label="Copy URL"
          >
            {/* Share (Copy URL) SVG */}
            <svg
              xmlns="http://www.w3.org/2000/svg"
              className="icon icon-tabler icon-tabler-share"
              width="20"
              height="20"
              viewBox="0 0 24 24"
              stroke="currentColor"
              strokeWidth="2"
              fill="none"
              strokeLinecap="round"
              strokeLinejoin="round"
            >
              <path stroke="none" d="M0 0h24v24H0z" fill="none"></path>
              <circle cx="6" cy="12" r="3"></circle>
              <circle cx="18" cy="6" r="3"></circle>
              <circle cx="18" cy="18" r="3"></circle>
              <line x1="8.7" y1="10.7" x2="15.3" y2="7.3"></line>
              <line x1="8.7" y1="13.3" x2="15.3" y2="16.7"></line>
            </svg>
          </button>
          {showNotification && (
            <div className="notification-container">
              <div className="notification">
                <div className="notification-message">Link Copied!</div>
                <button
                  className="notification-close"
                  onClick={() => setShowNotification(false)}
                  aria-label="Close notification"
                >
                  &times;
                </button>
              </div>
            </div>
          )}
        </div>
        <div className="news-content">
          {isExpanded ? (
            <div
              className="full-content"
              dangerouslySetInnerHTML={{ __html: sanitizedFullContent }}
            ></div>
          ) : (
            <div
              className="truncated-content"
              dangerouslySetInnerHTML={{ __html: sanitizedTruncatedContent }}
            ></div>
          )}
        </div>
        <div className="news-source">
          <a href={newsItem.url} target="_blank" rel="noopener noreferrer">
            <span>Visit Source</span>
            <span>
              {/* Chevron Right SVG */}
              <svg
                xmlns="http://www.w3.org/2000/svg"
                className="icon icon-tabler icon-tabler-chevron-right"
                width="16"
                height="16"
                viewBox="0 0 24 24"
                stroke="currentColor"
                strokeWidth="2"
                fill="none"
                strokeLinecap="round"
                strokeLinejoin="round"
              >
                <path stroke="none" d="M0 0h24v24H0z" fill="none"></path>
                <polyline points="9 6 15 12 9 18"></polyline>
              </svg>
            </span>
          </a>
        </div>
      </div>
    </>
  );
};

export default CoinNewsDetails;
