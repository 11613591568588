// Utility function to format coin variations/prices dynamically
const formatCoinValue = (value) => {
  if (value === null || value === undefined) return "0";

  const absValue = Math.abs(value);
  let maximumFractionDigits = 2; // Default decimal places

  if (absValue < 0.000001) {
    maximumFractionDigits = 12;
  } else if (absValue < 0.0001) {
    maximumFractionDigits = 10;
  } else if (absValue < 0.01) {
    maximumFractionDigits = 8;
  } else if (absValue < 1) {
    maximumFractionDigits = 6;
  }

  return value.toLocaleString(undefined, {
    minimumFractionDigits: 0,
    maximumFractionDigits,
  });
};

module.exports = {
  formatCoinValue,
};
