import { useEffect, useState } from "react";
import "./../../styles/micro/cryptoCollageBanner.css";
import AdvBanner1 from "./../../images/advertisement/adv1.jpeg";
import AdvBanner2 from "./../../images/advertisement/adv2.jpeg";
import AdvBanner3 from "./../../images/advertisement/adv3.jpeg";
import AdvBanner4 from "./../../images/advertisement/adv4.jpeg";
import AdvBanner5 from "./../../images/advertisement/adv5.png";
import AdvBanner6 from "./../../images/advertisement/adv6.png";
import axios from "axios";

const CryptoCollageBanner = () => {
  const [CryptoBanner, setCryptoBanner] = useState([
    { image: AdvBanner1 },
    { image: AdvBanner2 },
    { image: AdvBanner3 },
    { image: AdvBanner4 },
    { image: AdvBanner5 },
    { image: AdvBanner6 },
  ]);

  useEffect(() => {
    async function fetchData() {
      const response = await axios.get(
        "https://fourtis.xyz/api/promotions/type/widebanner/1"
      );
      setCryptoBanner(response.data);
    }
    fetchData();
  }, []);

  return (
    <div className="crypto-collage-banner">
      {CryptoBanner &&
        CryptoBanner.map &&
        CryptoBanner.map((banner) => {
          return (
            <div className="adv-cntr">
              <a href={banner.link ? banner.link : "#"}>
                <img
                  src={"https://fourtis.xyz/images/" + banner.image}
                  alt=""
                  className="adv-img"
                />
              </a>
            </div>
          );
        })}
    </div>
  );
};

export default CryptoCollageBanner;
